import React, { useEffect } from "react";
import { useRoutes, BrowserRouter as Router } from "react-router-dom";
import {
  Home,
  OurServices,
  ComingSoon,
  Privacy,
  Politiques,
  ServicesPerOffer,
  SignUp,
  SignIn,
  Dashbord,
  TestM,
  Marketing,
  BlogsManagement,
  SingleBlog,
  ExternalisationComtapilite,
  OfficeManagement,
  PortageSlarialAndExternalisation,
  ExternalisationSuportApplicatif,
} from "@screens";
import { Helmet } from "react-helmet";

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/nos_services", element: <OurServices /> },
    { path: "/nos_services/:name", element: <OurServices /> },
    { path: "/nos_services/coming_soon", element: <ComingSoon /> },
    { path: "/legal_information", element: <Privacy /> },
    { path: "/Politiques_confidentialite", element: <Politiques /> },
    { path: "/nos_services/details/:name", element: <ServicesPerOffer /> },
    { path: "/back_ofice/3eafrica/auth/sign_up", element: <SignUp /> },
    { path: "/back_ofice/3eafrica/auth/sign_in", element: <SignIn /> },
    { path: "/test", element: <TestM /> },
    { path: "/marketing_digital", element: <Marketing /> },
    { path: "/nos_blogs/:title", element: <SingleBlog /> },
    {
      path: "/externalisation-comptabilte",
      element: <ExternalisationComtapilite />,
    },
    { path: "/office-management", element: <OfficeManagement /> },
    {
      path: "/portage-salarial-and-externalisation",
      element: <PortageSlarialAndExternalisation />,
    },
    {
      path: "/externalisation-support-applicatif",
      element: <ExternalisationSuportApplicatif />,
    },
    {
      path: "/back_ofice/3eafrica/administration/data/:date",
      element: <Dashbord />,
    },
    {
      path: "/ ",
      element: <BlogsManagement />,
    },
    { path: "/:name", element: <Home /> },
  ]);
  return routes;
};

export default ({}) => {
  return (
    <Router>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="3E Africa vous accompagne pour changer votre perception du monde numérique. Fondé en 2020, 3E Africa est un cabinet de conseil spécialisé en digitalisation …"
        />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="icon" type="image/png" href="../images/Logo.png" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <title>3eafrica.ma</title>
      </Helmet>
      <AppRoutes />
    </Router>
  );
};
