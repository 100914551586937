import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "../../../Redux/account/types";
import jwt_decode from "jwt-decode";
import "./index.css";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Menu from "../Menu";
import { Badge } from "@mui/material";
import { PendingIcon } from "@components";
import { Fonts } from "@common";
import { CONTACTS } from "@services";
import DashboardImage from "@images/resSmalls/dashboard.jpg";

export default ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLogged } = useSelector(({ account }) => account);
  const [contacts, setContacts] = useState([]);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const displayOnce = useRef(true);

  useEffect(() => {
    if (!isLogged) {
      navigate("/back_ofice/3eafrica/auth/sign_in");
    }
    if (!!user) {
      if (!!user?.token) {
        const { exp } = jwt_decode(user?.token);
        const DateExp = !!user ? new Date(exp * 1000) : null;
        if (DateExp <= new Date()) {
          dispatch({ type: LOGOUT });
          navigate("/back_ofice/3eafrica/auth/sign_in");
        }
      }
    }
  }, [user, isLogged]);

  useEffect(() => {
    if (displayOnce.current) {
      fetchContacts();
      displayOnce.current = false;
    }
  }, []);

  const fetchContacts = () => {
    CONTACTS.ALL()
      .then((result) => {
        if (result) {
          const fromHome = [];
          const fromNotHome = [];
          setContacts(result.data.data);
          result.data.data.map((ele) => {
            if (ele.from === "home") {
              fromHome.push(ele);
            } else {
              fromNotHome.push(ele);
            }
          });
          setFirst(fromHome);
          setSecond(fromNotHome);
          console.log(fromHome, fromNotHome);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error, "@************@ from fetch data");
        }
      });
  };

  const HomeUsersTable = ({ data }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Society</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>from</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user) => (
              <TableRow key={user.email}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.society}</TableCell>
                <TableCell>{user.message}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell><span className="from__dashboard">{user.from === "home" ? "Accueil" : ""} </span></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const ComingSoonUsersTable = ({ data }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Tag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user) => (
              <TableRow key={user.email}>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                <span className="from__dashboard">
                  {user.from == "coomig" ? "Cooming soon" : ""}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${DashboardImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right top",
        backgroundSize: "cover",
        width: "100%",
        height: "100vh",
      }}
    >
      <div className="container_backend">
        <Menu />
        <div
          className="content_backofice"
          style={{ width: "92%", margin: "100px auto" }}
        >
          <Badge
            badgeContent={<PendingIcon />}
            // color="info"
            // sx={{ backgroundColor: "#e38e16" }}
          >
            <div style={{ padding: 5, fontFamily: Fonts().primaryBold }}>
              Users from Home
            </div>
          </Badge>
          <HomeUsersTable data={first} />
          <br />
          <br />
          <Badge
            badgeContent={<PendingIcon />}
            // color="info"
            // sx={{ backgroundColor: "#e38e16" }}
          >
            <div style={{ padding: 5, fontFamily: Fonts().primaryBold }}>
              Users from Coming Soon Page"e
            </div>
          </Badge>
          <ComingSoonUsersTable data={second} />
        </div>
      </div>
    </div>
  );
};
