import React, { useEffect, useState } from "react";
import "../ExternalisationComptabilite/style.css";
import Logo from "@images/Logo2.png";
import Icon6 from "@images/FirstPage/Picture12.png";
import Icon7 from "@images/FirstPage/icon4.png";
import Icon8 from "@images/FirstPage/icon5.png";
import Icon9 from "@images/FirstPage/icon6.png";
import Icon10 from "@images/FirstPage/icon7.png";
import Icon11 from "@images/FirstPage/icon8.png";
import Icon12 from "@images/FirstPage/icon9.png";
import Background from "@images/fond.png";
import { Fonts } from "@common";
import Icon4 from "@images/FirstPage/Picture13.png";
import Icon5 from "@images/FirstPage/Picture14.png";
import Icon18 from "@images/FirstPage/Picture29.png";
import Icon26 from "@images/FirstPage/Picture22.png";

const PortageSlarialAndExternalisation = () => {
  const [transformValue, setTransformValue] = useState(200);

  useEffect(() => {
    const handleResize = () => {
      const newTransformValue = window.innerWidth / 200;
      setTransformValue(newTransformValue);
    };

    window.addEventListener("resize", handleResize);

    // Initial call to set the transform value based on initial window size
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="externalisation_comtapilite">
      <header className="header">
        <img src={Logo} alt="3e Africa" className="logo" />
      </header>
      <main className="main_first_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <section className="intro">
          <h1 style={{ fontFamily: Fonts().primaryBold }}>
            EXTERNALISATION DE LA GRH &amp; PORTAGE SALARIAL
          </h1>
          <p
            className="intro__text"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            En tant que{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              spécialistes de la gestion des ressources humaines
            </strong>
            , nous maîtrisons parfaitement ce secteur et sommes prêts à prendre
            en charge tout ou partie de votre gestion RH.
            <br />
            <br />
            Que ce soit pour des besoins spécifiques, comme le démarrage d'une{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              campagne de recrutement
            </strong>
            , l'élaboration d'un nouveau programme de{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              formation
            </strong>
            , ou pour un
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              accompagnement régulier
            </strong>
            afin de gérer vos activités quotidiennes, notre équipe est à votre
            service.
            <br /> <br /> Notre objectif, vous offrir des{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              services sur mesure et efficaces{" "}
            </strong>{" "}
            qui vous permettent de vous concentrer sur vos activités cœur de
            métier.
          </p>
        </section>
        <section className="content__third_page">
          <div className="benefits_third_page">
            <h2
              className="externaliser__traitement ___third__page"
              style={{ fontFamily: Fonts().primaryBold, textAlign: "start" }}
            >
              Notre offre de services GRH
            </h2>
            <div className="section_list_with_images">
              <div className="list_with_images_left_side">
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon7}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Gestion de la paie
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Recueil des éléments variables.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Calcul des salaires.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Émission des bulletins de paie.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Préparation des ordres de virements…
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon8}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Formation
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Identification des besoins.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Conception des programmes.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Mise en œuvre.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Evaluation de la formation…
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon9}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Administration du personnel
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Gestion des contrats.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Gestion des dossiers du personnel.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Gestion des absences.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Support aux employés.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Gestion des procédures…
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="list_with_images_right_side">
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon10}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Recrutement
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Rédaction des offres d’emploi.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Publication des offres d’emploi.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Collecte et tri des candidatures.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Pré-sélection des candidats.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Vérifications des références…
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon11}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Mise en œuvre de SIRH
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Définition des besoins.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Aide au choix.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Déploiement.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Formation des utilisateurs.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Support…
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon12}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Veille sociale et conseil juridique
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Veille sur les évolutions légales et réglementaires.
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Conseil sur les questions juridiques liées aux RH…
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="alone__pic">
          <img
            src={Icon6}
            className="image__section_src third__page"
            alt="3 EAFRICA"
          />
        </div>
      </main>

      <main className="main_first_page second_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <div className="icons__fixed_left">
          <img
            src={Icon4}
            alt="3e Africa"
            className="icon__fixedleft1 icon__ooooo"
            style={{ transform: `translateX(${window.innerWidth <= 768 ? transformValue + 4 : transformValue  + 3}rem)` }}
          />
          <img
            src={Icon5}
            alt="3e Africa"
            className="icon__fixedleft2 second_page"
            style={{ left: `${window.innerWidth <= 768 ? transformValue + 4 : transformValue + 2}rem` }}
          />
        </div>
        <section className="content">
          <div className="benefits">
            <h1
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold, color: "#bf0101" }}
            >
              Portage salarial au Maroc
            </h1>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Vous voulez faire appel à des{" "}
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                compétences localisées au Maroc
              </strong>{" "}
              mais vous souhaitez vous affranchir de la création d’une structure
              et des tâches administratives correspondantes.{" "}
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                Le portage salarial est la solution la mieux adapté.
              </strong>
              <br />
              <br />
              Le portage salarial est{" "}
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                une relation tripartite{" "}
              </strong>
              entre le{" "}
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                salarié porté
              </strong>{" "}
              qui intervient chez l’entreprise au même titre que les autres
              collaborateurs,{" "}
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                l'entreprise cliente
              </strong>{" "}
              (le donneur d'ordre) et la{" "}
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                société de portage salarial
              </strong>{" "}
              (3E Africa).
              <br /> <br />
              Le salarié et 3E Africa signent un{" "}
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                contrat de travail
              </strong>{" "}
              (CDD ou CDI), tandis que 3E Africa et l'entreprise cliente signent
              un
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                {" "}
                contrat de prestation{" "}
              </strong>
              de services.
              <br />
              <br />
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                3E Africa
              </strong>{" "}
              prend en charge, au titre de son statut d’employeur tous les
              aspects administratifs relatifs au salarié porté.
            </p>
          </div>
        </section>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Pourquoi externaliser ?
            </h2>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              L’externalisation de la GRH ou recourir au portage salarial vous
              permet de vous focaliser sur les tâches essentielles au
              développement de l’activité de votre entreprise (stratégie,
              innovation, business). Ils vous permettent de répondre aux
              exigences croissantes de l’environnement externe à votre
              entreprise. De plus, ils favorisent un processus d’amélioration
              continue de votre métier, en tirant profit des axes ci-dessous :
            </p>
            <ul className="ul__of_list2">
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Réduction des tâches chronophages :
                </strong>{" "}
                L’externalisation soulage le personnel interne des tâches
                administratives quotidiennes, permettant une concentration sur
                les compétences clés.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Expertise spécialisée :
                </strong>{" "}
                L’externalisation de la GRH offre l’expertise d’un partenaire
                choisi pour ses compétences et son expérience en ressources
                humaines.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Réduction des coûts :
                </strong>{" "}
                L’externalisation ou le portage salarial réduisent les coûts.
                (frais de recrutement, poste de responsable RH interne…) et
                évitent les investissements en infrastructures et logiciels
                nécessaires.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Simplicité administrative :
                </strong>{" "}
                La société de portage s'occupe de toutes les démarches
                administratives, fiscales, et légales liées au travail à
                l'étranger.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Intégration rapide des collaborateurs portés :
                </strong>{" "}
                En rejoignant rapidement vos équipes opérationnelles, les
                salariés portés apportent une valeur ajoutée immédiate.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Meilleure compétitivité :
                </strong>{" "}
                L’externalisation ou le portage salarial aident à réduire les
                coûts et à gagner du temps, permettant à l’entreprise de se
                concentrer sur son cœur de métier.
              </li>
            </ul>
          </div>
        </section>
      </main>

      <main className="main_first_page second_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <div className="icons__fixed_left__ligne">
          <img
            src={Icon18}
            alt="3e Africa"
            className="icon__fixed__ligne"
            // style={{ transform: `(${transformValue}rem)` }}
          />
        </div>
        <section className="content page__3_content">
          <div className="benefits">
            <h1
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold, color: "#bf0101" }}
            >
              Notre accompagnement à haute valeur ajoutée
            </h1>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Partenaire de votre croissance, 3E Africa vous propose une
              prestation de service, d’externalisation de la fonction RH et de
              portage salarial qui s’articule autour des axes suivants :
            </p>
            <ul className="ul__of_list2">
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Flexibilité :
                </strong>{" "}
                Nous offrons une flexibilité optimale en nous adaptant
                rapidement à l’évolution de vos besoins de gestion RH.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Faible engagement du client :
                </strong>{" "}
                Notre service nécessite un engagement minimal de votre part,
                vous permettant de vous concentrer sur vos activités
                principales.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Intervenant dédié et autonome :
                </strong>{" "}
                Chaque client bénéficie d’un intervenant dédié qui travaille en
                toute autonomie et gérer efficacement les tâches qui lui sont
                confiées.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Prise de poste rapide :
                </strong>{" "}
                Nos intervenants sont prêts à prendre leur poste rapidement pour
                minimiser les interruptions de service.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Télétravail sur vos créneaux horaires :
                </strong>{" "}
                Nos intervenants travaillent à distance sur les horaires fixés
                ensemble, assurant un service adaptée à votre entreprise.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Haut niveau de confidentialité :
                </strong>{" "}
                Nous respectons un haut niveau de confidentialité pour protéger
                les informations sensibles de nos clients.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Nos intervenants sont nos salariés (CDI) :
                </strong>{" "}
                Nos intervenants sont nos salariés (CDI) :
              </li>
            </ul>
          </div>
        </section>

        <section className="content second__content third__page___">
          <div className="benefits">
            <h2
              className="externaliser__traitement  third__page__"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Comment ça marche ?
            </h2>
          </div>
          <div className="steps_container">
            <div className="steps">
              <div className="step">
                <div
                  className="step-number thirdpage__red one___"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  1
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nous qualifions ensemble votre besoin
                </div>
              </div>
              <div className="step">
                <div
                  className="step-number thirdpage__grey two___"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  2
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nous recrutons le collaborateur qui correspond à vos besoins
                </div>
              </div>
              <div className="step">
                <div
                  className="step-number thirdpage__red three___"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  3
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nous gérons son intégration à vos outils et process
                </div>
              </div>
              <div className="step">
                <div
                  className="step-number thirdpage__grey four___"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  4
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nos collaborateurs prennent en main la gestion de votre RH
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Tarification
            </h2>
            <p
              className="just__paragraph"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Nous proposons différents forfaits adaptés à la taille et aux
              besoins spécifiques de chaque entreprise. Contactez-nous pour une
              évaluation personnalisée et un devis adapté.
            </p>
          </div>
        </section>
        <br />
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Pourquoi Nous Choisir ?
            </h2>
            <p
              className="just__paragraph"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Avec 3E Africa, vous bénéficiez non seulement d'une flexibilité
              accrue et, d’une expertise reconnue, mais aussi d'un partenariat
              basé sur la confiance, la transparence et l'engagement envers la
              réussite de votre entreprise.
            </p>
          </div>
        </section>
        <section className="image__section">
          <img src={Icon26} className="image__section_src_icon6 third__page" alt="Icon6" />
        </section>
      </main>
    </div>
  );
};

export default PortageSlarialAndExternalisation;
