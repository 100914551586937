import React, { useEffect } from "react";
import { useColors, Fonts } from "@common";
import LinkedIn from "@images/icons/LinkedIn.png";
import Phone from "@images/icons/PhoneF.png";
import Location from "@images/icons/Location.png";
import Mail from "@images/icons/Mail.png";
import "./styles/Footer.css";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const { innerWidth } = window;
export const Items = ({ img, text }) => {
  return (
    <div className="footer__items">
      <img className="footer__item_image" src={img} />
      <h5
        className="footer_item_title"
        style={{
          color: "#fff",
          fontFamily: Fonts().primarySemiBold,
          fontSize: "12px",
        }}
      >
        {text}
      </h5>
    </div>
  );
};

export default ({ isIn }) => {
  const navigate = useNavigate();
  const Colors = useColors();

  useEffect(() => {}, [isIn]);

  const handlePolitique = () => {
    navigate("/Politiques_confidentialite");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleMentions = () => {
    navigate("/legal_information");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleclick = (name) => {
    console.log(1);
    navigate(`/nos_services/${name}`);
  };

  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__left_content">
          <div className="wrapper">
            <div className="inside">
              <h1
                className="footer__title"
                style={{
                  fontSize: "18px",
                  color: "#FFF",
                  fontFamily: Fonts().primarySemiBold,
                }}
              >
                A propos de nous
              </h1>
              <p
                className="footer__desc"
                style={{
                  fontSize: "12px",
                  fontFamily: Fonts().primaryRegular,
                  color: "#FFF",
                }}
              >
                Fondé en 2020, 3E Africa est un cabinet de conseil digital
                spécialisé en organisation,
                <br /> management et marketing.
                <br />
                Notre objectif et notre engagement ? <br />
                Vous accompagner durablement pour vous permettre de vous
                concentrer sur la croissance de votre business, sur la stratégie
                et le développement de votre entreprise.
              </p>
            </div>
            <div className="after_about_us">
              <div className="horizontal_border" />

              <div className="footer__items_list">
                {innerWidth > 600 && (
                  <ul className="footer__list_of_items">
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FFF",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                    >
                      Services
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("digitalisation")}
                    >
                      Digitalisation
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("pilotage")}
                    >
                      Performance
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("marketing")}
                    >
                      Marketing Digital
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("externalisation")}
                    >
                      Externalisation
                    </li>
                  </ul>
                )}
              </div>
              <div className="horizontal_border" />
              <div className="footer__items_list">
                {innerWidth > 600 && (
                  <ul className="footer__list_of_items">
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FFF",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={handleMentions}
                    >
                      Mentions légales
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={handlePolitique}
                    >
                      Politique confidentialité
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className="footer__right_content">
              {innerWidth <= 600 && (
                <div className="responsive_list_footer">
                  <ul className="footer__list_of_items">
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FFF",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                    >
                      Services
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("digitalisation")}
                    >
                      Digitalisation
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("pilotage")}
                    >
                      Performance
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("marketing")}
                    >
                      Marketing Digital
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("externalisation")}
                    >
                      Externalisation
                    </li>
                  </ul>
                  <div className="horizontal_border vertical_responsive" />
                  <ul className="footer__list_of_items">
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FFF",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => navigate("/legal_information")}
                    >
                      Mentions légales
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "rgb(200 200 200)",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => navigate("/Politiques_confidentialite")}
                    >
                      Politique confidentialité
                    </li>
                  </ul>
                </div>
              )}
              {innerWidth > 600 && (
                <div className="icons__footer">
                  {" "}
                  <Items img={Phone} text="+212 6 74 85 39 92" />
                  <Items img={Mail} text="contact@3eafrica.ma" />
                  <Items img={LinkedIn} text="3E AFRICA" />
                  <Items
                    img={Location}
                    text="6 rue Ain Asserdoun angle 56 Avenue, Agdal-Rabat, Maroc"
                  />
                </div>
              )}
              {innerWidth <= 600 && (
                <div className="icons__footer">
                  {" "}
                  <Items img={Phone} text="+212 6 74 85 39 92" />
                  <Items img={Mail} text="contact@3eafrica.ma" />
                  <Items
                    img={Location}
                    text="6 rue Ain Asserdoun angle 56 Avenue, Agdal-Rabat, Maroc"
                  />
                  <Items img={LinkedIn} text="3E AFRICA" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer__foter"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="copyRight__inside">
          <p
            className="copyRghit"
            style={{
              fontFamily: Fonts().primaryRegular,
              fontSize: 12,
              color: "#FFF",
            }}
          >
            © copyright 2023
          </p>
        </div>

        <div
          className="socialMedia__icons"
          style={{ display: "flex", alignItems: "center" }}
        >
          <IconButton
            color="primary"
            component="a"
            href="https://web.facebook.com/profile.php?id=61559386425515"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon style={{ color: "#FFF" }} />
          </IconButton>
          <IconButton
            color="primary"
            component="a"
            href="https://www.instagram.com/3eafrica/?next=%2F"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon style={{ color: "#FFF" }} />
          </IconButton>
          <IconButton
            color="primary"
            component="a"
            href="https://www.linkedin.com/company/3e-africa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon style={{ color: "#FFF" }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
