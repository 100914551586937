import React, { useState, useRef } from "react";
import Back2 from "@images/backgrounds2/Back2.png";
import ResBack2 from "@images/responsive/Back2.png";
import { useColors, Fonts } from "@common";
import { Boxes } from "@components";
import Increase from "@images/imgs/increase.png";
import database from "@images/imgs/database.png";
import laptop from "@images/imgs/laptop.png";
import planet from "@images/imgs/planet.png";
import marketing from "@images/imgs/marketing.png";
import hover_planet from "@images/imgs/hover_planet.png";
import hover_increase from "@images/imgs/hover_increase.png";
import hover_database from "@images/imgs/hover_database.png";
import hover_laptop from "@images/imgs/hover_laptop.png";
import pic1 from "@images/icons/pic.png";
import pic2 from "@images/icons/pic2.png";
import pic3 from "@images/icons/pic3.png";
import "./styles/Section2.css";

const { innerHeight, innerWidth } = window;
export const Items = ({ icon, title, desc }) => {
  const Colors = useColors();
  return (
    <div className="items__left__content">
      {" "}
      <img className="left_conetnt_icon" src={icon} />{" "}
      <div className="items__after_image__text">
        <strong
          className="title__items"
          style={{
            fontSize: innerWidth > 600 ? "20px" : "10px",
            color: Colors.textColors,
            fontFamily: Fonts().primarySemiBold,
          }}
        >
          {title}
        </strong>
        <br />
        <span
          className="desc__items"
          style={{
            color: Colors.textColors,
            fontFamily: Fonts().primaryRegular,
            fontSize: innerWidth > 600 ? "20px" : "8px",
          }}
        >
          {desc}
        </span>
      </div>
    </div>
  );
};

const TEXT = (
  <span>
    Pilotage <br /> de la performance
  </span>
);
const TEXT2 = (
  <span>
    Digitalisation <br /> des processus métier
  </span>
);

export default ({
  Ref,
  isClicked,
  idBox,
  handleClicked,
  onMouseEnter,
  onMouseLeave,
}) => {
  const Colors = useColors();

  return (
    <div
      ref={Ref}
      className="section2__main"
      style={{
        backgroundImage: `url(${innerWidth > 600 ? Back2 : ResBack2})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
        height: "auto",
        position: "relative",
      }}
    >
      <div className="section2__top">
        <div className="section2__content_top">
          <p
            style={{
              color: Colors.error,
              fontFamily: Fonts().primaryBold,
              fontSize: innerWidth > 600 ? "50px" : "18px",
            }}
          >
            Nos Services
          </p>
          <h4
            style={{
              color: Colors.textColors,
              fontFamily: Fonts().primaryBold,
              fontSize: innerWidth > 600 ? "36px" : "14px",
              marginTop: innerWidth > 600 ? 12 : 4,
            }}
          >
            Une offre construite autour du Digital
          </h4>
        </div>
      </div>

      <div className="section2__center__content">
        <Boxes
          topText={TEXT}
          image1={Increase}
          image2={hover_increase}
          // bottomText="Lorem ipsum dolor, act screen 1500's evolution tech"
          isClicked={isClicked}
          handleClicked={() => handleClicked(1)}
          id={1}
          onMouseEnter={() => onMouseEnter(1)}
          onMouseLeave={() => onMouseLeave(1)}
        />
        <Boxes
          topText="Marketing digital"
          image2={laptop}
          image1={marketing}
          // bottomText="Lorem ipsum dolor, act screen 1500's evolution tech"
          isClicked={isClicked}
          handleClicked={() => handleClicked(2)}
          id={2}
          onMouseEnter={() => onMouseEnter(2)}
          onMouseLeave={() => onMouseLeave(2)}
        />
        <Boxes
          topText={TEXT2}
          image1={database}
          image2={hover_database}
          // bottomText="Lorem ipsum dolor, act screen 1500's evolution tech"
          isClicked={isClicked}
          handleClicked={() => handleClicked(3)}
          onMouseEnter={() => onMouseEnter(3)}
          onMouseLeave={() => onMouseLeave(3)}
          id={3}
        />
        <Boxes
          topText="Externalisation"
          image1={planet}
          image2={hover_planet}
          // bottomText="Lorem ipsum dolor, act screen 1500's evolution tech"
          isClicked={isClicked}
          handleClicked={() => handleClicked(4)}
          id={4}
          onMouseEnter={() => onMouseEnter(4)}
          onMouseLeave={() => onMouseLeave(4)}
        />
      </div>
      <div className="section2__bottom__content">
        <div className="bottom__content__right__content">
          <p
            className="who__we_are"
            style={{
              // fontSize: innerWidth > 600 ? "15px" : "10px",
              fontFamily: Fonts().primarySemiBold,
              color: Colors.error,
            }}
          >
            QUI SOMMES-NOUS ?
          </p>
          <h1
            className="section2__second_text"
            style={{
              fontSize: innerWidth > 600 ? "33px" : "13px",
              fontFamily: Fonts().primaryBold,
              color: Colors.textColors,
              marginTop: innerWidth > 600 ? 16 : 6,
            }}
          >
            3E Africa vous accompagne {innerWidth < 600 && <br />}pour{" "}
            <span style={{ color: Colors.error }}>changer</span>{" "}
            {innerWidth > 600 && <br />} votre perception{" "}
            {innerWidth < 600 && <br />} du monde{" "}
            <span style={{ color: Colors.error }}>numérique</span>
          </h1>
          <p
            className="descreption__bottom__conetnt__righit"
            style={{
              fontSize: innerWidth > 600 ? "20px" : "7px",
              fontFamily: Fonts().primaryRegular,
              color: Colors.textColors,
              lineHeight: 1.6,
              marginTop: innerWidth > 600 ? 12 : 7,
            }}
          >
            Fondé en 2020,
            <strong style={{ fontFamily: Fonts().primarySemiBold }}>
              {" "}
              3E Africa{" "}
            </strong>
            est un cabinet de conseil digital {innerWidth < 600 && <br />}
            <strong style={{ fontFamily: Fonts().primarySemiBold }}>
              spécialisé en organisation, management et marketing.
            </strong>{" "}
            <br />
            <strong style={{ fontFamily: Fonts().primarySemiBold }}>
              {" "}
              Notre objectif et notre engagement
            </strong>{" "}  <br />
            <strong
              style={{
                color: Colors.error,
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
              className="vous__accompagner"
            >
              Vous accompagner durablement en vous apportant{" "}
              {innerWidth < 600 && <br />} des prestations à forte valeur
              ajoutée.
            </strong>
          </p>
        </div>
        {/* <div className="bottom_content_horizontal__border" /> */}
        <div className="bottom__content__left_content">
          <Items
            icon={pic3}
            title="+30 collaborateurs"
            desc="Engagés pour vous accompagner au Maroc et en France"
          />
          {/* <Items
            icon={pic2}
            title="+150 Projets"
            desc="Vous trouverez la meilleure qualité de service et de dévouement"
          /> */}
          <Items
            icon={pic1}
            title="+50 Projets"
            desc="Déjà réalisés pour des clients qui recommandent notre professionnalisme, notre réactivité et nos compétences"
          />
        </div>
      </div>
    </div>
  );
};
