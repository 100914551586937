import React, { useEffect, useState } from "react";
import "./style.css";
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { BLOGS } from "@services";
import { format } from "date-fns";
import { Fonts } from "@common";
import { CiClock2 } from "react-icons/ci";

const URL =
  document.location.protocol + "//" + "3eafrica.ma/app" + "/images/blogs/";

export default ({}) => {
  const params = useParams();
  const { title } = params;
  const [single, setSingle] = useState(null);
  const [iseLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSingleBlog();
  }, []);

  const fetchSingleBlog = () => {
    setIsLoading(true);
    const titleI = title.split("_")[0];
    console.log(titleI, "**********");
    BLOGS.ONE(titleI)
      .then((res) => {
        console.log(res.data.data, "*@@@@@@@@");
        setIsLoading(false);
        setSingle(res.data.data);
      })
      .catch((error) => {
        setIsLoading(true);
        if (error) {
          console.log(
            error,
            "********** catxhed when fitching single blog ********"
          );
        }
      });
  };

  return (
    <div className="single_blog_container">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <article className="blog-card2">
              <div className="blog-card__background">
                <div className="card__background--wrapper">
                  <div
                    className="card__background--main"
                    style={{
                      backgroundImage: `url(${URL + single?.image})`,
                    }}
                  >
                    <div className="card__background--layer"></div>
                  </div>
                </div>
              </div>
              <div className="blog-card__head">
                <span className="date__box">
                  <span className="date__day">11</span>
                  <span className="date__month">JAN</span>
                </span>
              </div>
              <div className="blog-card__info">
                <h5
                  className="blog_detail_title"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  {single?.title}
                </h5>
                <p style={{ marginTop: 8 }}>
                  <a className="icon-link mr-3">
                    <CiClock2 style={{ fontSize: "13px", marginTop: "5px" }} />{" "}
                    <i className="fa fa-pencil-square-o"></i>{" "}
                    {format(
                      new Date(!!single && single?.createdAt),
                      "dd-MM-yyyy HH:mm"
                    )}
                  </a>
                </p>
                <p
                  style={{
                    fontFamily: Fonts().primaryRegular,
                    textAlign: "justify",
                    marginTop: 20,
                  }}
                >
                  {single?.description}
                </p>
                <a
                  className="btn btn--with-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                >
                  <i
                    className="btn-icon fa fa-long-arrow-right"
                    onClick={() => navigate("/")}
                  >
                    <IoArrowBackSharp
                      style={{
                        position: "relative",
                        top: "4px",
                        fontSize: 18,
                        fontFamily: Fonts().primaryRegular,
                      }}
                    />
                  </i>
                  RETOURNER
                </a>
              </div>
            </article>
          </div>
        </div>
      </div>

      <section className="detail-page">
        <div className="container mt-5"></div>
      </section>
    </div>
  );
};
