import React, { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Computer from "@images/backgrounds2/Computer.png";
import logo from "@images/Logo.png";
import { Fonts } from "@common";
import { Button } from "@components";
import { USERS } from "@services";
import { toast } from "react-toastify";
import { LOGIN } from "../../../Redux/account/types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const TheDate = new Date();
const { innerWidth, innerHeight } = window;
const SignInPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoaing] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);

  const handleSignIn = () => {
    setIsLoaing(true);
    const params = {
      email: email,
      password: password
    };
    USERS.SignIn(params)
      .then((data) => {
        setIsLoaing(false);
        if (data) {
          dispatch({ type: LOGIN, payload: data.data.data });
          toast.success("Vous êtes connecté", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setPassword("");
        setEmail("");
        navigate(
          `/back_ofice/3eafrica/administration/data/${
            TheDate.getTime().toString() +
            "_" +
            TheDate.getTimezoneOffset().toString()
          }`
        );
      })
      .catch((error) => {
        setIsLoaing(false);
        if (error) {
          const errorMessage = error?.response?.data?.message;
          toast.error(
            !!errorMessage ? errorMessage : "Quelque chose n'a pas fonctionné",
            {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
      }}
    >
      <div
        style={{
          flex: "1 0 70%", // Take 30% width of the screen
          backgroundImage: `url(${Computer})`, // Replace with your image path
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <img
          src={logo}
          style={{ position: "absolute", top: 20, left: 20, width: 70 }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: "1 0 30%", // Take 70% width of the screen
          backgroundColor: "#e74c3c", // Background color
          color: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <h1
            style={{
              fontFamily: Fonts().primaryBold,
              marginBottom: "1rem",
              marginBottom: 30,
              fontSize: 22,
            }}
          >
            Bienvenue sur le Dashboard de 3eafrica (Connexion 👋)
          </h1>
          <TextField
            onChange={(e) => handleChangeEmail(e)}
            value={email}
            label="Email"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "1rem",
              "& .MuiInputLabel-root": {
                color: "#FFF", // Custom label color
              },
              "& .MuiOutlinedInput-root": {
                // "& fieldset": {
                //   borderColor: "#e74c3c", // Custom focused border color
                //   borderWidth: 2, // Add additional border width
                // },
                "& .MuiInputLabel-root": {
                  color: "#FFF", // Custom label color
                },
                "&:hover fieldset": {
                  borderColor: "#FFF", // Custom hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#FFF", // Custom focused border color
                  borderWidth: 2, // Add additional border width
                },
              },
            }}
          />
          <TextField
            onChange={(e) => handleChangePassword(e)}
            value={password}
            label="Mot de passe"
            variant="outlined"
            fullWidth
            type={showPassword ? "text" : "password"}
            sx={{
              "& .MuiInputLabel-root": {
                color: "#FFF", // Custom label color
              },
              "& .MuiOutlinedInput-root": {
                // "& fieldset": {
                //   borderColor: "#e74c3c", // Custom focused border color
                //   borderWidth: 2, // Add additional border width
                // },
                "& .MuiInputLabel-root": {
                  color: "#FFF", // Custom label color
                },
                "&:hover fieldset": {
                  borderColor: "#FFF", // Custom hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#FFF", // Custom focused border color
                  borderWidth: 2, // Add additional border width
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button
            isLoading={isLoading}
            Click={handleSignIn}
            Label="Connexion"
            style={{
              width: "100%",
              background: "transparent",
              color: "#FFF",
              fontSize: innerWidth > 600 ? "13px" : "11px",
              textTransform: "capitalize",
              fontWeight: "400",
              borderRadius: "14px",
              padding: innerWidth > 600 ? "14px" : "12px",
              marginTop: "2rem",
              marginRight: innerWidth > 600 ? "40px" : "0px",
              border: "1px solid #FFF",
              margin: "22px auto",
            }}
          />
          {/* <Button
            isLoading={isLoading}
            Click={() => navigate("/back_ofice/3eafrica/auth/sign_up")}
            Label="Créer un compte"
            style={{
              width: "100%",
              background: "transparent",
              color: "#FFF",
              fontSize: innerWidth > 600 ? "13px" : "11px",
              textTransform: "capitalize",
              fontWeight: "400",
              borderRadius: "14px",
              padding: innerWidth > 600 ? "14px" : "12px",
              marginTop: "2rem",
              marginRight: innerWidth > 600 ? "40px" : "0px",
              border: "1px solid #FFF",
              margin: "0px auto",
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
