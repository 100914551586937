import React, { useEffect, useState } from "react";
import "./style.css";
import Logo from "@images/Logo2.png";
import Icon0 from "@images/FirstPage/Picture2.png";
import Icon1 from "@images/FirstPage/Picture3.png";
import Icon2 from "@images/FirstPage/Picture1.png";
import Icon3 from "@images/FirstPage/Picture4.png";
import Icon4 from "@images/FirstPage/Picture13.png";
import Icon5 from "@images/FirstPage/Picture14.png";
import Icon6 from "@images/FirstPage/Picture23.png";
import Background from "@images/fond.png";
import { Fonts } from "@common";

const ExternalisationComtapilite = () => {
  const [transformValue, setTransformValue] = useState(200);

  useEffect(() => {
    const handleResize = () => {
      const newTransformValue = window.innerWidth / 200;
      setTransformValue(newTransformValue);
    };

    window.addEventListener("resize", handleResize);

    // Initial call to set the transform value based on initial window size
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="externalisation_comtapilite">
      <header className="header">
        <img src={Logo} alt="3e Africa" className="logo" />
      </header>
      <main className="main_first_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <div className="icons__fixed_left">
          <img
            src={Icon0}
            alt="3e Africa"
            className="icon__fixedleft1"
            style={{ transform: `translateX(${transformValue + 1}rem)` }}
          />
          <img
            src={Icon1}
            alt="3e Africa"
            className="icon__fixedleft2"
            style={{ left: `${transformValue + 1}rem` }}
          />
        </div>
        <section className="intro">
          <h1 style={{ fontFamily: Fonts().primaryBold }}>
            L'EXTERNALISATION DE LA COMPTABILITÉ
          </h1>
          <p className="intro-bold" style={{ fontFamily: Fonts().primaryBold }}>
            L’externalisation comptable est la solution pour remédier aux
            pénuries de ressources rencontrées par les cabinets d’expertise
            comptable et les entreprises.
          </p>
          <p
            className="intro__text"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            Pour les cabinets d’expertise comptable et les entreprises,
            l’externalisation de la saisie comptable offre des avantages
            économiques{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              en résolvant les défis de recrutement{" "}
            </strong>{" "}
            et en réduisant les coûts fixes.
          </p>
        </section>
        <section className="image__section">
          <img src={Icon2} className="image__section_src" alt="Icon2" />
        </section>
        <section className="content">
          <div className="text">
            <p
              className="second__para"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              <strong style={{ fontFamily: Fonts().primaryBold }}>
                3E Africa
              </strong>{" "}
              offre un service complet de saisie comptable pour les entreprises
              ou les cabinets ayant un projet d’externalisation de tout ou
              partie du traitement de leurs opérations comptable. Notre équipe
              utilise les dernières technologies pour garantir une gestion
              comptable précise, efficace et conforme aux normes en vigueur.
            </p>
          </div>
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Pourquoi externaliser le traitement des opérations comptables ?
            </h2>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Pénurie de profils :
                </strong>{" "}
                pallier le manque de ressources systémique pour les
                experts-comptables.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Coût du travail maîtrisé :
                </strong>{" "}
                moins onéreux qu’en France avec un temps de travail hebdomadaire
                de 44 heures.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Gain de temps :
                </strong>{" "}
                concentrez-vous sur les opérations à fortes valeur ajoutée et
                laissez-nous gérer les tâches chronophages, fastidieuses et
                répétitives.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Le décalage horaire entre la France et le Maroc est de
                  seulement 1 heure.
                </strong>
              </li>
            </ul>
          </div>
        </section>
      </main>

      <main className="main_first_page second_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Les services que nous proposons
            </h2>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Saisie des opérations comptables :
                </strong>{" "}
                enregistrement des achats, ventes, frais généraux, notes de
                frais, immobilisations, paies et autres opérations financières.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Saisie des opérations de trésorerie :
                </strong>{" "}
                enregistrement des chèques, espèces, virements.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Rapprochement bancaire :
                </strong>{" "}
                vérification de la concordance entre les comptes bancaires et
                les enregistrements comptables.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Production comptable :
                </strong>
                pointage des comptes, lettrage, écritures de Cut off, saisie des
                OD, suivi des comptes de tiers…
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Déclarations fiscales et sociales :
                </strong>
                préparation et soumission des déclarations de TVA, impôts sur
                les sociétés…
              </li>
            </ul>
          </div>
        </section>

        <section className="content second__content have__horizontal_content">
          <section className="image__section">
            <img src={Icon3} className="image__section_src" alt="Icon2" />
          </section>
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Comment ça marche ?
            </h2>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Collecte des pièces (transmission des documents numérisés ou
                accès direct au serveur à l’aide de mot de passe).
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Vérification et classement des documents.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Codification et saisie des écritures sur différents logiciels
                (nos collaborateurs sont polyvalents sur les principaux
                logiciels utilisés par les cabinets d’expertise comptable).
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Réalisation des travaux de production comptable et des
                déclarations.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Envoi d’un compte rendu au client sur les tâches réalisées et
                les éventuelles difficultés rencontrées.
              </li>
            </ul>
          </div>
        </section>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Nos engagements
            </h2>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                N’utiliser que des documents numérisés transmis ou obtenus par
                flux sécurisés.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Vous informer sur l’état de vos dossiers de tenue externalisés,
                grâce à un système de suivi d’avancement des travaux.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Gérer à distance et de manière sécurisée les données de vos
                clients.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Respecter le secret professionnel et de la confidentialité des
                documents communiqués.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Pas de prise de contact avec vos clients.
              </li>
            </ul>
          </div>
        </section>

        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Technologie et Sécurité
            </h2>
            <p
              className="just__paragraph"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Nous utilisons vos outils (logiciels comptables ou solutions cloud
              sécurisées) pour garantir la confidentialité et l'intégrité des
              données transmises. La sécurité informatique est une priorité
              absolue, avec des protocoles de cryptage avancés et des
              sauvegardes régulières.
            </p>
          </div>
        </section>
      </main>

      <main className="main_first_page second_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <div className="icons__fixed_left second_pick2">
          <img
            src={Icon4}
            alt="3e Africa"
            className="icon__fixedleft1 second__picks"
            style={{ transform: `translateX(${window.innerWidth  <= 1335 ? window.innerWidth <= 750 ? transformValue + 4 : transformValue + 2 : transformValue}rem)` }}
          />
          <img
            src={Icon5}
            alt="3e Africa"
            className="icon__fixedleft2 second__picks"
            style={{ left: `${window.innerWidth  <= 1335 ? window.innerWidth <= 750 ? transformValue + 2 : transformValue :transformValue - 2}rem` }}
          />
        </div>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Tarification
            </h2>
            <p
              className="just__paragraph"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Nous proposons différents forfaits adaptés à la taille et aux
              besoins spécifiques de chaque entreprise. Contactez-nous pour une
              évaluation personnalisée et un devis adapté.
            </p>
          </div>
        </section>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Pourquoi Nous Choisir ?
            </h2>
            <p
              className="just__paragraph"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Avec 3E Africa, vous bénéficiez non seulement d'une compétence
              comptable reconnue, mais aussi d'un partenariat basé sur la
              confiance, la transparence et l'engagement envers la réussite de
              votre entreprise.
            </p>
          </div>
        </section>
        <section className="image__section">
          <img src={Icon6} className="image__section_src_icon6" alt="Icon6" />
        </section>
      </main>
      <main className="main_first_page last__section">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <section className="intro">
          <h1 style={{ fontFamily: Fonts().primaryBold }}>
            TARIFICATION en € POUR LES CABINETS
          </h1>
        </section>

        <section className="table__last_page">
          <table className="styled-table">
            <thead>
              <tr>
                <th style={{textAlign: "start", fontFamily: Fonts().primaryBold}}>Tenue comptabilité</th>
                <th style={{textAlign: "end", fontFamily: Fonts().primaryBold}}>Tarification</th>
              </tr>
            </thead>
            <tbody className="eeafrica__tbody">
              <tr>
                <td style={{fontFamily: Fonts().primaryBold}}>Saisie des ventes - achats - NDF - lettrage</td>
                <td></td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryRegular}}>par pièce</td>
                <td style={{fontFamily: Fonts().primaryBold, textAlign: "end"}}>0.35</td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryBold}}>Saisie banque manuellement</td>
                <td></td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryRegular}}>par ligne</td>
                <td style={{fontFamily: Fonts().primaryBold, textAlign: "end"}}>0.20</td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryBold}}>Rapprochement bancaire</td>
                <td></td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryRegular}}>Part fixe</td>
                <td style={{fontFamily: Fonts().primaryBold, textAlign: "end"}}>6.00</td>
              </tr>
              <tr style={{background: "transparent"}}>
                <td style={{fontFamily: Fonts().primaryBold}}>Part variable par ligne</td>
                <td style={{fontFamily: Fonts().primaryBold, textAlign: "end"}}>0.20</td>
              </tr>
              <tr className="highlight">
                <td style={{fontFamily: Fonts().primaryBold}}>Déclaration de TVA (sans contrôle de TVA)</td>
                <td></td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryBold}}>TVA régime simplifié (CA12)</td>
                <td></td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryRegular}}>Par déclaration</td>
                <td style={{fontFamily: Fonts().primaryBold, textAlign: "end"}}>12.00</td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryBold}}>TVA régime simplifié (acomptes)</td>
                <td></td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryRegular}}>Par déclaration</td>
                <td style={{fontFamily: Fonts().primaryBold, textAlign: "end"}}>5.00</td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryBold}}>TVA régime normal (CA3)</td>
                <td></td>
              </tr>
              <tr>
                <td style={{fontFamily: Fonts().primaryRegular}}>Part fixe par déclaration</td>
                <td style={{fontFamily: Fonts().primaryBold, textAlign: "end"}}>5.00</td>
              </tr>
              <tr style={{background: "transparent"}}>
                <td style={{fontFamily: Fonts().primaryRegular}}>Part variable par ligne</td>
                <td style={{fontFamily: Fonts().primaryBold, textAlign: "end"}}>0.20</td>
              </tr>
              <tr style={{background: "#eae9e9"}}>
                <td style={{fontFamily: Fonts().primaryBold}}>Administratif</td>
                <td></td>
              </tr>
              <tr style={{background: "transparent"}}>
                <td style={{fontFamily: Fonts().primaryRegular}}>Par dossier per an</td>
                <td style={{fontFamily: Fonts().primaryBold, textAlign: "end"}}>25.00</td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default ExternalisationComtapilite;
