import React from "react";
import { URL } from "@common";
// import { store } from "../../redux/store";
const axios = require("axios").default;

export const backend = axios.create({
  baseURL: URL, //dev local
  headers: { "Content-Type": "application/json" },
});

// backend.interceptors.request.use(
//   (config) => {
//     const lang = store?.getState()?.account?.language;
//     config.headers["Accept-Language"] = !!lang ? lang : "EN";
//     return config;
//   },
//   (error) => {
//     if (error) {
//       return error;
//       //   navigate("/");
//     }
//   }
// );