import React, { useEffect, useState } from "react";
import "../ExternalisationComptabilite/style.css";
import Logo from "@images/Logo2.png";
import Icon0 from "@images/FirstPage/Picture2.png";
import Icon1 from "@images/FirstPage/Picture3.png";
import Icon2 from "@images/FirstPage/Picture8.png";
import Icon7 from "@images/FirstPage/Picture9.png";
import Icon8 from "@images/FirstPage/Picture12.png";
import Background from "@images/fond.png";
import { Fonts } from "@common";
import Icon5 from "@images/FirstPage/Picture14.png";
import Icon4 from "@images/FirstPage/Picture13.png";
import Icon01 from "@images/FirstPage/icon1.png";
import Icon02 from "@images/FirstPage/icon2.png";
import Icon03 from "@images/FirstPage/icon3.png";
import Icon15 from "@images/FirstPage/Picture15.png";

const OfficeManagement = () => {
  const [transformValue, setTransformValue] = useState(200);

  useEffect(() => {
    const handleResize = () => {
      const newTransformValue = window.innerWidth / 200;
      setTransformValue(newTransformValue);
    };

    window.addEventListener("resize", handleResize);

    // Initial call to set the transform value based on initial window size
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="externalisation_comtapilite">
      <header className="header">
        <img src={Logo} alt="3e Africa" className="logo" />
      </header>
      <main className="main_first_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <div className="icons__fixed_left">
          <img
            src={Icon0}
            alt="3e Africa"
            className="icon__fixedleft1"
            style={{ transform: `translateX(${window.innerWidth <= 1100 ?  window.innerWidth <= 770 ? transformValue + 4 : transformValue + 2 : transformValue}rem)` }}
          />
          <img
            src={Icon1}
            alt="3e Africa"
            className="icon__fixedleft2"
            style={{ left: `${window.innerWidth <= 1100 ?   window.innerWidth <= 770 ? transformValue + 4 : transformValue + 2 : transformValue}rem` }}
          />
        </div>
        <section className="intro">
          <h1 style={{ fontFamily: Fonts().primaryBold }}>
            L’OFFICE MANAGEMENT A LA CARTE
          </h1>
          <p
            className="intro__text"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            Startup, TPE ou PME profitez d’une solution d’externalisation
            offshore de la fonction d’office manager. Concentrez-vous sur le
            développement de votre activité et confiez-nous le reste. 3E Africa
            avec son équipe d’office managers assure, en temps partagé, la
            gestion organisationnelle et administrative de votre entreprise.
          </p>
        </section>
        <section className="image__section">
          <img src={Icon2} className="image__section_src" alt="Icon2" />
        </section>
        <section className="content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Mais, qu’est-ce qu’un office manager ?
            </h2>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Office manager, une notion peu répandue en France ou en Afrique
              Francophone, que nous assimilons souvent, par ses fonctions, au
              poste d’assistant de direction. L’office manager joue un rôle
              central au sein des organisations. Afin de veiller au bon
              fonctionnement de l’entreprise, c’est un interlocuteur privilégié
              de la direction, des responsables hiérarchiques et du personnel.
              L’office manager occupe un poste de coordination polyvalent au
              sein des entreprises, véritable interface entre l’entreprise et
              ses contreparties (administration, fournisseurs, expert-comptable,
              avocat, banque, candidats, …). La diversité des missions de
              l’office manager l’amène à exercer ses compétences dans différents
              domaines.
            </p>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                le secrétariat courant
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                l’assistanat de direction
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                la préparation comptable
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                le suivi des budgets et la préparation des reporting
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                l’administration des ventes
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                l’organisation de manifestation événementielles
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                les services généraux
              </li>
            </ul>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              En résumé la fonction d’office manager a pour objectif d’alléger
              les dirigeants afin qu’ils se focalisent sur l’aspect commercial
              et managérial de leur entreprise.
            </p>
          </div>
          <div className="benefits add__margin_top">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Pourquoi externaliser la fonction d’office manager ?
            </h2>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Souvent, la réduction des coûts est la principale raison, à juste
              titre, mais l’outsourcing présente bien d’autres avantages. <br />
              Pour les petites ou moyennes structures notamment, c’est une
              alternative très efficace au recrutement traditionnel.
            </p>
          </div>
        </section>
      </main>

      <main className="main_first_page second_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <section className="content second__content">
          <img src={Icon7} className="image__absolute_position" alt="Icon7" />
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Notre offre d’accompagnement à haute valeur ajoutée
            </h2>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Partenaire de votre croissance, 3E Africa vous propose une
              prestation de service, d’externalisation offshore de la fonction
              office manager qui s’articule autour des axes suivants :
            </p>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Flexibilité
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Faible engagement du client
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Facturation au temps passé
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Intervenant dédié et autonome
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Prise de poste rapide
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Solution clé en main
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Télétravail sur vos créneaux horaires
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Haut niveau de confidentialité
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Nos intervenants sont nos salariés (CDI)
              </li>
            </ul>
          </div>
        </section>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Une intervention de qualité par des consultants expérimentés
            </h2>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Nos collaborateurs disposent de savoir-faire étendus, pour
              s’adapter au mieux à toutes les situations
            </p>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Utilisation d’outils bureautiques et collaboratifs
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Techniques de gestion administrative
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Communication
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Coordination
              </li>
            </ul>
          </div>
        </section>

        <section className="content second__content">
          <img
            src={Icon8}
            className="image__absolute_position second_image"
            alt="Icon7"
          />
          <div className="benefits">
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Et d’excellents Soft skills, des compétences non techniques mais
              indispensables.
            </p>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Pro-activité
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Autonomie
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Polyvalence
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Rigueur
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Capacité d’adaptation
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Qualités relationnelles
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Attitude Positive
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Travail d’équipe
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Éthique au travail
              </li>
            </ul>
          </div>
        </section>
        <br />
        <br />
        <section className="content second__content items__number">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Comment ça marche ?
            </h2>
          </div>
          <div className="steps_container">
            <div className="steps">
              <div className="step">
                <div
                  className="step-number"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  1
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nous qualifions ensemble vos besoins
                </div>
              </div>
              <div className="step">
                <div
                  className="step-number  responsive2"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  2
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nous vous présentons le personnel le mieux adapté à votre
                  besoin
                </div>
              </div>
              <div className="step">
                <div
                  className="step-number responsive3"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  3
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Notre collaborateur(trice) travaille à distance pour vous sur
                  le temps convenu
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <br />
      <main className="main_first_page second_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <div className="icons__fixed_left second_pick3">
          <img
            src={Icon4}
            alt="3e Africa"
            className="icon__fixedleft1"
            style={{ transform: `translateX(${window.innerWidth <= 768 ? transformValue + 4 : transformValue}rem)` }}
          />
          <img
            src={Icon5}
            alt="3e Africa"
            className="icon__fixedleft2 second_page"
            style={{ left: `${window.innerWidth <= 768 ? transformValue + 4 : transformValue - 2}rem` }}
          />
        </div>
        <div className="ligne_fixed_icon">
          <img
            src={Icon15}
            alt="3e Africa"
            className="icon__fixed___ligne"
            style={{ right: `${transformValue - 2}rem` }}
          />
        </div>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Les services que nous proposons
            </h2>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Selon la taille de votre entreprise et votre choix, l'office
              manager Nous construisons ensemble le planning d’intervention,
              récurrent et sur mesure.
            </p>
          </div>
        </section>

        <section className="content items__icoms_gestion_admin">
          <div className="section__gestion_admin">
            <div className="section">
              <div className="icon">
                <img src={Icon01} alt="Gestion et administration" />
              </div>
              <div className="content">
                <h2
                 className="title__of__list"
                  style={{fontFamily: Fonts().primaryRegular }}
                >
                  Gestion et administration
                </h2>
                <ul className="ul__of_list">
                  <li
                    className="li___getsion"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Assistance polyvalente
                  </li>
                  <li
                    className="li___getsion"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Assistanat de direction
                  </li>
                  <li
                    className="li___getsion"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Traitement des mails
                  </li>
                  <li
                    className="li___getsion"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Assistanat personnel
                  </li>
                  <li
                    className="li___getsion"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Veille au respect des procédures
                  </li>
                  <li
                    className="li___getsion"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Optimisation des process interne
                  </li>
                </ul>
              </div>
            </div>

            <div className="section">
              <div className="icon">
                <img src={Icon02} alt="Finance" />
              </div>
              <div className="content">
                <h2
                 className="title__of__list"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Finance
                </h2>
                <ul className="ul__of_list">
                  <li
                    className="li___finance"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Récupération des pièces comptables
                  </li>
                  <li
                    className="li___finance"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Facturation et relance client
                  </li>
                  <li
                    className="li___finance"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Gestion administrative
                  </li>
                  <li
                    className="li___finance"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Tenue de tableaux de bord
                  </li>
                  <li
                    className="li___finance"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Émission de virements
                  </li>
                </ul>
              </div>
            </div>

            <div className="section">
              <div className="icon">
                <img src={Icon03} alt="Ressources humaines, juridique" />
              </div>
              <div className="content">
                <h2
                className="title__of__list"
                  style={{fontFamily: Fonts().primaryRegular }}
                >
                  Ressources humaines, juridique
                </h2>
                <ul className="ul__of_list">
                  <li
                    className="li___resources"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Gestion des éléments variables de paie et des NDF
                  </li>
                  <li
                    className="li___resources"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Administration du personnel (absence, congés)
                  </li>
                  <li
                    className="li___resources"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Contrats de travail
                  </li>
                  <li
                    className="li___resources"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Recrutement
                  </li>
                  <li
                    className="li___resources"
                    style={{ fontFamily: Fonts().primaryRegular }}
                  >
                    Mutuelle, prévoyance
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="content second__content second__page">
          <div className="benefits">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Quelques exemples de missions d’un office manager :
            </h2>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Assurer les opérations administratives comme la gestion
                d’agenda, le traitement du courrier, l’organisation des
                réunions, des recrutements et des éventuels déplacements, la
                gestion du matériel, des fournitures de bureau, ...
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Gérer la communication interne ou externe (les fournisseurs, les
                clients, les partenaires…)
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                S'occuper du budget de l’entreprise au niveau des différents
                projets et superviser leur mise en œuvre
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Gérer la facturation client, l’enregistrement des factures et
                les paiements fournisseurs, ...
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Gérer les éléments variables de paie et les congés du personnel.
              </li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
};

export default OfficeManagement;
