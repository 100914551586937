// This hook returns the colors object based on the user's prefarred colorScheme (Light / Dark)

const useColors = () => {
  // the Color palette for the whole AP
  return {
    backgroundHex: "#F5F8FB",
    backgroundDark: "white",
    white: "#FFFFFF",
    blackText: "#273039",
    whiteText: "#FFF",
    primary: "#e38e16",
    skyBlue: "#E6EFF8",
    grayText: "#2D445B",
    success: "#009688",
    error: "#F15151",
    textColors: "#273039"
  };
};

export default useColors;
