import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "@redux/account/types";
import jwt_decode from "jwt-decode";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "../index.css";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import Menu from "../../Menu";
import { Badge } from "@mui/material";
import {
  PendingIcon,
  Modal,
  TextInput,
  Uploader,
  NativeSelect,
} from "@components";
import { Fonts, useColors } from "@common";
import { BLOGS } from "@services";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import AddIcon from "@mui/icons-material/Add";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { IoCaretBack } from "react-icons/io5";


const DATA = [
  { id: 0, name: "Aucun" },
  { id: 1, name: "Agriculture" },
  { id: 2, name: "Ingénierie" },
  { id: 3, name: "Recherche & dév..." },
  { id: 4, name: "Finance" },
];

const URL =
  document.location.protocol + "//" + "3eafrica.ma/app" + "/images/blogs/";

export default ({}) => {
  const selectRef = useRef(null);
  const [openSelect, setOpenSelect] = useState(false);
  const Colors = useColors();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const cookiesref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLogged } = useSelector(({ account }) => account);
  const [blogs, setBlogs] = useState([]);
  const displayOnce = useRef(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [pic, setpic] = useState(null);
  const [isLoading, setIsLoaing] = useState(false);
  const [id, setId] = useState(null);
  const [isUpdate, setIsUpdate] = useState(null);
  const [oldPic, setOldPic] = useState(null);
  const [error, setError] = useState({ field: "", message: "" });
  const [secteur, setSecteur] = useState(null);

  const hanleOnClick = () => {
    setOpenSelect((prv) => !prv);
  };

  useEffect(() => {
    // Function to close the select when clicking outside of it
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpenSelect(false); // Call the click handler to close the select
      }
    };

    // Attach the click event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [hanleOnClick]);

  useEffect(() => {
    if (!isLogged) {
      navigate("/back_ofice/3eafrica/auth/sign_in");
    }
    if (!!user) {
      if (!!user?.token) {
        const { exp } = jwt_decode(user?.token);
        const DateExp = !!user ? new Date(exp * 1000) : null;
        if (DateExp <= new Date()) {
          dispatch({ type: LOGOUT });
          navigate("/back_ofice/3eafrica/auth/sign_in");
        }
      }
    }
  }, [user, isLogged]);

  useEffect(() => {
    if (displayOnce.current) {
      fetchBlogs();
      displayOnce.current = false;
    }
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleOnChangeSecteur = (e) => {
    if (e === "Aucun") {
      setSecteur(null);
      setOpenSelect(false);
      return;
    }
    setSecteur(e);
    setOpenSelect(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setId(null);
  };

  const handleOpenUpModal = (id) => {
    setId(id);
    setIsUpdate(true);
    setIsModalOpen(true);
    fetchOn(id);
  };

  const handleOpenDeleteModal = (id) => {
    setId(id);
    setIsDeleteModalOpen(true);
  };

  const fetchOn = (id) => {
    BLOGS.ONE(id)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const { description, title, image, id } = res.data.data;
          setDesc(description);
          setTitle(title);
          setOldPic(image);
          setSecteur(secteur);
        }
      })
      .catch((error) => {
        if (error) {
          setIsModalOpen(false);
          setIsUpdate(null);
          setId(false);
          toast.error(
            error?.response?.data
              ? error?.response?.data?.message
              : "Quelque chose s'est mal passé",
            {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTitle("");
    setSecteur(null);
    setDesc("");
    setOldPic("");
    setId(null);
    setIsUpdate(null);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeDesc = (e) => {
    setDesc(e.target.value);
  };

  const fetchBlogs = () => {
    BLOGS.ALL()
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          setBlogs(result.data.data);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error, "@************@ from fetch data");
        }
      });
  };

  const updateBlog = () => {
    if (!title) {
      setError({ field: "title", message: "Veuillez saisir un titre" });
      return;
    }
    if (!desc) {
      setError({ field: "desc", message: "Veuillez saisir un descreption" });
      return;
    }
    if (!acceptedFiles[0] && !oldPic) {
      setError({ field: "picture", message: "Veuillez upload une image" });
      return;
    }

    setError({ field: null, message: "" });
    setIsLoaing(true);
    let Data = new FormData();
    Data.append("title", !title ? null : title);
    Data.append("description", !desc ? null : desc);
    Data.append("image", !acceptedFiles[0] ? null : acceptedFiles[0]);

    BLOGS.UPDATE(Data, id)
      .then((res) => {
        setIsLoaing(false);
        if (res.status === 200 || res.status === 201) {
          toast.success("Blog est bien Modifié", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetchBlogs();
          setTitle("");
          setSecteur(null);
          setDesc("");
          handleCloseModal();
        }
      })
      .catch((error) => {
        setIsLoaing(false);
        if (error) {
          handleCloseModal();
          toast.error(
            error?.response?.data
              ? error?.response?.data?.message
              : "Quelque chose s'est mal passé",
            {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      });
  };

  const handleAdd = () => {
    setIsLoaing(true);
    if (!title) {
      setError({ field: "title", message: "Veuillez saisir un titre" });
      return;
    }
    if (!desc) {
      setError({ field: "desc", message: "Veuillez saisir un descreption" });
      return;
    }
    if (!acceptedFiles[0]) {
      setError({ field: "picture", message: "Veuillez upload une image" });
      return;
    }

    setError({ field: null, message: "" });

    let Data = new FormData();
    Data.append("title", title);
    Data.append("description", desc);
    Data.append("image", acceptedFiles[0]);

    BLOGS.NEW(Data)
      .then((res) => {
        setIsLoaing(false);
        if (res.status === 200 || res.status === 201) {
          toast.success("Blog est bien enregistrées", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetchBlogs();
          setTitle("");
          setSecteur(null);
          setDesc("");
          handleCloseModal();
        }
      })
      .catch((error) => {
        setIsLoaing(false);
        if (error) {
          toast.error(
            error?.response?.data
              ? error?.response?.data?.message
              : "Quelque chose s'est mal passé",
            {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      });
  };

  const handleDelete = () => {
    setIsLoaing(true);
    BLOGS.DELETE(id)
      .then((res) => {
        setIsLoaing(false);
        if (res.status === 200 || res.status === 201) {
          toast.success("Blog supprimé avec succès", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleCloseDeleteModal();
          setId(null);
          fetchBlogs();
        }
      })
      .catch((error) => {
        setIsLoaing(false);
        if (error) {
          toast.error(
            error?.response?.data
              ? error?.response?.data?.message
              : "Quelque chose s'est mal passé",
            {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      });
  };

  const ComingSoonUsersTable = ({ data }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Titre</TableCell>
              <TableCell>Descreption</TableCell>
              <TableCell>Image</TableCell>
              <TableCell> </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user, index) => (
              <TableRow key={user.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{user.title}</TableCell>
                <TableCell>{user.description}</TableCell>
                <TableCell>
                  {" "}
                  <img
                    src={`${URL + user.image}`}
                    style={{ width: "50px", height: "50px" }}
                  />{" "}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenUpModal(user.id)}>
                    <CreateIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDeleteModal(user.id)}>
                    <DeleteIcon />{" "}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <div className="container_backend">
        <Menu useBack />
        <div
          className="content_backofice"
          style={{ width: "92%", margin: "100px auto" }}
        >
          <br />
          <Badge
            badgeContent={<PendingIcon />}
            // color="info"
            // sx={{ backgroundColor: "#e38e16" }}
          >
            <div style={{ padding: 5, fontFamily: Fonts().primaryBold }}>
            <IconButton
                onClick={() => navigate(-1)}
                style={{ borderRadius: 16, marginLeft: 10 }}
              >
                <IoCaretBack />
                <p style={{ fontSize: 12, marginLeft: 3 }}> back</p>
              </IconButton>
              Gestion des blogs
              <IconButton
                onClick={handleOpenModal}
                style={{ borderRadius: 16, marginLeft: 10 }}
              >
                <AddIcon />
                <p style={{ fontSize: 12 }}> Blogs</p>
              </IconButton>
            </div>
          </Badge>
          <ComingSoonUsersTable data={blogs} />
        </div>
      </div>
      <Modal isOpen={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <div>
          <p
            style={{
              fontFamily: Fonts().primarySemiBold,
              color: Colors.secondary,
              fontSize: 18,
            }}
          >
            Êtes-vous sûr de vouloir supprimer ce blog ?
          </p>
        </div>
        <br />
        <div className="this_is_the_actions" style={{ textAlign: "center" }}>
          <div
            onClick={handleDelete}
            className="skewed-link-container"
            style={{
              borderBottom: "1px solid",
              border: `1px solid ${Colors.secondary}`,
              width: "200px",
              textAlign: "center",
              padding: "14px 0px",
              cursor: "pointer",
              background: "#de003d",
              position: "relative",
            }}
          >
            <span
              className="skewed-link-text"
              style={{
                color: "#FFF",
                fontFamily: Fonts().primarySemiBold,
                fontSize: 14,
              }}
            >
              Supprimer
            </span>
            {isLoading && (
              <Box
                sx={{ display: "flex" }}
                style={{
                  fontSize: 100,
                  color: Colors.primary,
                  position: "absolute",
                  right: 20,
                  top: "14px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        </div>
      </Modal>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} useFullWidth>
        <div className="blog_form">
          <div className="header_modal_new_blog">
            <h4 style={{ fontFamily: Fonts().primarySemiBold, fontSize: 14 }}>
              Ajout d'un nouveau blog
            </h4>
          </div>
          <div className="modal_new_blog_body">
            <div
              className="modal_next_content"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <TextInput
                style={{ width: "100%" }}
                value={title}
                placeholder="Title"
                required
                onChange={handleChangeTitle}
                error={
                  !!error && error.field === "title" ? error.message : null
                }
              />
            </div>

            <br />
            <TextInput
              value={desc}
              onChange={handleChangeDesc}
              placeholder="Dsecreption.."
              required
              multiline
              rows={6}
              className="message_neofast"
              width="100%"
              error={!!error && error.field === "desc" ? error.message : null}
            />
            <br />
            <Uploader
              acceptedFiles={acceptedFiles}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              error={error.field === "picture" ? error : null}
              isUpdate={isUpdate}
              picture={URL + oldPic}
            />

            <div
              className="this_is_the_actions"
              style={{ textAlign: "center" }}
            >
              <div
                onClick={isUpdate ? updateBlog : handleAdd}
                className="skewed-link-container"
                style={{
                  // borderBottom: "1px solid",
                  // border: `1px solid ${Colors.secondary}`,
                  // width: "200px",
                  textAlign: "center",
                  padding: "14px 0px",
                  cursor: "pointer",
                  background: Colors.primary,
                  position: "relative",
                }}
              >
                <span
                  className="skewed-link-text"
                  style={{
                    color: "#FFF",
                    fontFamily: Fonts().primarySemiBold,
                    fontSize: 14,
                  }}
                >
                  {isUpdate ? "Mise à jour" : "Ajout"}
                </span>
                {isLoading && (
                  <Box
                    sx={{ display: "flex" }}
                    style={{
                      fontSize: 100,
                      color: Colors.primary,
                      position: "absolute",
                      right: "8rem",
                      top: "20px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
