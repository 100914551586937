import React, { useRef, useState, useEffect } from "react";
import { useColors, Fonts } from "@common";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import Politiques from "@images/backgrounds/Politique.png";
import { PendingIcon } from "@components";
import "./index.css";

const { innerWidth, innerHeight } = window;
export const Items = ({ desc, strong, Ref, useRed }) => {
  const Colors = useColors();
  return (
    <div className="term_section__item">
      <PendingIcon />
      <p
        className="term_section__item_desc"
        style={{
          fontSize: "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          className="term_section_Strongs"
          style={{
            fontSize: innerWidth > 414 ? "16px" : "10px",
            fontFamily: Fonts().primarySemiBold,
            color: useRed ? Colors.error : Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {!!strong && !!desc && " : "}
        {desc}
      </p>
    </div>
  );
};

export default ({}) => {
  const Colors = useColors();
  const section2Ref = useRef(null);
  const Home2Ref = useRef(null);
  const ContactRef = useRef(null);
  const AboutRef = useRef(null);
  const [active, setActive] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const OurServices = () => {
    setActive("services");
    navigate("/nos_services");
  };

  const hanleOnclickHome = () => {
    setActive("Accueil");
    // Home2Ref.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };

  const handleOnClickContact = () => {
    setActive("Contacts");
    navigate(`/${"contacts"}`);
  };
  const handleOnClickAbout = () => {
    setActive("Abouts");
    navigate(`/${"abouts"}`);
  };

  return (
    <div className="home__home" style={{ background: Colors.backgroundHex }}>
      <NavBar
        handleOurService={OurServices}
        active={active}
        hanleOnclickHome={hanleOnclickHome}
        handleOnClickContact={handleOnClickContact}
        handleOnClickAbout={handleOnClickAbout}
      />

      <div
        className="term_of_use "
        style={{
          backgroundImage: `url(${Politiques})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <div className="term_of_us_global second_use">
          <h1
            className="big_title second_use"
            style={{ fontFamily: Fonts().primaryBold, color: Colors.error }}
          >
            Politique relative aux données à caractère personnel
          </h1>
          <div className="term_of_us_content_1">
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Pour satisfaire à ses besoins, 3E AFRICA met en œuvre et exploite
              des traitements de données à caractère personnel. La politique a
              pour objet de satisfaire à l’obligation d’information de 3E AFRICA
              et de formaliser les droits et les obligations de ses clients et
              contacts concernant le traitement de leurs données à caractère
              personnel.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Définitions
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Pour une bonne compréhension de la présente politique il est
              précisé que :<br />
              <div className="term_us__items">
                <Items
                  useRed
                  strong={`"client "`}
                  desc="désigne toute personne physique ou morale cliente du réseau."
                />
                <Items
                  useRed
                  strong={`" contact "`}
                  desc="désigne toute personne physique ou morale en relation avec le réseau mais qui n’est pas client (prospects, relations, partenaires, …)."
                />
                <Items
                  useRed
                  strong={`" responsable du traitement "`}
                  desc="désigne la personne physique ou morale qui détermine les finalités et les moyens d’un traitement de données à caractère personnel. Au titre de la présente politique, le responsable du traitement est le réseau."
                />
                <Items
                  useRed
                  strong={`“sous-traitant”`}
                  desc="désigne toute personne physique ou morale qui traite des données à caractère personnel pour le compte du responsable du traitement. Il s’agit donc en pratique des prestataires avec lesquels le réseau travaille et qui interviennent sur les données à caractère personnel qu’il traite."
                />
                <Items
                  useRed
                  strong={`"personnes concernées"`}
                  desc="désigne les personnes qui peuvent être identifiées, directement ou indirectement. Elles sont, au titre des présentes, qualifiées de “client” ou de “contact”."
                />
              </div>
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Objet
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Pour satisfaire à ses besoins, le réseau met en œuvre et exploite
              des traitements de données à caractère personnel relatifs à ses
              clients et contacts.
              <br /> La présente politique a pour objet de satisfaire à
              l’obligation d’information du réseau et ainsi de formaliser les
              droits et les obligations de ses clients et contacts au regard du
              traitement de leurs données à caractère personnel.
              <br />
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Liens
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Les liens hypertextes mis en place en direction d’autres sites
              sont fournis pour la convenance personnelle des internautes, les
              pages restant de la seule responsabilité de leurs titulaires.
              <br /> La reproduction d’une page du Site dans un cadre
              n’appartenant pas à 3E AFRICA est interdite.
              <br /> 3E AFRICA se réserve le droit de supprimer les liens
              proposés par son site vers un site tiers.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Portée
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              La présente politique de protection des données à caractère
              personnel a vocation à s’appliquer dans le cadre de la mise en
              place du traitement des données à caractère personnel des clients
              et contacts de 3E AFRICA.
              <br /> La présente politique ne porte que sur les traitements dont
              le réseau est responsable ainsi que sur les données qualifiées de
              “structurées”.
              <br /> Le traitement de données à caractère personnel peut être
              géré directement par 3E AFRICA ou par le biais d’un sous-traitant
              spécifiquement désigné par lui.
              <br />
              Cette politique est indépendante de tout autre document pouvant
              s’appliquer au sein de la relation contractuelle entre le réseau
              et ses clients et contacts, notamment nos conditions générales
              d’intervention ou notre politique de cookies.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Principes généraux et engagement
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Aucun traitement n’est mis en œuvre par 3E AFRICA concernant des
              données de clients et contacts s’il ne porte pas sur des données à
              caractère personnel collectées par ou pour nos services ou
              traitées en relation avec nos services et s’il ne répond pas aux
              principes généraux de protection des données.
              <br /> Tout nouveau traitement, modification ou suppression d’un
              traitement existant sera porté à la connaissance des clients et
              contacts par le biais d’une modification de la présente politique.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_big_2"
              style={{ fontFamily: Fonts().primaryBold, color: Colors.error }}
            >
              Types de données collectées
            </h1>
            <h2
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Données non techniques selon les cas d’usage :
            </h2>
            <div className="term_us__items">
              <Items desc="Données de connexion (logs notamment)" />
              <Items desc="l’exploitation des informations contenues dans le Site." />
              <Items desc="Données relatives au consentement essentiellement pour les abonnements en ligne" />
            </div>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Données sensibles
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le réseau ne traite pas de données sensibles.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Origines des données
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le réseau collecte les données de ses clients et de ses contacts à
              partir de :
              <br />
            </p>
            <div className="term_us__items">
              <Items desc="Données fournies par le client dans le cadre d’un dossier confié au réseau." />
              <Items desc="Cartes de visite." />
              <Items desc="Fiches ou formulaires électroniques remplis par le client." />
              <Items desc="Inscription ou abonnement à nos services en ligne." />
              <Items desc="Inscription à des évènements organisés par le réseau." />
              <Items desc="listes communiquées par les organisateurs d’évènements." />
              <Items desc="Échanges via courrier électronique ou via les réseaux sociaux." />
            </div>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              De manière exceptionnelle nous pouvons louer des bases de données.
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Finalités du traitement
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Selon les cas, 3E AFRICA traite vos données pour les finalités
              suivantes :
              <br />
            </p>
            <div className="term_us__items">
              <Items desc="Traitement des dossiers confiés au réseau." />
              <Items desc="Gestion de la relation client." />
              <Items desc="Gestion des événements organisés par le réseau." />
              <Items desc="Envoi de nos newsletters ou fils d’informations." />
              <Items desc="Réponses aux questions qui nous sont posées (par téléphone ou en ligne)." />
              <Items desc="Réponses à des appels d’offres publics ou privés." />
              <Items desc="Envoi de vœux et autres félicitations de la part du réseau." />
              <Items desc="Amélioration de nos services." />
              <Items desc="Réponses à nos obligations administratives." />
              <Items desc="Réalisation de statistiques." />
            </div>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Base légale
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Les finalités de traitement présentées ci-dessus reposent sur les
              conditions de licéité suivantes :
              <br />
            </p>
            <div className="term_us__items">
              <Items
                useRed
                strong={`Clients`}
                desc="Exécution précontractuelle ou contractuelle."
              />
              <Items
                useRed
                strong={`Contacts`}
                desc=" Intérêt légitime et lorsque la loi le requiert, consentement."
              />
            </div>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Destinataires des données
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le réseau s’assure que les données ne soient accessibles qu’à des
              destinataires internes ou externes autorisés.
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Destinataires internes
            </h1>
            <div className="term_us__items">
              <Items strong="Juristes" />
              <Items strong="Consultants" />
              <Items strong="Personnel administratif" />
              <Items strong="Stagiaires" />
              <Items strong="Dirigeants" />
            </div>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Destinataires externes
            </h1>
            <div className="term_us__items">
              <Items strong="Prestataires ou services support (e.g., service RH, prestataire informatique, etc.)" />
              <Items strong="Administration" />
            </div>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Durée de conservation
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              La durée de conservation des données est définie par le réseau au
              regard des contraintes légales et contractuelles qui pèsent sur
              lui. Elle est fixée dans le cadre de sa politique de durée de
              conservation.
              <br /> Passés les délais fixés dans ladite politique, les données
              sont soit supprimées, soit conservées après avoir été anonymisées,
              notamment pour des raisons d’usage statistique.
              <br /> Il est rappelé aux clients et contacts que la suppression
              ou l’anonymisation sont des opérations irréversibles et que le
              réseau n’est plus, par la suite, en mesure de les restaurer.
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Droit d’accès
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Les clients et contacts disposent traditionnellement d’un droit de
              demander au réseau la confirmation que des données les concernant
              sont ou non traitées.
              <br /> Les clients et contacts disposent également d’un droit
              d’accès conditionné au respect des règles suivantes :
              <br />
            </p>
            <div className="term_us__items">
              <Items strong="la demande émane de la personne elle-même et est accompagnée d’une copie d’un titre d’identité, à jour." />
              <Items strong="la demande doit être formulée par écrit à l’adresse suivante : contact@3eafrica.ma" />
            </div>

            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Les clients et contacts ont le droit de demander une copie de
              leurs données à caractère personnel faisant l’objet du traitement
              auprès du réseau.
              <br /> Toutefois, en cas de demande de copie supplémentaire, le
              réseau pourra exiger la prise en charge financière de ce coût par
              les clients et contacts.
              <br /> Si les clients et contacts présentent leur demande de copie
              des données par voie électronique, les informations demandées lui
              seront fournies sous une forme électronique d’usage courant, sauf
              demande contraire. Les clients et contacts sont informés que ce
              droit d’accès ne peut porter sur des informations ou données
              confidentielles ou encore pour lesquelles la loi n’autorise pas la
              communication.
              <br /> Ce droit ne peut en aucun cas permettre d’accéder à des
              documents et des pièces confiées au réseau et qui sont soumises au
              secret professionnel.
              <br /> Le droit d’accès ne doit pas être exercé de manière abusive
              c’est-à-dire réalisé de manière régulière dans le seul but de
              déstabiliser le réseau.
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Mise à jour – Actualisation et rectification
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              L’exercice de ce droit s’exerce auprès de votre interlocuteur
              habituel, à défaut auprès de la direction du réseau.
              <br /> Afin de permettre une mise à jour régulière des données à
              caractère personnel collectées par le réseau, celui-ci pourra
              solliciter les clients et contacts qui auront la possibilité de
              satisfaire à ses demandes.
              <br /> Le réseau ne pourra se voir reprocher une absence de mise à
              jour si le client ou le contact n’actualise pas ses données.
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Droit à l’effacement
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le droit à l’effacement des clients et contacts ne sera pas
              applicable dans les cas où le traitement est mis en œuvre pour
              répondre à une obligation légale.
              <br /> En dehors de cette situation, les clients et contacts
              pourront demander l’effacement de leurs données dans les cas
              limitatifs suivants :
              <br />
            </p>

            <div className="term_us__items">
              <Items strong="lorsque les données à caractère personnel ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées d’une autre manière." />
              <Items strong="lorsque la personne concernée retire le consentement sur lequel est fondé le traitement et qu’il n’existe pas d’autre fondement juridique au traitement." />
              <Items strong="lorsque la personne concernée s’oppose à un traitement nécessaire aux fins des intérêts légitimes poursuivis par le réseau et qu’il n’existe pas de motif légitime impérieux pour le traitement." />
              <Items strong="lorsque la personne concernée s’oppose à un traitement de ses données à caractère personnel à des fins de prospection, y compris au profilage." />
              <Items strong="lorsque les données à caractère personnel ont fait l’objet d’un traitement illicite." />
            </div>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Droit à la limitation
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Les clients et contacts sont informés que ce droit n’a pas
              vocation à s’appliquer dans la mesure où le traitement opéré par
              le réseau est licite et que toutes les données à caractère
              personnel collectées sont nécessaires à l’exécution du contrat
              commercial.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Décision individuelle automatisée
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le réseau fait droit à la portabilité des données dans le cas
              particulier des données communiquées par les clients ou les
              contacts eux-mêmes, sur des services en ligne proposés par le
              réseau et pour les finalités reposant sur le seul consentement des
              personnes.
              <br /> Dans ce cas les données seront communiquées dans un format
              structuré, couramment utilisé et lisible par une machine.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Décision individuelle automatisée
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le réseau ne procède pas à des décisions individuelles
              automatisées.
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Droit post mortem
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Les clients et contacts sont informés qu’ils disposent du droit de
              formuler des directives concernant la conservation, l’effacement
              et la communication de leurs données post-mortem. La communication
              de directives spécifiques post-mortem et l’exercice de leurs
              droits s’effectuent par écrit à l’adresse électronique suivante :
              <br />
              contact@3eafrica.ma
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Justification
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Pour l’ensemble des droits mentionnés dont bénéficie le client ou
              le contact et conformément à la législation sur la protection des
              données à caractère personnel, vous êtes informés qu’il s’agit de
              droits de nature individuelle qui ne peuvent être exercés que par
              la personne concernée relativement à ses propres informations.
              <br />
              Pour satisfaire à cette obligation, nous vérifierons l’identité de
              la personne concernée.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Caractère facultatif ou obligatoire des réponses
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Les clients et contacts sont informés sur chaque formulaire de
              collecte des données à caractère personnel du caractère
              obligatoire ou facultatif des réponses par la présence d’un
              astérisque.
              <br /> Dans le cas où des réponses sont obligatoires, le réseau
              explique aux clients et contacts les conséquences d’une absence de
              réponse.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Droit d’usage
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le réseau informe ses clients et contacts qu’il pourra faire
              intervenir tout sous-traitant de son choix dans le cadre du
              traitement de leurs données à caractère personnel.
              <br /> Dans ce cas, le réseau s’assure du respect par le
              sous-traitant de ses obligations en vertu de la protection des
              données personnelles.
              <br /> Le réseau s’engage à signer avec tous ses sous-traitants un
              contrat écrit et impose aux sous-traitants les mêmes obligations
              en matière de protection des données que lui.
              <br /> De plus, le réseau se réserve le droit de procéder à un
              audit auprès de ses sous-traitants afin de s’assurer du respect
              des dispositions de protection des données personnelles.
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Sous traitance
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le réseau informe ses clients et contacts qu’il pourra faire
              intervenir tout sous-traitant de son choix dans le cadre du
              traitement de leurs données à caractère personnel. Dans ce cas, le
              réseau s’assure du respect par le sous-traitant de ses obligations
              en vertu de la protection des données personnelles.
              <br /> Le réseau s’engage à signer avec tous ses sous-traitants un
              contrat écrit et impose aux sous-traitants les mêmes obligations
              en matière de protection des données que lui. De plus, le réseau
              se réserve le droit de procéder à un audit auprès de ses
              sous-traitants afin de s’assurer du respect des dispositions de
              protection des données personnelles.
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Sécurité
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Il appartient au réseau de définir et de mettre en œuvre les
              mesures techniques de sécurité, physique ou logique, qu’il estime
              appropriées pour lutter contre la destruction, la perte,
              l’altération ou la divulgation non autorisée des données de
              manière accidentelle ou illicite.
              <br /> Parmi ces mesures figurent principalement :
              <br />
            </p>
            <div className="term_us__items">
              <Items strong="La sécurité de l’accès à nos postes informatiques et smartphones." />
              <Items strong="Identifiants de connexion et mots de passe pour toutes nos applications métiers." />
              <Items strong="La gestion des habilitations pour l’accès aux données." />
              <Items strong="Mot de passe complexe pour notre réseau Wi-Fi modifié à intervalle régulier." />
            </div>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Pour ce faire, le réseau peut se faire assister de tout tiers de
              son choix pour procéder, aux fréquences qu’il estimera
              nécessaires, à des audits de vulnérabilité ou des tests
              d’intrusion.
              <br /> En tout état de cause, le réseau s’engage, en cas de
              changement des moyens visant à assurer la sécurité et la
              confidentialité des données à caractère personnel, à les remplacer
              par des moyens d’une performance supérieure.
              <br /> Aucune évolution ne pourra conduire à une régression du
              niveau de sécurité. <br />
              En cas de sous-traitance d’une partie ou de la totalité d’un
              traitement de données à caractère personnel, le réseau s’engage à
              imposer contractuellement à ses sous-traitants des garanties de
              sécurité par le biais de mesures techniques de protection de ces
              données et les moyens humains appropriés.
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Violation des données
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              En cas de violation de données à caractère personnel, le réseau
              s’engage à le notifier à la Cnil dans les conditions prescrites
              par les dispositions de protection des données personnelles.
              <br /> Si ladite violation fait porter un risque élevé pour les
              clients et contacts et que les données n’ont pas été protégées, le
              réseau :
              <br />
            </p>
            <div className="term_us__items">
              <Items strong="En avisera les clients et contacts concernés." />
              <Items strong="Communiquera aux clients et contacts concernés les informations et recommandations nécessaires." />
            </div>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Référent données personnelles
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              3E AFRICA a désigné un référent “données personnelles”. <br />
              Les coordonnées de notre référent données personnelles sont les
              suivantes :<br /> Monsieur Gilles RAUCH - contact@3eafrica.ma
              <br />
            </p>
          </div>

          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Modification
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              La présente politique peut être modifiée ou aménagée à tout moment
              en cas d’évolution légale, jurisprudentielle, des décisions et
              recommandations de la BMDA ou des usages.
              <br /> Toute nouvelle version de la présente politique sera portée
              à la connaissance des clients et contacts par tout moyen choisi
              par le réseau en ce compris la voie électronique.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Pour plus d’informations
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Pour toutes informations complémentaires, vous pouvez contacter
              notre référent à l’adresse électronique suivante : 
              <br />
              contact@3eafrica.ma.
              <br /> Pour toute autre information plus générale sur la
              protection des données personnelles, vous pouvez consulter le site
              de la BMDA.
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer isIn />
    </div>
  );
};
