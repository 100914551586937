import React from "react";
import Back6 from "@images/backgrounds2/Back4.png";
import ResBack6 from "@images/responsive/Back4.png";
import small8 from "@images/smalls/small8.png";
import arrow from "@images/icons/arrow_bottom.png";
import { useColors, Fonts } from "@common";
import { IconButton } from "@mui/material";
import Infos from "@images/section6/infos.png";
import Internet from "@images/section6/internet.png";
import Safety from "@images/section6/safety.png";
import Users from "@images/section6/users.png";

import "./styles/Section5.css";

const { innerWidth, innerHeight } = window;
export const Items = ({ text, img, onClick }) => {
  const Colors = useColors();
  return (
    <div className="section6__items" onClick={onClick}>
      <div className="section6__item">
        <img src={img} className="section6__images" />
      </div>
      <h2
        className="section6__desc__item"
        style={{
          fontSize: innerWidth > 600 ? "14px" : "11px",
          fontFamily: Fonts().primarySemiBold,
          color: Colors.blackText,
        }}
      >
        {text}
      </h2>
    </div>
  );
};

export default ({ handleClick }) => {
  const Colors = useColors();
  return (
    <div
      style={{
        backgroundImage: `url(${Back6})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "450px",
        position: "relative",
      }}
      className="section6___main"
    >
      <div className="section6__content">
        <h1
          className="section6__title"
          style={{
            fontSize: innerWidth < 441 ? "22px" : "30px",
            color: Colors.blackText,
            fontFamily: Fonts().primaryBold,
          }}
        >
          3E Africa, c’est aussi
        </h1>

        <div className="section6__content_items">
          <Items
            text="Le nearshoring"
            img={Internet}
            onClick={() => handleClick("nearshoring")}
          />
          <Items
            text="Le portage salarial"
            img={Users}
            onClick={() => handleClick("portage_salarial")}
          />
          <Items
            text="La formation"
            img={Infos}
            onClick={() => handleClick("formation")}
          />
          <Items
            text="La conformité"
            img={Safety}
            onClick={() => handleClick("conformité")}
          />
        </div>
        <div className="section6__border" />
     
      </div>
    </div>
  );
};
