import React, { useEffect } from "react";
import Nosservices from "@images/backgrounds2/Nos_services.png";
import ResNosservices from "@images/responsive/resServices.png";
import { useColors, Fonts } from "@common";
import { PendingIcon, Button } from "@components";
import Agrobource from "@images/logos/Agrobource.png";
import NeoFast from "@images/logos/NeoFast.png";
import Token from "@images/logos/Token.png";
import Sections4 from "../../components/Sections4";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";
import { useNavigate } from "react-router-dom";
import small2 from "@images/smalls/small2.png";
import small3 from "@images/smalls/small3.png";
import resSmall3 from "@images/smalls/resSmall3.png";
import small4 from "@images/smalls/small4.png";
import small5 from "@images/smalls/small5.png";
import small6 from "@images/smalls/small6.png";
import small7 from "@images/smalls/small7.png";
import resSmall7 from "@images/smalls/resSmall7.png";
import small8 from "@images/smalls/small8.png";
import small9 from "@images/smalls/small9.png";

const { innerWidth } = window;
export const Items = ({ desc, strong, items , removeBreakLigne}) => {
  const Colors = useColors();
  return (
    <div className="section3__item second__">
      <PendingIcon  top = {8}/>
      <p
        className="section3__item_desc nos_service_section4_sentent_seven_items our____services"
        style={{
          fontSize: "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          style={{
            fontSize: "12px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {desc}
        <br />
        {!!items &&
          items.map((ele, index) => (
            <React.Fragment key={index}>
              <span
                style={{
                  marginTop: 12,
                  marginLeft: 12,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ margin: "2px" }}>-</span> <span>{ele}</span>
              </span>
              {!removeBreakLigne && <br />}
            </React.Fragment>
          ))}
      </p>
    </div>
  );
};

export default ({
  Ref,
  RefOne,
  RefTwo,
  RefThree,
  RefFour,
  RefFive,
  RefSex,
  RefSeven,
  RefEight,
}) => {
  const navigate = useNavigate();
  const Colors = useColors();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const SeeMoreEvent = (name) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (name === "marketing") {
      navigate(`/marketing_digital`);
    } else {
      navigate(`/nos_services/coming_soon`);
    }
  };

  return (
    <div
      ref={Ref}
      style={{
        backgroundImage: `url(${
          innerWidth > 600 ? Nosservices : ResNosservices
        })`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        // height: "4650px",
        position: "relative",
      }}
      className="first_section__main_our_services"
    >
      <div className="first_section__main ou_Services">
        <div className="first_section__title_nos_service" ref={RefOne}>
          <h1
            className="firts_section__title__text ou_service_text"
            style={{
              fontSize: "30px",
              color: Colors.error,
              fontFamily: Fonts().primarySemiBold,
            }}
          >
            Nos offres
          </h1>
        </div>

        <div className="first_section__content__one_nos_services">
          <div className="nos_service_section1_small">
            <div className="nos_services_small_content">
              <div
                className="first_section_title_image"
                style={{
                  backgroundImage: `url(${innerWidth < 620 && resSmall3})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  backgroundSize: "contain",
                  // height: "3210px",
                  position: "relative",
                }}
              >
                <h3
                  className="first_section__content__one__title"
                  style={{
                    fontSize: "28px",
                    color: Colors.blackText,
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  Pilotage de la performance
                </h3>
              </div>

              <p
                className="first_section__content__one__desc firts_nos_service_desc"
                style={{
                  fontSize: "13px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "50%",
                  marginTop: 8,
                }}
              >
                Le pilotage de la performance est l’ensemble des outils et des
                processus permettant aux dirigeants d’améliorer la performance
                de leur entreprise. La mise en place d’un outil de pilotage de
                la performance permet de :
              </p>
              <div className="first_section__items_no_services">
                <Items
                  desc="Transformer vos données en informations"
                  fontSize="13px"
                />
                <Items
                  desc="Gagner du temps pour les activités à forte valeur ajoutée"
                  fontSize="13px"
                />
                <Items
                  desc="Faire collaborer efficacement tous les départements de l’entreprise"
                  fontSize="13px"
                />
                <Items desc="Prendre des décisions rapides" fontSize="13px" />
                <div className="first_section__added_para">
                  <p
                    className="first__section__added"
                    style={{
                      fontFamily: Fonts().primaryRegular,
                      marginTop: "16px",
                      marginBottom: 0,
                      fontSize: "13px",
                    }}
                  >
                    Avec nous vous améliorez{" "}
                    <span
                      ref={RefFour}
                      style={{
                        color: Colors.error,
                      }}
                    >
                      la création de valeur et la compétitivité de votre
                      entreprise.
                    </span>
                  </p>
                </div>
                <Button
                  Click={() => SeeMoreEvent("pilotage")}
                  Label="En savoir plus"
                  useIcon
                  notImage
                  IconName={ArrowForwardIosIcon}
                  style={{
                    width: innerWidth > 700 ? "240px" : "150px",
                    background: "rgb(246 215 218)",
                    color: "rgb(241 81 81)",
                    fontSize: innerWidth > 1100 ? "18px" : "10px",
                    textTransform: "capitalize",
                    fontWeight: "400",
                    borderRadius: "14px",
                    padding: innerWidth > 700 ? "14px" : "8px",
                    boxShadow: "rgb(215, 210, 210) 1px 1px 1px",
                    marginTop: "16px",
                    marginRight: "40px",
                  }}
                />
              </div>
            </div>
            {innerWidth > 700 && (
              <div className="nos_service_image_small1">
                <img className="img_small1_nos_service" src={small3} />
              </div>
            )}
          </div>
        </div>

        <div className="second_section__content__two nos_service_content_two">
          <div className="second_nos_service_section1_small">
            {/* <div className="second_nos_service_image_small2">
              <img className="img_small1_nos_service" src={small4} />
            </div> */}
            <div className="second_nos_services_small_content2">
              <div
                className="second_section_title_image responsive___once"
                style={{
                  backgroundImage: `url(${innerWidth < 620 && small4})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  backgroundSize: "contain",
                  // height: "3210px",
                  position: "relative",
                }}
              >
                <h3
                  className="second_section__content__two__title second_nos_service_content_two_title"
                  style={{
                    fontSize: "28px",
                    color: Colors.blackText,
                    fontFamily: Fonts().primaryBold,
                  }}
                >
                  Externalisation
                </h3>
              </div>

              <p
                className="second_section__content__two__desc second_nos_service_content_two_desc"
                style={{
                  fontSize: "14px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  marginTop: 8,
                }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Confiez-nous vos tâches périphériques les plus chronophages et
                  gagnez en flexibilité{" "}
                </strong>{" "}
                et en productivité. Ces activités peuvent varier selon les
                entreprises, elles sont liées à la gestion financière
                (comptabilité), l’administratif (secrétariat) ou les services
                généraux (expéditions, maintenance, …). Quels sont{" "}
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  les principaux avantages de l’externalisation ?
                </strong>
              </p>
              <div className="second_section__items">
                <Items desc="Gain de temps" fontSize="13px" />
                <Items
                  desc="Augmentation de la compétitivité et de la productivité"
                  fontSize="13px"
                />
                <Items
                  desc="Réduction des frais de personnel"
                  fontSize="13px"
                />
                <Items
                  desc="Recentrage sur le cœur de métier"
                  fontSize="13px"
                />
                <Items desc="Souplesse opérationnelle" fontSize="13px" />

                <div className="second_section__added_para">
                  <p
                    className="second__section__added"
                    style={{
                      fontFamily: Fonts().primaryRegular,
                      marginTop: "18px",
                      marginBottom: 10,
                      fontSize: "13px",
                    }}
                  >
                    Concentrez vos ressources sur les missions de l’entreprise à
                    plus forte valeur ajoutée...
                    <br />
                    <span
                      ref={RefTwo}
                      style={{
                        color: Colors.error,
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                     Sur votre cœur de métier !
                    </span>
                  </p>
                </div>
                <Button
                  Click={() => SeeMoreEvent("externalisation")}
                  Label="En savoir plus"
                  useIcon
                  notImage
                  IconName={ArrowForwardIosIcon}
                  style={{
                    width: innerWidth > 700 ? "240px" : "150px",
                    background: "rgb(246 215 218)",
                    color: "rgb(241 81 81)",
                    fontSize: innerWidth > 1100 ? "18px" : "10px",
                    textTransform: "capitalize",
                    fontWeight: "400",
                    borderRadius: "14px",
                    padding: innerWidth > 700 ? "14px" : "8px",
                    boxShadow: "rgb(215, 210, 210) 1px 1px 1px",
                    marginTop: "16px",
                    marginRight: "40px",
                  }}
                />
              </div>
            </div>
            {innerWidth > 700 && (
              <div className="second_nos_service_image_small1">
                <img className="img_small1_nos_service" src={small4} />
              </div>
            )}
          </div>
        </div>

        <div className="third_section__content__one_nos_services third_nos_service_content_one">
          <div className="third_nos_service_section1_small">
            <div className="third_nos_services_small_content">
              <div
                className="second_section_title_image"
                style={{
                  backgroundImage: `url(${innerWidth < 620 && small5})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  backgroundSize: "contain",
                  // height: "3210px",
                  position: "relative",
                }}
              >
                <h3
                  className="third_section__content__one__title"
                  style={{
                    fontSize: "28px",
                    color: Colors.blackText,
                    fontFamily: Fonts().primaryBold,
                  }}
                >
                 Marketing et Digital Marketing
                </h3>
              </div>
              <p
                className="third_section__content__one__desc third_nos_service_content_one_des"
                style={{
                  fontSize: "14px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "50%",
                  marginTop: 8,
                }}
              >
                Le marketing se défini comme l'analyse des besoins clients et
                l'ensemble des moyens d'actions utilisés par les entreprises
                pour influencer le comportement des clients.
                <br /> Il implique l'ajustement constant des produits et des
                stratégies commerciales pour répondre aux besoins et aux
                comportements identifiés.
                <br />
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Notre équipe vous accompagne dans la mise en place des
                  campagnes marketing.
                </strong>{" "}
                <br /> <br />
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Le  marketing 
                </strong>{" "}
                digital  utilise  l’ensemble des canaux{" "}
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  {" "}
                   numériques 
                </strong>{" "}
                pour vendre un produit ou un{" "}
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  {" "}
                  service{" "}
                </strong>
                  auprès des consommateurs. Notre département web agency est
                spécialisé dans{" "}
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  {" "}
                  la  création de site internet
                </strong>
                , le développement web, le développement d'application mobile ou
                encore le référencement naturel (SEO) ou publicité (SEA). <br />
              </p>
              <span
                style={{
                  fontSize: "16px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "50%",
                  marginTop: 16,
                }}
              >
                {" "}
                Du conseil à la réalisation, nos experts,{" "}
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  {" "}
                  Vous conseillent pour concevoir un site performant et visible.
                </strong>
              </span>
              {/* <div className="third_section__items">
                <Items desc="Chef de projet" fontSize="13px" />
                <Items
                  desc="Développeurs (front-end et back-end)"
                  fontSize="13px"
                />
                <Items desc="Designer UX/UI" fontSize="13px" />
              </div> */}
              <p
                className="third_section__content__one__desc"
                style={{
                  fontSize: "12px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "50%",
                  marginTop: 0,
                }}
              >
                <br />
                Recourir à nos services,{" "}
                <strong
                  style={{ fontFamily: Fonts().primaryBold }}
                  ref={RefThree}
                >
                  {" "}
                  c'est avant tout l'assurance d'obtenir un résultat conforme à
                  vos exigences.
                </strong>
              </p>
              <Button
                Click={() => SeeMoreEvent("marketing")}
                Label="En savoir plus"
                useIcon
                notImage
                IconName={ArrowForwardIosIcon}
                style={{
                  width: innerWidth > 700 ? "240px" : "150px",
                  background: "rgb(246 215 218)",
                  color: "rgb(241 81 81)",
                  fontSize: innerWidth > 1100 ? "18px" : "10px",
                  textTransform: "capitalize",
                  fontWeight: "400",
                  borderRadius: "14px",
                  padding: innerWidth > 700 ? "14px" : "8px",
                  boxShadow: "rgb(215, 210, 210) 1px 1px 1px",
                  marginTop: "16px",
                  marginRight: "40px",
                }}
              />
            </div>
          </div>
          {innerWidth > 700 && (
            <div className="fourth_nos_service_image_small1">
              <img className="img_small1_nos_service" src={small5} />
            </div>
          )}
        </div>

        <div className="fourth_section__content__four_nos_services fourth_nos_service_content_four">
          <div className="fourth_nos_service_section1_small">
            {/* <div className="fourth_nos_service_image_small2">
              <img className="img_small1_nos_service" src={small7} />
            </div> */}
            <div className="fourth_nos_services_small_content2">
              <div
                className="second_section_title_image"
                style={{
                  backgroundImage: `url(${innerWidth < 620 && resSmall7})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  backgroundSize: "contain",
                  // height: "3210px",
                  position: "relative",
                }}
              >
                <h3
                  className="fourth_section__content__one__title"
                  style={{
                    fontSize: "28px",
                    color: Colors.blackText,
                    fontFamily: Fonts().primaryBold,
                  }}
                >
                  Digitalisation {innerWidth < 620 && <br />}des processus
                  métier
                </h3>
              </div>
              <p
                className="fourth_section__content__one__desc_nos fourth_nos_service_content_four_desc"
                style={{
                  fontSize: "14px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "50%",
                  marginTop: 16,
                }}
              >
                La digitalisation au service de la performance des entreprises.
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Une solution de transformation numérique des organisations,
                  qui concerne toutes les fonctions de l'entreprise.
                </strong>{" "}
              </p>
              <div className="fourth_section__items">
                <Items
                  desc="les processus financiers et comptables"
                  fontSize="13px"
                />
                <Items
                  desc="les processus de Ressources Humaines"
                  fontSize="13px"
                />
                <Items desc="les processus d’achats" fontSize="13px" />
                <Items
                  desc="les processus commerciaux et marketing"
                  fontSize="13px"
                />
              </div>
              <p
                className="fourth_section__content__one__desc"
                style={{
                  fontSize: "12px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "80%",
                  marginTop: 16,
                }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  {" "}
                  La digitalisation des processus apporte de
                </strong>
                <strong
                  ref={RefSeven}
                  style={{ fontFamily: Fonts().primaryBold, color: "red" }}
                >
                  {" "}
                  nombreux avantages à l’entreprise et à sa chaîne de valeur.
                </strong>
              </p>
              <Button
                Click={() => SeeMoreEvent("digitalisation")}
                Label="En savoir plus"
                useIcon
                notImage
                IconName={ArrowForwardIosIcon}
                style={{
                  width: innerWidth > 700 ? "240px" : "150px",
                  background: "rgb(246 215 218)",
                  color: "rgb(241 81 81)",
                  fontSize: innerWidth > 1100 ? "18px" : "10px",
                  textTransform: "capitalize",
                  fontWeight: "400",
                  borderRadius: "14px",
                  padding: innerWidth > 700 ? "14px" : "8px",
                  boxShadow: "rgb(215, 210, 210) 1px 1px 1px",
                  marginTop: "16px",
                  marginRight: "40px",
                }}
              />
            </div>
          </div>
          {innerWidth > 700 && (
            <div className="fifth_nos_service_image_small1">
              <img className="img_small1_nos_service" src={small7} />
            </div>
          )}
        </div>

        <div className="fifth_section__content__three_nos_services fifth_nos_service_content_three">
          <div className="fifth_nos_service_section1_small">
            <div className="fifth_nos_services_small_content">
              <div
                className="second_section_title_image"
                style={{
                  backgroundImage: `url(${innerWidth < 620 && small9})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  backgroundSize: "contain",
                  // height: "3210px",
                  position: "relative",
                }}
              >
                <h3
                  className="fifth_section__content__two__title fifth_nos_service_content_three_title"
                  style={{
                    fontSize: "28px",
                    color: Colors.blackText,
                    fontFamily: Fonts().primaryBold,
                  }}
                >
                  Formation
                </h3>
              </div>
              <p
                className="fifth_section__content__two__desc fifth__nos_service_content_three_desc"
                style={{
                  fontSize: "13px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  marginTop: 16,
                }}
              >
                Dans un monde des affaires en{" "}
                <strong style={{ fontFamily: Fonts().primaryBold, fontSize: "15px" }}>
                  évolution constante
                </strong>
                , il est primordial pour les dirigeants d'entreprise de rester à
                la 
                <strong style={{ fontFamily: Fonts().primaryBold, fontSize: "15px"  }}>
                  {" "}pointe des compétences managériales, commerciales et
                  digitales.
                </strong>
                Nous avons élaboré notre programme de formation pour soutenir
                votre croissance en vous aidant à acquérir{" "}
                <strong style={{ fontFamily: Fonts().primaryBold , fontSize: "15px" }}>
                  les compétences essentielles nécessaires pour garantir le
                  succès et la durabilité de votre entreprise.
                </strong>
                <br /> <br />
                Nous vous accompagnons sur les domaines suivants :
              </p>
              <div className="fourth_section__items">
                <Items desc="Rôle du dirigeant d’entreprise" fontSize="13px" />
                <Items desc="Pilotage de la performance" fontSize="13px" />
                <Items
                  desc="Maîtrise des risques clients/fournisseurs"
                  fontSize="13px"
                />
                <Items desc="Commercial" fontSize="13px" />
                <Items desc="Marketing digital" fontSize="13px" />
                <Items desc="Soft skills" fontSize="13px" />
                <Items desc="Digital" fontSize="13px" />
                <Items desc="Optimisation des processus" fontSize="13px" />
              </div>
              <p
                ref={RefEight}
                className="fifth__section__content__two__desc fifth__nos_service_content_section4_desc"
                style={{
                  fontSize: "13px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primarySemiBold,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  marginTop: 16,
                }}
              >
                Contacter nous pour une consultation gratuite afin de discuter
                de vos besoins de formation spécifiques.
              </p>
              <Button
                Click={SeeMoreEvent}
                Label="En savoir plus"
                useIcon
                notImage
                IconName={ArrowForwardIosIcon}
                style={{
                  width: innerWidth > 700 ? "240px" : "150px",
                  background: "rgb(246 215 218)",
                  color: "rgb(241 81 81)",
                  fontSize: innerWidth > 1100 ? "18px" : "10px",
                  textTransform: "capitalize",
                  fontWeight: "400",
                  borderRadius: "14px",
                  padding: innerWidth > 700 ? "14px" : "8px",
                  boxShadow: "rgb(215, 210, 210) 1px 1px 1px",
                  marginTop: "16px",
                  marginRight: "40px",
                }}
              />
            </div>
          </div>
          {innerWidth > 700 && (
            <div className="sexth_nos_service_image_small1  ___formation" style={{width: window.innerWidth > 1400 && "75rem"}}>
              <img className="img_small1_nos_service" src={small9} />
            </div>
          )}
        </div>

        <div className="fifth_section__content__six_nos_services fifth_nos_service_content_sex">
          <div className="fifth_nos_service_section1_small">
            <div className="fifth_nos_services_small_content2">
              <div
                className="second_section_title_image"
                style={{
                  backgroundImage: `url(${innerWidth < 620 && small6})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  backgroundSize: "contain",
                  // height: "3210px",
                  position: "relative",
                }}
              >
                <h3
                  className="fifth_section__content__one__title"
                  style={{
                    fontSize: "28px",
                    color: Colors.blackText,
                    fontFamily: Fonts().primaryBold,
                  }}
                >
                  Conseil en {innerWidth < 380 && <br />}conformité
                </h3>
              </div>
              <p
                className="fifth_section__content__one__desc_nos fifth_nos_service_content_sex_desc"
                style={{
                  fontSize: "14px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "98%",
                  marginTop: 16,
                }}
              >
                Nous accompagnons nos clients dans le déploiement des nouvelles
                réglementations, ainsi que dans l’audit, le diagnostic,
                l’optimisation et le renforcement des dispositifs de contrôle.
                <br />
                <br /> Nos savoir-faire :
              </p>
              <div className="fifth_section__items">
                <Items
                  desc="Analyse des impacts réglementaires (BALE 2, SOLVENCY 2,..)"
                  fontSize="13px"
                />
                <Items desc="Diagnostic de conformité" fontSize="13px" />
                <Items
                  desc="Dispositifs de lutte contre la fraude et le blanchiment"
                  fontSize="13px"
                />
                <Items
                  desc="Mise en place de procédures, normes de conformité"
                  fontSize="13px"
                />
                <Items
                  desc="Prise en charge de mission audit"
                  fontSize="13px"
                />
              </div>
              <p
                className="fifth_section__content__one__desc"
                style={{
                  fontSize: "12px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "80%",
                  marginTop: 16,
                }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  {" "}
                  Nos consultants sont déjà intervenus sur des missions de
                </strong>
                <br />
                <span
                  ref={RefSex}
                  style={{
                    color: innerWidth > 600 ? Colors.error : Colors.blackText,
                    position: "relative",
                    left: 12,
                  }}
                >
                  - D'implémentation de nouvelles obligations réglementaires
                  <br />- D'audit et de contrôle de vos dispositifs de
                  conformité <br />- De conduite de plans de remédiations
                </span>
              </p>
              <Button
                Click={SeeMoreEvent}
                Label="En savoir plus"
                useIcon
                notImage
                IconName={ArrowForwardIosIcon}
                style={{
                  width: innerWidth > 700 ? "240px" : "150px",
                  background: "rgb(246 215 218)",
                  color: "rgb(241 81 81)",
                  fontSize: innerWidth > 1100 ? "18px" : "10px",
                  textTransform: "capitalize",
                  fontWeight: "400",
                  borderRadius: "14px",
                  padding: innerWidth > 700 ? "14px" : "8px",
                  boxShadow: "rgb(215, 210, 210) 1px 1px 1px",
                  marginTop: "16px",
                  marginRight: "40px",
                }}
              />
            </div>
          </div>
          {innerWidth > 700 && (
            <div className="fifth_nos_service_image_small2">
              <img className="img_small1_nos_service" src={small6} />
            </div>
          )}
        </div>

        <div className="sexth_section__content__one nos_service_section4_content_one">
          <div className="sexth_nos_service_section1_small">
            <div className="sexth_nos_services_small_content">
              <div
                className="second_section_title_image"
                style={{
                  backgroundImage: `url(${innerWidth < 620 && small8})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  backgroundSize: "contain",
                  // height: "3210px",
                  position: "relative",
                }}
              >
                <h3
                  className="sexth_section__content__seven__title sexth_nos_service_section4_content_seven_title"
                  style={{
                    fontSize: "28px",
                    color: Colors.blackText,
                    fontFamily: Fonts().primaryBold,
                  }}
                >
                  Portage salarial
                </h3>
              </div>
              <p
                className="sexth_section__content__one__desc sexth_nos_service_section_content_seven_desc"
                style={{
                  fontSize: "14px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "50%",
                  marginTop: 8,
                }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Salarié ou indépendant, n’hésitez plus.
                  {innerWidth < 620 && <br />} Choisissez les deux grâce au
                  portage salarial.
                </strong>
                <br />
                Le portage salarial vous permet de devenir consultant{" "}
                {innerWidth < 620 && <br />} indépendant tout en conservant les
                avantages {innerWidth < 620 && <br />} d’un salarié.
                <br />{" "}
                <span style={{ fontFamily: Fonts().primaryBold }}>
                  Professionnels autonomes, nous vous proposons nos services.
                </span>
              </p>
              <div className="sexth_section__items" ref={RefFive}>
                <Items
                  desc="Notre rôle ?"
                  items={[
                    "Employeur, en vous proposant un contrat de travail à durée déterminée ou indéterminée.",
                  ]}
                  fontSize="13px"
                  removeBreakLigne
                />
                <Items
                  desc="Nos revenus ?"
                  items={["Les frais de gestion."]}
                  fontSize="13px"
                  removeBreakLigne
                />
                <Items
                  desc="Nos missions ?"
                  fontSize="13px"
                  removeBreakLigne
                  items={[
                    "Réaliser les démarches fiscales et administratives.",
                    "Etre le lien entre l'entreprise cliente et vous salarié (indépendant porté).",
                  ]}
                />
                <Items
                  desc="Comment ça marche ?"
                  fontSize="13px"
                  removeBreakLigne
                  items={[
                    "Vous trouvez votre mission, nous signons le contrat en tant que prestataire auprès de la société cliente.",
                    "Parallèlement, nous signons ensemble un contrat de travail.",
                    "bénéficierez alors d'un salaire et des avantages sous-jacents.",
                  ]}
                />
              </div>

              <Button
                Click={SeeMoreEvent}
                Label="En savoir plus"
                useIcon
                notImage
                IconName={ArrowForwardIosIcon}
                style={{
                  width: innerWidth > 700 ? "240px" : "150px",
                  background: "rgb(246 215 218)",
                  color: "rgb(241 81 81)",
                  fontSize: innerWidth > 1100 ? "18px" : "10px",
                  textTransform: "capitalize",
                  fontWeight: "400",
                  borderRadius: "14px",
                  padding: innerWidth > 700 ? "14px" : "8px",
                  boxShadow: "rgb(215, 210, 210) 1px 1px 1px",
                  marginTop: "16px",
                  marginRight: "40px",
                }}
              />
            </div>
          </div>
          {innerWidth > 700 && (
            <div className="sexth_nos_service_image_small1 portage__salarial">
              <img className="img_small1_nos_service" src={small8} />
            </div>
          )}
        </div>

        <div className="sexth_section__content__six_nos_services nos_service_content_sex_last">
          <div className="sexth_nos_service_section1_small">
            <div className="sexth_nos_services_small_content2">
              <div
                className="second_section_title_image"
                style={{
                  backgroundImage: `url(${innerWidth < 620 && small2})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  backgroundSize: "contain",
                  // height: "3210px",
                  position: "relative",
                }}
              >
                <h3
                  className="sexth_section__content__one__title"
                  style={{
                    fontSize: "28px",
                    color: Colors.blackText,
                    fontFamily: Fonts().primaryBold,
                  }}
                >
                  Nearshoring
                </h3>
              </div>
              <p
                className="sexth_section__content__one__desc"
                style={{
                  fontSize: "14px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "98%",
                  marginTop: 16,
                }}
              >
                Entrepreneurs francophones concentrez-vous sur vos activités
                stratégiques.
                <br /> Confiez-nous vos activités administratives et/ou de
                productions.
              </p>
              <div className="sexth_section__items">
                <Items
                  desc="Des professionnels qualifiés parlant votre langue"
                  fontSize="13px"
                />
                <Items desc="Facilement accessibles" fontSize="13px" />
                <Items desc="Ayant une proximité culturelle" fontSize="13px" />
                <Items
                  desc="Travaillant sur les mêmes créneaux horaires que vous"
                  fontSize="13px"
                />
              </div>
              <p
                className="sexth_section__content__one__desc"
                style={{
                  fontSize: "14px",
                  color: Colors.blackText,
                  fontFamily: Fonts().primaryRegular,
                  lineHeight: 1.7,
                  opacity: 0.7,
                  width: "80%",
                  marginTop: 16,
                }}
              >
                <strong
                  style={{
                    fontFamily: Fonts().primaryBold,
                    color: Colors.error,
                  }}
                >
                  {" "}
                  Des missions de développeur, de support et assistance,
                  d’office manager et de consultant, à des coûts très
                  compétitifs tout en garantissant une qualité de services de
                  norme européenne.
                </strong>
              </p>
              <Button
                Click={SeeMoreEvent}
                Label="En savoir plus"
                useIcon
                notImage
                IconName={ArrowForwardIosIcon}
                style={{
                  width: innerWidth > 700 ? "240px" : "150px",
                  background: "rgb(246 215 218)",
                  color: "rgb(241 81 81)",
                  fontSize: innerWidth > 1100 ? "18px" : "10px",
                  textTransform: "capitalize",
                  fontWeight: "400",
                  borderRadius: "14px",
                  padding: innerWidth > 700 ? "14px" : "8px",
                  boxShadow: "rgb(215, 210, 210) 1px 1px 1px",
                  marginTop: "16px",
                  marginRight: "40px",
                }}
              />
            </div>
          </div>
          {innerWidth > 700 && (
            <div className="sexth_nos_service_image_small1 seventh_last__one_is_here">
              <img className="img_small1_nos_service" src={small2} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
