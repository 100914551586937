export const Fonts = () => {
  return {
    primaryRegular: "Regular",
    primaryMedium: "Medium",
    primaryBold: "Bold",
    primarySemiBold: "Semi-Bold",
    primaryExtraBold: "Extra-Bold",
    primaryThin: "Thin",
    primaryItalic: "BlackItalic",
    primaryRegularItalic: "ThinItalic",
  };
};
