import React, { useEffect, useRef, useState } from "react";
import "./blogs.css";
import { Fonts } from "@common";
import { CiClock2 } from "react-icons/ci";
import { BLOGS } from "@services";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const URL =
  document.location.protocol + "//" + "3eafrica.ma/app" + "/images/blogs/";

export default ({}) => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoaing] = useState(false);
  const displayOnce = useRef(true);

  useEffect(() => {
    if (displayOnce.current) {
      fetchBlogs();
      displayOnce.current = false;
    }
  }, []);

  const fetchBlogs = () => {
    setIsLoaing(true);
    BLOGS.ALL()
      .then((result) => {
        setIsLoaing(false);
        if (result.status === 200 || result.status === 201) {
          setBlogs(result.data.data);
        }
      })
      .catch((error) => {
        setIsLoaing(false);
        if (error) {
          console.log(error, "@************@ from fetch data");
        }
      });
  };

  const onClickBlog = (id, name) => {
    const nameG = id + "_" + name;
    navigate(`/nos_blogs/${nameG}`);
  };

  return (
    <div className="blogs_container">
      {blogs.length > 0 && (
        <React.Fragment>
          <div className="title__blogs">
            <h1
              className="nos_blogs_title"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Nos Blogs
            </h1>
          </div>
          <div className="blogs__section_items">
            {!!blogs.length > 0 &&
              blogs.map((ele, index) => (
                <div className="blog-card" key={ele.id}>
                  <div className="meta">
                    <div
                      className="photo"
                      style={{
                        backgroundImage: `url(${URL + ele.image})`,
                      }}
                    ></div>
                    <ul className="details">
                      <li className="author">
                        <a href="#">{ele.title}</a>
                      </li>
                      {/* <li className="date">Aug. 24, 2015</li> */}
                      {/* <li className="tags">
                  <ul>
                    <li>
                      <a href="#">Learn</a>
                    </li>
                    <li>
                      <a href="#">Code</a>
                    </li>
                    <li>
                      <a href="#">HTML</a>
                    </li>
                    <li>
                      <a href="#">CSS</a>
                    </li>
                  </ul>
                </li> */}
                    </ul>
                  </div>
                  <div className="description">
                    <h1
                      className="blog___title"
                      style={{ fontFamily: Fonts().primarySemiBold }}
                    >
                      {ele.title}
                    </h1>
                    <h2
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <CiClock2
                        style={{ fontSize: "13px", marginTop: "4px" }}
                      />{" "}
                      <span
                        style={{
                          marginLeft: "5px",
                          marginTop: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {format(new Date(ele.created_at), "dd-MM-yyyy HH:mm")}
                      </span>
                    </h2>
                    <p
                      className="blog___description"
                      style={{ fontFamily: Fonts().primaryRegular }}
                    >
                      {" "}
                      {ele.description}
                    </p>
                    <p
                      className="read-more"
                      style={{ fontFamily: Fonts().primaryRegular }}
                    >
                      <a
                        onClick={() => onClickBlog(ele.id, ele.title)}
                        style={{ cursor: "pointer" }}
                      >
                        Read More
                      </a>
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
