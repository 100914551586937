import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { Menu as MenuIcon, AccountCircle } from "@mui/icons-material";
import Logo from "@images/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useColors } from "@common";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LogoutIcon from "@mui/icons-material/Logout";
import { LOGOUT } from "../../../Redux/account/types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { VscNewFile } from "react-icons/vsc";


const NavBar = () => {
  const [open, setOpen] = useState(false);
  const Colors = useColors();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(({ account }) => account);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleGoToCreateUserAccount = () => {
    navigate("/back_ofice/3eafrica/auth/sign_up");
  };
  const handleGoToCreateNewBlog = () => {
    navigate("/back_ofice/3eafrica/administration/blogs_managements");
  };

  const handleLogOut = () => {
    dispatch({ type: LOGOUT });
    setOpen(true);
    setTimeout(() => {
      navigate("/back_ofice/3eafrica/auth/sign_in");
      setOpen(false);
    }, 1500);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: "#e0e0e0", padding: "9px" }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img src={Logo} style={{ width: 60 }} />
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="profile"
          onClick={handleMenuOpen}
          sx={{ color: "#e38e16" }}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose} >3eafica.ma</MenuItem>
          <MenuItem onClick={handleMenuClose}>{user?.user?.email}</MenuItem>
          {user?.user?.role === "ADMIN" && (
            <>
              <MenuItem onClick={handleGoToCreateUserAccount}>
                <PersonAddIcon
                  style={{
                    marginRight: 10,
                    fontSize: 16,
                    background: Colors.error,
                    color: "#FFF",
                    borderRadius: 20,
                    padding: 4,
                  }}
                />
                Créer de nouveaux comptes
              </MenuItem>
              <MenuItem onClick={handleGoToCreateNewBlog}>
                <VscNewFile
                  style={{
                    marginRight: 10,
                    fontSize: 16,
                    background: Colors.primary,
                    color: "#FFF",
                    borderRadius: 20,
                    padding: 4,
                  }}
                />
                Créer de nouveaux Blog
              </MenuItem>
            </>
          )}
          <MenuItem onClick={handleLogOut}>
            <LogoutIcon
              style={{
                marginRight: 10,
                fontSize: 16,
                background: Colors.error,
                color: "#FFF",
                borderRadius: 20,
                padding: 4,
              }}
            />
            Déconnexion
          </MenuItem>
        </Menu>
      </Toolbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AppBar>
  );
};

export default NavBar;
