import React, { useRef, useState, useEffect } from "react";
import { useColors, Fonts } from "@common";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import Term from "@images/backgrounds/term.png";
import { PendingIcon } from "@components";
import "./index.css";

const { innerWidth, innerHeight } = window;
export const Items = ({ desc, strong, Ref }) => {
  const Colors = useColors();
  return (
    <div className="term_section__item">
      <PendingIcon />
      <p
        className="term_section__item_desc"
        style={{
          fontSize: "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          className="term_section_Strongs"
          style={{
            fontSize: innerWidth > 414 ? "12px" : "10px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {desc}
      </p>
    </div>
  );
};

export default ({}) => {
  const Colors = useColors();
  const section2Ref = useRef(null);
  const Home2Ref = useRef(null);
  const ContactRef = useRef(null);
  const AboutRef = useRef(null);
  const [active, setActive] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const OurServices = () => {
    setActive("services");
    navigate("/nos_services");
  };

  const hanleOnclickHome = () => {
    setActive("Accueil");
    // Home2Ref.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };

  const handleOnClickContact = () => {
    setActive("Contacts");
    navigate(`/${"contacts"}`);
  };
  const handleOnClickAbout = () => {
    setActive("Abouts");
    navigate(`/${"abouts"}`);
  };

  return (
    <div className="home__home" style={{ background: Colors.backgroundHex }}>
      <NavBar
        handleOurService={OurServices}
        active={active}
        hanleOnclickHome={hanleOnclickHome}
        handleOnClickContact={handleOnClickContact}
        handleOnClickAbout={handleOnClickAbout}
      />

      <div
        className="term_of_use"
        style={{
          backgroundImage: `url(${Term})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <div className="term_of_us_global">
          <h1
            className="big_title"
            style={{ fontFamily: Fonts().primaryBold, color: Colors.error }}
          >
            Mentions légales
          </h1>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Identification
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le site 3eafrica.ma ci-après dénommé le Site, est la propriété de
              la société 3E AFRICA, ICE 002399837000013 R.C Rabat (ci-après
              dénommée 3E AFRICA).
              <br /> Le responsable de la publication du Site est Gilles RAUCH
              en qualité de dirigeant.
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Responsabilité
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              3E AFRICA s’engage à faire ses meilleurs efforts pour assurer aux
              utilisateurs une accessibilité du Site à tout moment. 3E AFRICA
              prend le plus grand soin pour s’assurer de la qualité et de la
              fiabilité des informations contenues sur le Site.
              <br /> 3E AFRICA ne peut garantir l’exactitude et la complétude et
              l’actualité des informations diffusées sur son Site, ni la
              permanence de son bon fonctionnement ou sa totale sécurité
              informatique. Les informations contenues dans le Site sont
              fournies à titre indicatif sans aucune garantie, explicite ou
              implicite, de validité, d’exhaustivité ou d’actualité pour la
              seule information générale des utilisateurs, elles ne sauraient
              remplacer ou se substituer à des conseils ou consultations de
              nature juridique, fiscale ou de toute autre nature
              professionnelle. <br /> Elle ne pourra être tenue responsable en
              cas d’indisponibilité du site, pour quelque cause que ce soit.
              Elle ne saurait non plus être tenue responsable des erreurs, d’une
              absence de disponibilité des informations et/ou de la présence de
              virus sur son site. 3E AFRICA ne sera responsable ni des dommages,
              temporaires ou permanents, causés au système informatique du
              visiteur ni des pertes ou dommages éventuels (y compris, mais sans
              s’y limiter, la perte de données ou de profits) qui seraient subis
              suite à :<br />
              <div className="term_us__items">
                <Items desc="l’accès ou à la navigation sur le Site." />
                <Items desc="l’exploitation des informations contenues dans le Site." />
                <Items desc="la copie, la visualisation ou toute autre utilisation du Site qui donneraient lieu à une procédure contentieuse ou de recouvrement." />
              </div>
            </p>
            <p
              className="term_of_desc"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              3E AFRICA  ne sera pas non plus responsable des dommages indirects
              résultant d’une intrusion frauduleuse d’un tiers ayant entraîné
              une modification des information <br /> diffusées sur le Site ou
              un préjudice financier ou commercial, perte de programme ou de
              données du système d’information ou autre de l’utilisateur, même
              si <br /> 3E AFRICA a eu connaissance de la possibilité de
              survenance de tels dommages.
              <br />
              L’utilisateur reconnaît avoir pris connaissance de la présente
              notice légale et s’engage à la respecter. <br />
              L’utilisateur reconnaît également utiliser ces informations sous
              sa seule et unique responsabilité.
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Propriété Intellectuelle
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              La structure générale du Site, ainsi que chacun des éléments qui
              le compose pris indépendamment, notamment les textes, les données
              et les éléments graphiques, animés ou non, constituent des œuvres
              qui sont protégées en tant que telles par les lois sur la
              propriété intellectuelle, en particulier par le droit d’auteur,
              les dessins et modèles et le droit des marques, et par les traités
              internationaux. La structure générale du Site, ainsi que chacun
              des éléments qui le composent pris indépendamment, notamment les
              textes, les données et les éléments graphiques du Site ne doivent
              en aucun cas être modifiés, reproduits, affichés, présentés,
              distribués ou utilisés à des fins d’intérêt public ou commercial.
              Toute personne qui télécharge ou recueille des informations
              diffusées sur le Site ne dispose sur celles-ci que d’un droit
              d’usage privé, personnel et non transmissible. Les informations
              recueillies ne sont susceptibles que d’une seule reproduction
              papier ou numérique. Toute utilisation des informations
              recueillies devra mentionner la source. Les œuvres protégées
              reproduites sur le Site et n’appartenant pas à 3E AFRICA ont fait
              l’objet d’une autorisation expresse et préalable de reproduction
              de la part du titulaire des droits.
              <br /> Toute représentation totale ou partielle du Site ou d’un
              des éléments qui le composent sans l’autorisation expresse de 3E
              AFRICA est interdite et constituerait une contrefaçon sanctionnée
              par les lois relatives aux droits d’auteurs et aux droits voisins.
              <br /> Les marques ou les logotypes figurant sur le Site sont des
              marques déposées par 3E AFRICA ou par des tiers. Toute
              reproduction, imitation ou usage, totale ou partielle, de ces
              signes distinctifs sans l’autorisation expresse et en violation
              des lois relatives aux droits d’auteurs et aux droits voisins et
              engage la responsabilité de leur auteur.
              <br /> Les autres signes distinctifs, notamment les dénominations
              sociales, noms commerciaux, enseignes, noms de domaine reproduits
              sur le Site sont la propriété de 3E AFRICA ou des tiers et toute
              reproduction sans autorisation expresse est susceptible de
              constituer une usurpation engageant la responsabilité de son
              auteur. 3E AFRICA n’assume aucune obligation de suivi des
              informations que l’utilisateur peut déposer sur le Site. A ce
              titre, l’utilisateur garantit que n’importe quelle information, ou
              élément (le terme “élément” désigne tout projet, fichier ou autres
              pièces jointes envoyés) ou commentaires autres que des données
              personnelles, que l’utilisateur peut transmettre à 3E AFRICA par
              le site web de cette dernière, ne viole aucun droit de propriété
              intellectuelle d’un tiers ou une autre loi applicable. De telles
              informations, ou éléments ou commentaires, seront traités comme
              non-confidentiels et non-propriétaires. En soumettant ainsi
              n’importe quelle information, élément ou commentaire,
              l’utilisateur donne à 3E AFRICA tout droit nécessaire, de manière
              illimité et irrévocable, pour employer, exécuter, montrer,
              modifier et transmettre de telles informations, éléments ou
              commentaires, y compris n’importe quel suggestion, concept ou
              savoir-faire. 3E AFRICA se réserve le droit d’employer de telles
              informations, de tels éléments ou commentaires de n’importe quelle
              façon qu’elle le choisira.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Liens
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Les liens hypertextes mis en place en direction d’autres sites
              sont fournis pour la convenance personnelle des internautes, les
              pages restant de la seule responsabilité de leurs titulaires.
              <br /> La reproduction d’une page du Site dans un cadre
              n’appartenant pas à 3E AFRICA est interdite.
              <br /> 3E AFRICA se réserve le droit de supprimer les liens
              proposés par son site vers un site tiers.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Modification
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              3E AFRICA se réserve le droit de modifier le contenu de la
              présente notice légale à tout moment et sans préavis.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Loi applicable
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le présent Site est régi par les lois marocaines. <br />
              L’utilisateur reconnaît avoir pris connaissance de la présente
              notice légale et s’engage à la respecter.
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
