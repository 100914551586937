import React, { useState, useEffect } from "react";
import "./nav.css";
import Logo from "@images/Logo.png";
import { FcMenu } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";
import { IconButton } from "@mui/material";
import { useColors, Fonts } from "@common";
import Menu from "@images/icons/Menu.png";
import MenuX from "@images/icons/MenuX.png";
import Info from "@images/icons/Info.png";
import sell from "@images/icons/sell.png";
import HomeIcon from "@images/icons/HomeIcons.png";
import envelope from "@images/icons/envelope.png";
import { fontFamily } from "@mui/system";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const { innerWidth } = window;
const fadeIn = keyframes`
   from {opacity: 0;}
   to {opacity: 1;}
`;

const NavContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${innerWidth > 600 ? "100px" : innerWidth > 560 ? "90px" : "75px"};
  background-color: transparent;
  animation: ${fadeIn} 0.5s ease-in-out;
  z-index: 9999;
  padding: 1px;
`;

const NavContainer2 = styled.nav`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: ${innerWidth > 600 ? "100px" : innerWidth > 560 ? "90px" : "75px"};
  background-color: transparent;
  animation: ${fadeIn} 0.5s ease-in-out;
  z-index: 9999;
`;

export default ({
  handleOurService,
  active,
  hanleOnclickHome,
  handleOnClickContact,
  handleOnClickAbout,
}) => {
  const navigate = useNavigate();
  const Colors = useColors();
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [TheNav, setTheNav] = useState(NavContainer2);

  const handleIconClick = () => {
    setIsOpen((prv) => !prv);
    setClicked(!clicked);
    setDrawerVisible(!drawerVisible);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const isGreaterThan20 = scrollTop > 10;
      if (scrollTop > 5) {
        setTheNav(NavContainer);
      } else {
        setTheNav(NavContainer2);
      }
      setIsVisible(isGreaterThan20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [TheNav]);

  return (
    <TheNav
      className="section__nav"
      style={{
        background: isVisible ? "#fff" : "transparent",
        boxShadow: isVisible && "3px 2px 13px #ccccccbf",
      }}
    >
      <img
        className="header__image"
        src={Logo}
        alt="Logo"
        onClick={() => navigate("/")}
      />
      <ul className="section__itemsList">
        <li
          className="section__items"
          style={{
            color: active === "Accueil" ? Colors.error : Colors.textColors,
            borderBottom: active === "Accueil" && `2px solid ${Colors.error}`,
            padding: "3px 0px",
            fontSize: "20px",
          }}
          onClick={hanleOnclickHome}
        >
          Accueil
        </li>
        <li
          onClick={handleOnClickAbout}
          className="section__items"
          style={{
            color: active === "Abouts" ? Colors.error : Colors.textColors,
            borderBottom: active === "Abouts" && `2px solid ${Colors.error}`,
            padding: "3px 0px",
            fontSize: "20px",
          }}
        >
          À propos
        </li>
        <li
          className="section__items"
          style={{
            color: active === "services" ? Colors.error : Colors.textColors,
            borderBottom: active === "services" && `2px solid ${Colors.error}`,
            padding: "3px 0px",
            fontSize: "20px",
          }}
          onClick={handleOurService}
        >
          Nos offres
        </li>
        <li
          onClick={handleOnClickContact}
          className="section__items"
          style={{
            color: active === "Contacts" ? Colors.error : Colors.textColors,
            borderBottom: active === "Contacts" && `2px solid ${Colors.error}`,
            padding: "3px 0px",
            fontSize: "20px",
          }}
        >
          Contacts
        </li>
      </ul>
      <div className="resp___menu">
        <IconButton
          aria-label="delete"
          onClick={handleIconClick}
          style={{
            zIndex: "999",
            position: isOpen && "fixed",
            top: isOpen && "16px",
            right: isOpen && "-3px",
            padding: "16px 0px 16px 15px",
            marginRight:  "11px",
          }}
        >
          {isOpen ? (
            <img
              src={MenuX}
              style={{
                width: 26,
                height: 26,
                marginRight: 19,
                transition: "all .3s ease-in-out",
              }}
            />
          ) : (
            <img
              className="menu_africa"
              src={Menu}
              style={{
                width: 35,
                height: 26,
                marginRight: 10,
                transition: "all .3s ease-in-out",
              }}
            />
          )}
        </IconButton>
        <ul className={`drawer-items ${drawerVisible ? "visible" : ""}`}>
          <li className="section__items" onClick={hanleOnclickHome}>
            {" "}
            <img src={HomeIcon} style={{ width: 20, height: 20 }} />
            <strong
              style={{
                color: active === "Accueil" ? Colors.error : Colors.blackText,
                borderBottom:
                  active === "Accueil" && `2px solid ${Colors.error}`,
                padding: "3px 0px",
                fontSize: "17px",
                marginLeft: "12px",
                fontFamily: Fonts().primaryRegular,
              }}
            >
              Accueil
            </strong>
          </li>
          <li onClick={handleOnClickAbout} className="section__items">
            <img src={Info} style={{ width: 20, height: 20 }} />
            <strong
              style={{
                color: active === "Abouts" ? Colors.error : Colors.blackText,
                borderBottom:
                  active === "Abouts" && `2px solid ${Colors.error}`,
                padding: "3px 0px",
                fontSize: "17px",
                marginLeft: "12px",
                fontFamily: Fonts().primaryRegular,
              }}
            >
              {" "}
              À propos
            </strong>
          </li>
          <li className="section__items" onClick={handleOurService}>
            <img src={sell} style={{ width: 20, height: 20 }} />
            <strong
              style={{
                color: active === "services" ? Colors.error : Colors.blackText,
                borderBottom:
                  active === "services" && `2px solid ${Colors.error}`,
                padding: "3px 0px",
                fontSize: "17px",
                marginLeft: "12px",
                fontFamily: Fonts().primaryRegular,
              }}
            >
              {" "}
              Nos Services
            </strong>
          </li>
          <li onClick={handleOnClickContact} className="section__items">
            <img src={envelope} style={{ width: 20, height: 20 }} />
            <strong
              style={{
                color: active === "Contacts" ? Colors.error : Colors.blackText,
                borderBottom:
                  active === "Contacts" && `2px solid ${Colors.error}`,
                padding: "3px 0px",
                fontSize: "17px",
                marginLeft: "12px",
                fontFamily: Fonts().primaryRegular,
              }}
            >
              Contacts
            </strong>
          </li>
        </ul>
      </div>
      <div className="empty__div" />
    </TheNav>
  );
};
