import { SETCOOKIES, LOGOUT, LOGIN } from "./types";

const initialState = {
  cookies: null,
  user: null,
  isLogged: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETCOOKIES:
      return {
        ...state,
        cookies: action.payload,
      };
    case LOGIN:
      return {
        ...state,
        user: { ...action.payload },
        isLogged: true,
      };
    case LOGOUT: {
      return { ...state, isLogged: false, user: null };
    }
    default:
      return state;
  }
};
