import React from "react";
import small3 from "@images/smalls/small3.png";
import { PendingIcon, Button } from "@components";
import { useColors, Fonts } from "@common";
import { useNavigate } from "react-router-dom";
import "./style.css";

const { innerWidth, innerHeight } = window;

export const Items = ({ desc, strong, fontSize }) => {
  const Colors = useColors();
  return (
    <div className="section4__item">
      <PendingIcon width={15} height={15} />
      <p
        className="section4__item_desc"
        style={{
          fontSize: fontSize || "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          style={{
            fontSize: fontSize || "12px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {desc}
      </p>
    </div>
  );
};

export default ({ RefOne }) => {
  const Colors = useColors();
  const navigate = useNavigate();
  return (
    <div className="pilotage_section__content__one" ref={RefOne}>
      <div className="pilotage_section_content_1">
        {innerWidth > 700 && (
          <div className="pilotage_smaøl2_div_image">
            <img className="pilotage_section4_small_image_1" src={small3} />
          </div>
        )}
        <div className="pilotage_section_content1_content">
          <h3
            className="pilotage_section__content__one__title"
            style={{
              fontSize: "24px",
              color: Colors.blackText,
              fontFamily: Fonts().primarySemiBold,
            }}
          >
            Pilotage de la performance
          </h3>
          <p
            className="pilotage_section__content__one__desc"
            style={{
              fontSize: "16px",
              color: Colors.textColors,
              fontFamily: Fonts().primaryRegular,
              lineHeight: 1.7,
              opacity: 0.7,
            }}
          >
            Le pilotage de la performance est l’ensemble des outils et des
            processus permettant aux dirigeants d’améliorer la performance de
            leur entreprise. La mise en place d’un outil de pilotage de la
            performance
            {innerWidth < 600 && <br />}
            permet de :
          </p>
          <div className="pilotage_section__items">
            <Items
              desc="Transformer vos données en informations"
              fontSize="13px"
            />
            <Items
              desc="Gagner du temps pour les activités à forte valeur ajoutée"
              fontSize="13px"
            />
            <Items
              desc="Faire collaborer efficacement tous les départements de l’entreprise"
              fontSize="13px"
            />
            <Items desc="Prendre des décisions rapides" fontSize="13px" />
            <div className="section4__added_para">
              <p
                className="pilotage__section4__added"
                style={{
                  // fontFamily: Fonts().primaryRegular,
                  marginTop: "2%",
                  marginBottom: 10,
                  fontSize: innerWidth < 414 ? "12px" : "14px",
                  fontFamily: 'Roboto',
                  fontWeight: 'bold'
                }}
              >
                Avec nous vous améliorez la création de valeur et{" "}
                <span
                  style={{
                    color: Colors.error,
                  }}
                >
                  la compétitivité de votre entreprise.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
