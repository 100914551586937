import React, { useEffect, useState } from "react";
import Back1 from "@images/backgrounds2/Back1.png";
import Small1 from "@images/smalls/small1.png";
import resSmall1 from "@images/smalls/resSmall1.png";
import ResBack1 from "@images/responsive2/resBack1.png";
import NavBar from "./NavBar";
import phone from "@images/icons/Phone.png";
import { useColors, Fonts } from "@common";
import { Button } from "@components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./styles/Section1.css";

export default ({
  handleOurService,
  active,
  hanleOnclickHome,
  Ref,
  handleOnClickContact,
  handleOnClickAbout,
  Ref2,
}) => {
  const { innerHeight, innerWidth } = window;

  const Colors = useColors();
  const [TheNav, setTheNav] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 5) {
        setTheNav(true);
      } else {
        setTheNav(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [TheNav]);

  return (
    <div
      ref={Ref}
      style={{
        backgroundImage: `url(${innerWidth > 600 ? Back1 : ResBack1})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
        height: "100vh",
        position: "relative",
      }}
      className="section1___main"
    >
      <NavBar
        handleOurService={handleOurService}
        active={active}
        hanleOnclickHome={hanleOnclickHome}
        handleOnClickContact={handleOnClickContact}
        handleOnClickAbout={handleOnClickAbout}
      />
      {TheNav && <div style={{ height: "90px" }} />}
      <div
        className="first__content"
        style={{
          backgroundImage: `url(${innerWidth < 600 ? resSmall1 : Small1})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
          backgroundSize: "contain",
          width: "100%",
        }}
      >
        <div className="home__first_content">
          <div className="right_section_home">
            {/* <span className="bienvenu" style={{ color: Colors.error }}>
            BIENVENUE
          </span> */}
            <div className="first__desc">
              <h2
                style={{
                  fontFamily: Fonts().primaryBold,
                  color: Colors.textColors,
                  fontSize: "40px",
                }}
              >
                Le Digital au cœur <br />
                de votre Performance
              </h2>
            </div>
            <div className="second__div">
              <p
                className="second__desc"
                style={{
                  fontFamily: Fonts().primaryRegular,
                  color: Colors.textColors,
                }}
              >
                Notre ambition, vous permettre {innerWidth < 600 && <br />}
                de vous concentrer,{innerWidth < 600 && <br />}{" "}
                <span style={{ color: "#F15151" }}>
                  sur la croissance de votre business, sur la stratégie et le
                  développement de votre entreprise {innerWidth < 600 && <br />}
                </span>
              </p>
            </div>
            <div className="action__secion">
              <Button
                Click={handleOnClickContact}
                Label="Contactez-nous"
                useIcon
                IconName={ArrowForwardIosIcon}
                notImage
                style={{
                  width:
                    innerWidth > 700
                      ? "190px"
                      : innerWidth > 600
                      ? "230px"
                      : innerWidth < 600
                      ? "129px"
                      : "150px",
                  background: Colors.error,
                  color: "rgb(255, 255, 255)",
                  fontSize:
                    innerWidth > 600
                      ? "15px"
                      : innerWidth < 600
                      ? "9px"
                      : "10px",
                  textTransform: "lowercase",
                  fontWeight: "400",
                  borderRadius: innerWidth < 600 ? "12px" : "14px",
                  fontFamilly: Fonts().primaryRegular,
                  padding:
                    innerWidth > 600
                      ? "12px"
                      : innerWidth < 600
                      ? "7px"
                      : "9px",
                  boxShadow: "2px 3px 7px #d7d2d2",
                }}
              />
              <div className="phone__test">
                <img
                  src={phone}
                  alt="phone"
                  style={{ width: "40px", height: "40px" }}
                />
                <div className="numbers">
                  <p className="number1" style={{ color: Colors.grayTextƒ }}>
                    +212 6 74 85 39 92
                  </p>
                  <p className="number2" style={{ color: Colors.grayText }}>
                    +33 6 81 21 67 99
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <img className="first_small_home" src={Small1} alt="firstSmall" /> */}
      </div>
    </div>
  );
};
