import React, { useState, useEffect } from "react";
import "./modal.css";

const Modal = ({ isOpen, onClose, children }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setModalOpen(false);
    onClose();
  };

  return (
    <>
      {modalOpen && (
        <div className="backdrop" onClick={handleClose}>
          <div
            className={`modal ${modalOpen ? "modal-open" : ""}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-content">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
