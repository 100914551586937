import React, { useRef, useState, useEffect } from "react";
import "./home.css";
import { useColors, Fonts } from "@common";
import Section1 from "../components/Section1";
import Section2 from "../components/Section2";
import Section3 from "../components/section3";
import Section4 from "../components/Sections4";
import Section5 from "../components/Section5";
import Section6 from "../components/Section6";
import Section7 from "../components/Section7";
import Section8 from "../components/Section8";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Arrow from "@images/icons/arrow_left.png";
import { Button, Modal } from "@components";
import { SETCOOKIES } from "../../Redux/account/types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import Blogs from "../components/Blogs";

import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const { innerHeight, innerWidth } = window;

export default ({}) => {
  const { cookies } = useSelector(({ account }) => account);
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(
    !!cookies?.isCanclled
      ? false
      : !!cookies?.isAccepted
      ? false
      : !!cookies?.preferences
      ? false
      : true
  );
  const params = useParams();
  const Colors = useColors();
  const RefOne = useRef();
  const RefTwo = useRef();
  const RefThree = useRef();
  const RefFour = useRef();
  const section2Ref = useRef(null);
  const NosOffer = useRef(null);
  const Home2Ref = useRef(null);
  const ContactRef = useRef(null);
  const [isScrollGreaterThan20, setIsScrollGreaterThan20] = useState(false);
  const AboutRef = useRef(null);
  const [active, setActive] = useState("Accueil");
  const [isClicked, setIsClicked] = useState({ id: null, clicked: false });
  const [idBox, setIdBox] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [personnalisation, setPersonnalisation] = useState(false);
  const [analytiques, setAnalytiques] = useState(false);
  const cookiesref = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);

  const navigate = useNavigate();

  const { name } = params;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const isGreaterThan20 = scrollTop > 20;
      setIsScrollGreaterThan20(isGreaterThan20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    //   cookiesref.current.style =
    //     "transform: translateX(0px)!important;  transition: all .4s ease-in-out";
    //   // Add bounce animation using keyframes
    //   cookiesref.current.style.animation = "bounce 1s!important";
    //   cookiesref.current.style.animationIterationCount = "infinite!important";
    //   setIsAnimated(true);
    // }, 4000);
    setTimeout(() => {
      setIsAnimated(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!!name) {
      if (name === "abouts") {
        handleOnClickAbout();
      } else if (name === "contacts") {
        handleOnClickContact();
      }
    }
  }, [name]);

// Assuming you are using React
// useEffect(() => {
//   if (section2Ref.current) {
//     section2Ref.current.style.marginTop = "76px";
//     section2Ref.current.scrollIntoView({ behavior: "smooth"});
//   }
// }, [section2Ref]);

  const OurServices = () => {
    setActive("services");
    // NosOffer.current.scrollIntoView({ behavior: "smooth" });
    navigate("/nos_services");
  };

  const hanleOnclickHome = () => {
    setActive("Accueil");
    Home2Ref.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };

  const handleOnClickContact = () => {
    setActive("Contacts");
    ContactRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleOnClickAbout = () => {
    setActive("Abouts");
    section2Ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const handleClicked = (id) => {
    setIsClicked({ id: id, clicked: true });
    setIdBox(id);
    if (id === 1) {
      // RefOne.current.scrollIntoView({ behavior: "smooth" });
      navigate(`/nos_services/${"pilotage"}`);
    } else if (id === 2) {
      // RefTwo.current.scrollIntoView({ behavior: "smooth" });
      navigate(`/nos_services/${"marketing"}`);
    } else if (id === 3) {
      // RefThree.current.scrollIntoView({ behavior: "smooth" });
      navigate(`/nos_services/${"digitalisation"}`);
    } else {
      // RefFour.current.scrollIntoView({ behavior: "smooth" });
      navigate(`/nos_services/${"externalisation"}`);
    }
  };

  const onMouseEnter = (id) => {
    setIsClicked({ id: id, clicked: true });
    setIdBox(id);
  };
  const onMouseLeave = (id) => {
    setIsClicked({ id: null, clicked: false });
    setIdBox(null);
  };

  const handleClick = (name) => {
    navigate(`/nos_services/${name}`);
  };

  const handleScrollToTheTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOnClickCancell = () => {
    dispatch({
      type: SETCOOKIES,
      payload: { isCanclled: true, isAccepted: false, preferences: null },
    });
    setIsActive(false);
    handleCloseModal();
    toast.error("Les Cookies annulés", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleOnClickAccepte = () => {
    dispatch({
      type: SETCOOKIES,
      payload: { isCanclled: false, isAccepted: true, preferences: null },
    });
    setIsActive(false);
    handleCloseModal();
    toast.success("Les Cookies acceptés", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleOnClickPreferences = () => {
    dispatch({
      type: SETCOOKIES,
      payload: {
        isCanclled: false,
        isAccepted: false,
        preferences: {
          personnalisation: personnalisation,
          analytiques: analytiques,
        },
      },
    });
    setIsActive(false);
    handleCloseModal();
    toast.info("Les préférences enregistrées", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePersonalisation = (e) => {
    setPersonnalisation(e.target.checked);
  };
  const handleAnlytiques = (e) => {
    setAnalytiques(e.target.checked);
  };

  return (
    <div
      className="home__home"
      style={{ background: Colors.backgroundHex, position: "relative" }}
    >
      <Section1
        handleOurService={OurServices}
        active={active}
        hanleOnclickHome={hanleOnclickHome}
        Ref={Home2Ref}
        handleOnClickContact={handleOnClickContact}
        handleOnClickAbout={handleOnClickAbout}
        // Ref2={section2Ref}
      />
      <Section2
        Ref={section2Ref}
        isClicked={isClicked}
        idBox={idBox}
        handleClicked={handleClicked}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Section3
        Ref={NosOffer}
        RefOne={RefOne}
        RefTwo={RefTwo}
        RefThree={RefThree}
        RefFour={RefFour}
      />

      <Section6 Ref={AboutRef} handleClick={handleClick} />
      <Blogs />
      <Section8 Ref={ContactRef} />
      <Footer />
      {isScrollGreaterThan20 && (
        <Stack
          direction="row"
          spacing={1}
          style={{
            position: "fixed",
            left: 30,
            bottom: 30,
            background: Colors.error + 21,
            borderRadius: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
          }}
        >
          <IconButton aria-label="delete" onClick={handleScrollToTheTop}>
            <img
              src={Arrow}
              style={{ width: 15, height: 22, transform: "rotate(270deg)" }}
            />
          </IconButton>
        </Stack>
      )}

      {isActive && (
        <CSSTransition
          in={isAnimated}
          timeout={1000}
          classNames="bounce"
          unmountOnExit
        >
          <div
            className="section1_cookies"
            ref={cookiesref}
            // style={{
            //   transform: "translateX(-920px)",
            // }}
          >
            <div className="cookies_content">
              <p
                className="cookies_desc"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                En cliquant sur "Accepter", vous accepterez le stockage <br />{" "}
                de cookies sur votre appareil pour améliorer la navigation{" "}
                <br /> sur le site, analyser l'utilisation du site et nous aider
                dans <br /> nos efforts de marketing. <br /> Consultez notre
                politique de confidentialité pour plus d'informations.
              </p>
              <div className="cookies_action">
                <a
                  onClick={handleOpenModal}
                  className="first_link_cookies"
                  style={{
                    color: Colors.error,
                    fontFamily: Fonts().primaryRegular,
                  }}
                >
                  Préférences
                </a>
                <Button
                  Click={handleOnClickCancell}
                  Label="Refuser"
                  style={{
                    width: innerWidth > 1080 ? "115px" : "80px",
                    background: "transparent",
                    textTransform: "capitalize",
                    color: Colors.blackText,
                    fontSize: "10px",

                    fontWeight: "400",
                    borderRadius: "14px",
                    fontFamilly: Fonts().primaryRegular,
                    padding: "10px 0px",
                    border: `1px solid ${Colors.error}`,
                  }}
                />
                <Button
                  Click={handleOnClickAccepte}
                  Label="Accepter"
                  style={{
                    width: innerWidth > 1080 ? "115px" : "80px",
                    background: Colors.error,
                    textTransform: "capitalize",
                    color: "#FFF",
                    fontSize: "10px",

                    fontWeight: "400",
                    borderRadius: "14px",
                    fontFamilly: Fonts().primaryRegular,
                    padding: "10px 0px",
                  }}
                />
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="modal-content">
          <div className="modal-top-content">
            <h3
              className="modal__title"
              style={{ fontFamily: Fonts().primaryBold, color: Colors.error }}
            >
              Centre de confidentialité
            </h3>
            <div className="modal_top-content_border" />
          </div>
          <div className="modal_descreption">
            <p
              className="modal_desc"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Lorsque vous visitez des sites web, ils peuvent stocker ou
              récupérer des donnée dans votre navigateur.
              <br /> Ce stockage est souvent nécessaire pour assurer les
              fonctionnalités de base du site web.
              <br /> Le stockage peut être utilisé à des fins de marketing,
              d'analyse et de personnalisation du site, comme la conservation de
              vos préférences.
              <br /> La vie privée est importante pour nous, donc vous avez la
              possibilité de désactiver certains types de stockage qui peuvent
              ne pas être nécessaires pour le fonctionnement de base du site
              web. Le blocage de certaines catégories peut avoir un impact sur
              votre expérience sur le site web.
              <br />
            </p>
          </div>
          <div className="modal_actions">
            <Button
              Click={handleOnClickCancell}
              Label="Refuser les cookies"
              style={{
                width:
                  innerWidth > 1080
                    ? "160px"
                    : innerWidth > 340
                    ? "160px"
                    : "130px",
                background: "transparent",
                textTransform: "capitalize",
                color: Colors.blackText,
                fontSize: "10px",

                fontWeight: "400",
                borderRadius: "14px",
                fontFamilly: Fonts().primaryRegular,
                padding: "10px 0px",
                border: `1px solid ${Colors.error}`,
              }}
            />
            <Button
              Click={handleOnClickAccepte}
              Label="Accepter les cookies"
              style={{
                width:
                  innerWidth > 1080
                    ? "160px"
                    : innerWidth > 340
                    ? "160px"
                    : "130px",
                background: Colors.error,
                textTransform: "capitalize",
                color: "#FFF",
                fontSize: "10px",

                fontWeight: "400",
                borderRadius: "14px",
                fontFamilly: Fonts().primaryRegular,
                padding: "10px 0px",
                marginLeft: "12px",
              }}
            />
          </div>
          <div className="modal-top-content">
            <h3
              className="modal__title"
              style={{ fontFamily: Fonts().primaryBold, color: Colors.error }}
            >
              Centre de confidentialité
            </h3>
            <div className="modal_top-content_border" />
          </div>
          <div className="modal_essencial">
            <p
              className="essencial"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Essentiels
            </p>
            <p
              className="Toujours_actifs"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Toujours actifs
            </p>
          </div>
          <div className="small_des">
            <p
              className="modal_desc"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Ces éléments sont nécessaires pour permettre la fonctionnalité de
              base du site web.
              <br />
            </p>
          </div>
          <div className="modal_border_second" />
          <div className="after_second_border">
            <h4
              className="after_second_border_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Personnalisation
            </h4>
            <div className="modal_desc_with_check_box">
              <p
                className="modal_desc seconds"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Ces éléments permettent au site web de se souvenir des choix que
                vous faites (comme votre nom d'utilisateur, votre langue ou la
                région dans laquelle vous vous trouvez) et de fournir des
                fonctionnalités améliorées et plus personnelles.
                <br /> Par exemple, un site web peut vous fournir des rapports
                météorologiques locaux ou des informations sur le trafic en
                stockant des données sur votre emplacement actuel.
                <br />
              </p>
              <Checkbox
                {...label}
                checked={personnalisation}
                onChange={handlePersonalisation}
              />
            </div>
          </div>
          <div className="modal_border_second" />
          <div className="after_second_border">
            <h4
              className="after_second_border_title"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Analytiques
            </h4>
            <div className="modal_desc_with_check_box">
              <p
                className="modal_desc second"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Ces éléments aident l'opérateur du site web à comprendre comment
                fonctionne son site web, comment les visiteurs interagissent
                avec le site et s'il peut y avoir des problèmes techniques.
                <br /> Ce type de stockage ne collecte généralement pas
                d'informations permettant d'identifier un visiteur.
                <br />
              </p>
              <Checkbox
                {...label}
                checked={analytiques}
                onChange={handleAnlytiques}
              />
            </div>
          </div>

          <div className="modal_footer">
            <Button
              Click={handleOnClickPreferences}
              Label="Confirmer mes préférences et fermer"
              style={{
                width: innerWidth > 1080 ? "200px" : "100%",
                background: Colors.error,
                textTransform: "capitalize",
                color: "#FFF",
                fontSize: "10px",

                fontWeight: "400",
                borderRadius: "14px",
                fontFamilly: Fonts().primaryRegular,
                padding: "10px 0px",
              }}
            />
          </div>
        </div>
      </Modal>
      {/* <ToastContainer /> */}
    </div>
  );
};
