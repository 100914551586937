import React from "react";
import { useColors, Fonts } from "@common";
import "./box.css";

export default ({
  topText,
  image1,
  image2,
  bottomText,
  isClicked,
  handleClicked,
  id,
  onMouseEnter,
  onMouseLeave
}) => {
  const { innerWidth, innerHeight } = window;
  const Colors = useColors();
  return (
    <div
      className="boxe__section"
      onClick={handleClicked}
      onFocus={handleClicked}
      onMouseEnter={onMouseEnter}
      onMouseLeave = {onMouseLeave}
    >
      <div
        className="box__content"
        style={{
          background:
            isClicked.clicked === true && isClicked.id === id
              ? Colors.error
              : "#FFF",
          transform:
            isClicked.clicked === true && isClicked.id === id
              ? "scale(1.05)"
              : "scale(1)",
        }}
      >
        <p
          className="top__text"
          style={{
            fontSize: innerWidth > 600 ? "20px" : "9px",
            textAlign: "center",
            fontFamily: Fonts().primaryMedium,
            color:
              isClicked.clicked === true && isClicked.id === id
                ? "#FFF"
                : Colors.blackText,
          }}
        >
          {topText}
        </p>
        <img
          className="box__image"
          src={
            isClicked.clicked === true && isClicked.id === id ? image2 : image1
          }
          alt={topText}
        />
        <p
          className="bottom__text"
          style={{
            fontSize: "10px",
            textAlign: "center",
            fontFamily: Fonts().primaryRegular,
            color:
              isClicked.clicked === true && isClicked.id === id
                ? "#FFF"
                : Colors.blackText,
          }}
        >
          {bottomText}
        </p>
        {isClicked.clicked === true && isClicked.id === id && (
          <div className="border__section__box" />
        )}
      </div>
    </div>
  );
};
