// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import "./index.css";
import Box from "./components/boxSlide";
import profile1 from "@images/profiles/profile1.png";
import profile2 from "@images/profiles/profile2.png";
import profile3 from "@images/profiles/profile3.png";

const Items = [
  {
    id: 1,
    image: profile1,
    name: "Jayce Clark",
    job: "Jayce Clark",
    desc: "En tant qu'agence de marketing, nous remercions 3E AFRICA pour le travail acharné qui nous a aidés et guidés pour créer notre site Web",
  },
  {
    id: 2,
    image: profile2,
    name: "Tommy Dents",
    job: "CEO de innovate",
    desc: " 3E AFRICA est réactif et à la écoute. nous avons été impressionnés par leur maîtrise de toutes les étapes de la création d'un site web, du conseil initial à la mise en ligne, BRAVO ",
  },
  {
    id: 3,
    image: profile3,
    name: "Anna Vaumba",
    job: "Anna Vaumba",
    desc: " Quel plaisir de travailler avec 3E AFRICA, très professionnel et passionné par l'innovation et l'apport de nouvelles technologies sur le terrain ",
  },
  {
    id: 4,
    image: profile1,
    name: "Anna Vaumba",
    job: "Anna Vaumba",
    desc: " Quel plaisir de travailler avec 3E AFRICA, très professionnel et passionné par l'innovation et l'apport de nouvelles technologies sur le terrain ",
  },
];

const ScreenWidth = window.innerWidth;
export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <>
      {ScreenWidth > 481 ? (
        <Slider {...settings} className="slider__section7">
          {Items.map((ele) => (
            <Box ele={ele} key={ele.id} />
          ))}
        </Slider>
      ) : (
        <div className="our__clients__box_responsive">
          {Items.map(
            (ele, index) => (index) < 3 && <Box ele={ele} key={ele.id} />
          )}
        </div>
      )}
    </>
  );
}
