import React, { useState, useEffect } from "react";
import "./ComingSoonPage.css";
import ComingSoon from "@images/imgs/comingSoon.png";
import { useColors, Fonts } from "@common";
import { Button, TextInput } from "@components";
import { toast } from "react-toastify";
import { CONTACTS } from "@services";
import { TextField } from "@mui/material";

const { innerWidth, innerHeight } = window;

export default () => {
  const Colors = useColors();
  const [countdown, setCountdown] = useState(10); // Countdown duration in seconds
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoaing] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = () => {
    setIsLoaing(true);
    if (email) {
      const params = {
        email: email,
        from: "coomig",
      };
      CONTACTS.NEW(params)
        .then((data) => {
          setIsLoaing(false);
          setEmail("");
          if (data.status === 201 || data.status === 200) {
            toast.success("Votre email est enregistré", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          setIsLoaing(false);
          if (error) {
            setTimeout(() => {
              setEmail("");
              toast.error("VQuelque chose s'est mal passé", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }, 100);
          }
        });
    } else {
      setIsLoaing(false);
      setTimeout(() => {
        setEmail("");
        toast.error("Vous devez d'abord remplir les champs", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }, 100);
    }
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="coming-soon-container">
      <div className="cooming_soon_container">
        <div className="top_section">
          <h1
            className="grande_title"
            style={{ fontFamily: Fonts().primaryBold }}
          >
            Coming Soon
          </h1>
          <p
            className="Title"
            style={{ fontFamily: Fonts().primaryRegular, color: Colors.error }}
          >
            Vous pourrez bientôt découvrir nos offres détaillées ! <br />
            Laissez-nous votre email pour être notifié des publications.
          </p>
        </div>

        <div className="image-container">
          <img
            src={ComingSoon}
            alt="Coming Soon"
            className="loading-animation fade-in"
          />
        </div>
      </div>
      <div className="border_comming _soon" />
      <div className="form_section_container">
        <div className="inputs__party1">
          <TextField
            onChange={(e) => handleEmail(e)}
            value={email}
            label="Laissez votre email ici"
            variant="outlined"
            fullWidth
            type="email"
            className="comming__soon_input"
            placeholder="Laissez votre email ici"
            sx={{
              "& .MuiInputLabel-root": {
                color: "#e74c3c", // Custom label color
              },
              "& .MuiOutlinedInput-root": {
                // "& fieldset": {
                //   borderColor: "#e74c3c", // Custom focused border color
                //   borderWidth: 2, // Add additional border width
                // },
                "& .MuiInputLabel-root": {
                  color: "#e74c3c", // Custom label color
                },
                "&:hover fieldset": {
                  borderColor: "#e74c3c", // Custom hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e74c3c", // Custom focused border color
                  borderWidth: 2, // Add additional border width
                },
              },
            }}
          />
        </div>
        <div className="section_cooming_action">
          <Button
            isLoading={isLoading}
            Click={handleChange}
            Label="Envoyer"
            style={{
              width: innerWidth > 600 ? "216px" : "190px",
              background: "rgb(241, 81, 81)",
              color: "rgb(255, 255, 255)",
              fontSize: innerWidth > 600 ? "13px" : "11px",
              textTransform: "capitalize",
              fontWeight: "400",
              borderRadius: "14px",
              padding: innerWidth > 600 ? "14px" : "12px",
              boxShadow: "rgb(215 210 210) 2px 3px 7px",
              marginTop: "2rem",
              marginRight: innerWidth > 600 ? "40px" : "0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};
