import React from "react";
import small3 from "@images/smalls/small3.png";
import { PendingIcon, Button } from "@components";
import { useColors, Fonts } from "@common";
import { useNavigate } from "react-router-dom";
import "./style.css";
import small7 from "@images/smalls/small7.png";

const { innerWidth, innerHeight } = window;

export const Items = ({ desc, strong, fontSize }) => {
  const Colors = useColors();
  return (
    <div className="section4__item">
      <PendingIcon width={15} height={15} />
      <p
        className="section4__item_desc"
        style={{
          fontSize: fontSize || "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          style={{
            fontSize: fontSize || "12px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {desc}
      </p>
    </div>
  );
};

export default ({ RefThree }) => {
  const Colors = useColors();
  const navigate = useNavigate();
  return (
    <div className="digitalisation_section__content__four" ref={RefThree}>
      <div className="digitalisation_section_content_1">
        {innerWidth > 700 && (
          <div className="digitalisation_smaøl_div_image section5__with_titles">
            <img src={small7} className="section4_small_image_1" />
          </div>
        )}
        <div className="digitalisation_section_content1_content">
          <div className="digitalisation_section__image_with_titles">
            {innerWidth <= 700 && (
              <div className="digitalisation_smaøl_div_image section5__with_titles">
                <img src={small7} className="section4_small_image_1" />
              </div>
            )}
            <h3
              className="digitalisation_section__content__one__title"
              style={{
                fontSize: "18px",
                color: Colors.blackText,
                fontFamily: Fonts().primaryBold,
              }}
            >
              Digitalisation {innerWidth <= 700 && <br />} des processus métier
            </h3>
          </div>

          <p
            className="digitalisation_section__content__one__desc"
            style={{
              fontSize: "14px",
              color: Colors.blackText,
              fontFamily: Fonts().primaryRegular,
              lineHeight: 1.7,
              opacity: 0.7,
              width: "50%",
              marginTop: innerWidth > 600 ? 16 : 34,
            }}
          >
            La digitalisation au service de la performance des entreprises.
            <br />
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              Une solution de transformation numérique des organisations, qui
              concerne toutes les fonctions de l'entreprise.
            </strong>{" "}
          </p>
          <div className="digitalisation_section__items">
            <Items
              desc="les processus financiers et comptables"
              fontSize="13px"
            />
            <Items
              desc="les processus de Ressources Humaines"
              fontSize="13px"
            />
            <Items desc="les processus d’achats" fontSize="13px" />
            <Items
              desc="les processus commerciaux et marketing"
              fontSize="13px"
            />
          </div>
          <p
            className="digitalisation_section__content__one__desc"
            style={{
              fontSize: "12px",
              color: Colors.blackText,
              fontFamily: Fonts().primaryRegular,
              lineHeight: 1.7,
              opacity: 0.7,
              width: "50%",
              marginTop: 16,
            }}
          >
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              {" "}
              La digitalisation des processus apporte de{" "}
              {innerWidth < 600 && <br />}
            </strong>
            <strong style={{ fontFamily: Fonts().primaryBold, color: "red" }}>
              {" "}
              nombreux avantages à l’entreprise et à sa{" "}
              {innerWidth < 600 && <br />} chaîne de valeur.
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};
