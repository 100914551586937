import React, { useEffect, useState } from "react";
import "../ExternalisationComptabilite/style.css";
import Logo from "@images/Logo2.png";
import Icon0 from "@images/FirstPage/Picture2.png";
import Icon1 from "@images/FirstPage/Picture3.png";
import Icon8 from "@images/FirstPage/Picture12.png";
import Background from "@images/fond.png";
import { Fonts } from "@common";

import Icon21 from "@images/FirstPage/Picture21.png";
import Icon6 from "@images/FirstPage/Picture23.png";
import Icon001 from "@images/FirstPage/icon10.png";
import Icon002 from "@images/FirstPage/Icon11.png";
import Icon003 from "@images/FirstPage/icon12.png";
import Icon004 from "@images/FirstPage/icon13.png";
import Icon005 from "@images/FirstPage/icon14.png";
import Icon006 from "@images/FirstPage/icon15.png";
import Icon007 from "@images/FirstPage/icon16.png";
import Icon008 from "@images/FirstPage/icon17.png";
import Icon4 from "@images/FirstPage/Picture13.png";
import Icon5 from "@images/FirstPage/Picture14.png";
import Pictures22 from "@images/FirstPage/Picture22.png";

const ExternalisationSuportApplicatif = () => {
  const [transformValue, setTransformValue] = useState(200);

  useEffect(() => {
    const handleResize = () => {
      const newTransformValue = window.innerWidth / 200;
      setTransformValue(newTransformValue);
    };

    window.addEventListener("resize", handleResize);

    // Initial call to set the transform value based on initial window size
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="externalisation_comtapilite">
      <header className="header">
        <img src={Logo} alt="3e Africa" className="logo" />
      </header>
      <main className="main_first_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <div className="icons__fixed_left fourth_page_fixed">
          <img
            src={Icon0}
            alt="3e Africa"
            className="icon__fixedleft1 fouth_page"
            style={{
              transform: `translateX(${
                window.innerWidth <= 768
                  ? transformValue + 4
                  : transformValue + 4
              }rem)`,
            }}
          />
          <img
            src={Icon1}
            alt="3e Africa"
            className="icon__fixedleft2 fouth_page"
            style={{
              left: `${
                window.innerWidth <= 768
                  ? transformValue + 4
                  : transformValue + 4
              }rem`,
            }}
          />
        </div>
        <section className="intro">
          <h1 style={{ fontFamily: Fonts().primaryBold }}>
            EXTERNALISATION DU SUPPORT APPLICATIF
          </h1>
          <p
            className="intro__text"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            Editeurs de logiciel, optez pour une{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              solution économique et efficace pour gérer votre support
              applicatif.
            </strong>
            <br />
            <br />
            L’externalisation de votre équipe de support applicatif en
            complément de flexibilité qu’elle offre, permet, de réduire
            considérablement les coûts de fonctionnement (coûts de main d’œuvre
            et coûts fixes), de résoudre les défis liés au recrutement.
            <br />
            <br />
            Notre équipe est à votre service pour un accompagnement régulier
            afin de gérer vos besoins de support quotidiens.
            <br /> <br />
            Notre objectif, vous offrir{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              des services sur mesure et performante
            </strong>{" "}
            qui vous permettent de vous concentrer sur vos activités cœur de
            métier.
          </p>
        </section>
        <section className="content__third_page fouth__page">
          <div className="benefits_third_page">
            <h2
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold, textAlign: "start" }}
            >
              Notre offre de services Support Applicatif
            </h2>
            <div className="section_list_with_images">
              <div className="list_with_images_left_side fouth__page">
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon001}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Gestion RH
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Onboarding et formation
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Gestion des plannings
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Gestion de la paie
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Gestion des congés
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon002}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Analyse des problèmes Niveau 1
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Analyse des problèmes remontés par les clients
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Identification des causes
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Communication de cette analyse aux équipes internes
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon003}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Accompagnement métier
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Accompagner vos clients sur leur problématiques métiers
                        relatifs à votre application
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Aider les clients à optimiser leurs workflows sur votre
                        application
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon004}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Rédaction de documents techniques
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Documentation des anomalies
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Rédaction des procédures de résolution
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="list_with_images_right_side third_page___3">
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon008}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Gestion des demandes clients
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Qualification et priorisation des demandes
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Diagnostic des anomalies
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Proposition de solutions de contournement
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon007}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Analyse et correctifs Niveau 2
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Analyse des anomalies techniques
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Réalisation de correctifs techniques
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon006}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Réalisation des procédures support
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Rédaction des procédures de support
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Partage des best practices avec le reste de l’équipe
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section">
                  <div className="icon third_page">
                    <img
                      className="icons__thir_page"
                      src={Icon005}
                      alt="Finance"
                    />
                  </div>
                  <div className="content">
                    <h2
                      className="title__of__list2"
                      style={{
                        fontFamily: Fonts().primaryRegular,
                      }}
                    >
                      Rapports et analyses
                    </h2>
                    <ul className="ul__of_list2">
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Performance du support
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Tendances des incidents
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Taux de satisfaction utilisateurs
                      </li>
                      <li
                        className="li___finance"
                        style={{ fontFamily: Fonts().primaryRegular }}
                      >
                        Délais de résolution des tickets
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <main className="main_first_page second_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement externaliser___"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Pourquoi externaliser la fonction Support Applicatif ?
            </h2>
            <ul className="ul__of_list2">
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Réduction des coûts :
                </strong>{" "}
                <ul className="custom-list">
                  <li>
                    Évite les coûts liés au recrutement, à la formation, et au
                    maintien d'une équipe de support interne.
                  </li>
                  <li>Diminution des investissements en infrastructure.</li>
                  <li>
                    Transformation de coûts fixes en coûts variables, vous ne
                    payez que ce que vous utilisez !
                  </li>
                </ul>
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Concentration sur votre cœur de métier :
                </strong>{" "}
                <ul className="custom-list">
                  <li>
                    Permet de focaliser vos ressources sur vos activités
                    principales.
                  </li>
                  <li>
                    Libère les ressources internes pour se concentrer sur des
                    initiatives stratégiques.
                  </li>
                  <li>
                    Gestion opérationnelle, RH, et administrative de l’équipe
                    prise en charge par 3E Africa.
                  </li>
                </ul>
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Flexibilité :
                </strong>{" "}
                <ul className="custom-list">
                  <li>
                    Ajustement de notre offre à votre stratégie de service
                    support utilisateur.
                  </li>
                  <li>
                    Adaptation rapide de l’équipe en réponse à l’évolution de
                    votre activité.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </section>

        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement fourth__page__"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Pourquoi externaliser au Maroc ?
            </h2>
            <ul className="ul__of_list2">
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Coût du travail compétitif :
                </strong>{" "}
                moins élevé qu’en France avec un temps de travail hebdomadaire
                de 40 heures.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Main-d’œuvre{" "}
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  compétente
                </strong>{" "}
                avec de{" "}
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  compétente
                </strong>{" "}
                très bons niveaux en français et en anglais
              </li>

              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Proximité géographique et très{" "}
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  faible décalage
                </strong>{" "}
                horaire entre la France et le Maroc,{" "}
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  faible décalage
                </strong>{" "}
                1 heure seulement.
              </li>
            </ul>
          </div>
        </section>
        <section className="content second__content fouth__page___">
          <img
            src={Icon8}
            className="image__absolute_position second_image"
            alt="Icon7"
          />
          <div className="benefits">
            <h2
              className="externaliser__traitement notre__valeur__ajouter"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Notre valeur ajoutée
            </h2>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Partenaire de votre croissance, 3E Africa vous propose une
              prestation d’externalisation du support applicatif structurée
              autour des points suivants :
            </p>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Consultants expérimentés et autonomes
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Mise en place rapide et efficace
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Équipe constituée de salariés permanents (CDI)
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Accès à une expertise spécialisée adaptée à vos besoins
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Flexibilité et réactivité face aux exigences de la relation
                client
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Continuité d'activité et réduction du risque lié au personnel
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Solution clé en main
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Haut niveau de confidentialité
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Garantie d’une amélioration continue de la qualité du service
              </li>
            </ul>
          </div>
        </section>
      </main>

      <main className="main_first_page second_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        {/* <div className="icons__fixed_left__ligne">
          <img
            src={Icon18}
            alt="3e Africa"
            className="icon__fixed__ligne"
            // style={{ transform: `(${transformValue}rem)` }}
          />
        </div> */}
        <section className="content page__3_content">
          <div className="benefits">
            <h1
              className="externaliser__traitement"
              style={{ fontFamily: Fonts().primaryBold, color: "#bf0101" }}
            >
              Une intervention de qualité par des consultants expérimentés
            </h1>
            <p
              className="intro__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Notre équipe possède une expertise variée afin de répondre de
              manière optimale à vos besoins.
            </p>
            <ul className="ul__of_list2">
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Des consultants support généralistes,
                </strong>{" "}
                experts en relation client pour assurer le support client de
                premier niveau.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Des consultants spécialistes
                </strong>{" "}
                dans votre domaine d’activité (comptabilité, RH, etc…)
                garantissant un accompagnement et une relation client de
                qualité.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Des ingénieurs informatiques
                </strong>{" "}
                qualifiés pour fournir un support technique de second niveau.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Excellente maitrise du Français à l’écrit comme à l’oral par
                l’ensemble de notre équipe.
              </li>
              <li
                className="li___getsion"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Et d’excellents Soft skills, des compétences non techniques mais
                indispensables.
                <ul className="custom-list">
                  <li>Capacité d'écoute et empathie</li>
                  <li>Autonomie et prise d'initiative</li>
                  <li>Polyvalence et adaptabilité</li>
                  <li>Rigueur dans l'exécution des tâches</li>
                  <li>Capacité à s'adapter rapidement aux changements</li>
                  <li>Excellentes aptitudes relationnelles</li>
                  <li>Attitude positive et proactivité</li>
                  <li>Collaboration efficace au sein d'une équipe</li>
                </ul>
              </li>
            </ul>
          </div>
        </section>

        <section className="content second__content have__horizontal_content">
          <section className="image__section">
            <img src={Icon21} className="image__section_src" alt="Icon2" />
          </section>
          <div className="benefits">
            <h2
              className="externaliser__traitement fourth__page__"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Nos engagements
            </h2>
            <ul className="extenalisation__ul">
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Garantie de la confidentialité absolue de toutes vos données
                sensibles.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Engagement envers une qualité de service exceptionnelle,
                assurant la satisfaction de vos clients.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Fourniture régulière de rapports détaillés sur l'activité et la
                performance de nos équipes.
              </li>
              <li
                className="li__items__externalisation"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Utilisation exclusive d'outils informatiques sécurisés pour une
                protection optimale de vos informations.
              </li>
            </ul>
          </div>
        </section>

        <section className="content second__content fourthpage">
          <div className="benefits">
            <h2
              className="externaliser__traitement fouth___page"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Comment ça marche ?
            </h2>
          </div>
          <div className="steps_container">
            <div className="steps">
              <div className="step">
                <div
                  className="step-number thirdpage__red one___"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  1
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nous qualifions ensemble votre besoin
                </div>
              </div>
              <div className="step">
                <div
                  className="step-number thirdpage__grey two___"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  2
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nous constituons une équipe sur mesure en fonction de vos
                  exigences
                </div>
              </div>
              <div className="step">
                <div
                  className="step-number thirdpage__red three__"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  3
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nous prenons en charge l'intégration de l’équipe, de sa
                  formation à son alignement avec vos outils et processus
                </div>
              </div>
              <div className="step">
                <div
                  className="step-number thirdpage__grey four__"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  4
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Notre équipe en charge gestion la gestion de votre support
                  client
                </div>
              </div>
              <div className="step">
                <div
                  className="step-number thirdpage__red five__"
                  style={{ fontFamily: Fonts().primaryBold }}
                >
                  5
                </div>
                <div
                  className="step-text"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  Nous assurons un suivi constant des résultats et des
                  performances
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <main className="main_first_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <div className="icons__fixed_left fourth_page_fixed last__main">
          <img
            src={Icon4}
            alt="3e Africa"
            className="icon__fixedleft1 icon__ooooo last_main"
            style={{ transform: `translateX(${transformValue}rem)` }}
          />
          <img
            src={Icon5}
            alt="3e Africa"
            className="icon__fixedleft2 second_page last_main"
            style={{ left: `${transformValue}rem` }}
          />
        </div>

        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement tarification___"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Tarification
            </h2>
            <p
              className="just__paragraph "
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Nous proposons différents forfaits très compétitifs, adaptés à la
              taille et aux besoins spécifiques de chaque entreprise.
              Contactez-nous pour une évaluation personnalisée et un devis
              adapté.
            </p>
          </div>
        </section>
        <section className="content second__content">
          <div className="benefits">
            <h2
              className="externaliser__traitement tarification___"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Pourquoi Nous Choisir ?
            </h2>
            <p
              className="just__paragraph"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Avec 3E Africa, vous bénéficiez non seulement d'une flexibilité
              accrue et, d’une expertise reconnue, mais aussi d'un partenariat
              basé sur la confiance, la transparence et l'engagement envers la
              réussite de votre entreprise.
            </p>
          </div>
        </section>
        <section className="image__section last__page">
          <img src={Icon6} className="image__section_src_icon6" alt="Icon6" />
        </section>
      </main>

      <main className="main_first_page">
        <div className="fond__back">
          <img src={Background} className="fond__src" />
        </div>
        <section className="content second__content fourthpage">
          <div className="benefits">
            <h2
              className="externaliser__traitement tarifs___"
              style={{ fontFamily: Fonts().primaryBold }}
            >
              Tarifs
            </h2>
            <p
              className="just__paragraph"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Des tarifs compétitifs par rapport au marché Français.
            </p>
          </div>

          <table className="pricing-table">
            <thead>
              <tr>
                <th style={{ fontFamily: Fonts().primaryBold }}>
                  Support client généraliste N1
                </th>
                <th style={{ fontFamily: Fonts().primaryBold }}>
                  Support Métier N1
                </th>
                <th style={{ fontFamily: Fonts().primaryBold }}>
                  Support Technique N2
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontFamily: Fonts().primaryRegular }}>
                  A partir de 1700€/mois
                </td>
                <td style={{ fontFamily: Fonts().primaryRegular }}>
                  A partir de 1900€/mois
                </td>
                <td style={{ fontFamily: Fonts().primaryRegular }}>
                  A partir de 2600€/mois
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="image__section last_page_last_picture">
          <img
            src={Pictures22}
            className="image__section_src_icon6 last_page_last_picture_src"
            alt="Icon6"
          />
        </section>
      </main>
    </div>
  );
};

export default ExternalisationSuportApplicatif;
