import React from "react";
import small3 from "@images/smalls/small3.png";
import { PendingIcon, Button } from "@components";
import { useColors, Fonts } from "@common";
import { useNavigate } from "react-router-dom";
import "./style.css";
import small6 from "@images/smalls/small6.png";

const { innerWidth, innerHeight } = window;

export const Items = ({ desc, strong, fontSize }) => {
  const Colors = useColors();
  return (
    <div className="section4__item">
      <PendingIcon width={15} height={15} />
      <p
        className="section4__item_desc"
        style={{
          fontSize: fontSize || "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          style={{
            fontSize: fontSize || "12px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {desc}
      </p>
    </div>
  );
};

export default ({}) => {
  const Colors = useColors();
  const navigate = useNavigate();
  return (
    <div className="Ameliorez_section__content__three">
      <div className="Ameliorez_section_content_2">
        {innerWidth > 700 && (
          <div className="Ameliorez_smaøl2_div_image">
            <img src={small6} className="Ameliorez_section_small_image_1" />
          </div>
        )}
        <div className="Ameliorez_section_content2_content">
          <div className="Ameliorez_section__image_with_titles">
            {innerWidth <= 700 && (
              <div className="Ameliorez_smaøl2_div_image">
                <img src={small6} className="Ameliorez_section_small_image_1" />
              </div>
            )}
            <h3
              className="Ameliorez_section__content__two__title"
              style={{
                fontSize: "22px",
                color: Colors.blackText,
                fontFamily: Fonts().primaryBold,
              }}
            >
              Améliorez votre {innerWidth <= 700 && <br />} présence numérique
            </h3>
          </div>

          <p
            className="Ameliorez_section__content__two__desc_second"
            style={{
              fontSize: "14px",
              color: "red",
              fontFamily: Fonts().primaryBold,
              lineHeight: 1.7,
              opacity: 0.7,
              // marginTop: 16,
            }}
          >
            Votre site web constitue votre meilleur outil <br /> de
            communication digitale avec vos parties prenantes.
          </p>
          <h5
            className="Ameliorez_offer_for_you"
            style={{
              fontSize: "16px",
              color: Colors.blackText,
              fontFamily: Fonts().primarySemiBold,
              // marginTop: "18px",
            }}
          >
            Offrez-vous un marking digital {innerWidth <= 700 && <br />}de
            qualité
          </h5>
          <p
            className="Ameliorez_the__experts__our_web"
            style={{
              fontSize: "12px",
              color: Colors.blackText,
              fontFamily: Fonts().primaryRegular,
              lineHeight: 1.7,
              opacity: 0.7,
              // marginTop: 16,
              width: innerWidth > 600 ? "64%" : "98%",
            }}
          >
            Les experts de notre web agency vous accompagnent dans la réussite
            de vos projets digitaux. De la définition de votre stratégie
            digitale jusqu’aux opérations de référencements, en passant par la
            réalisation de votre site web
          </p>
          <br />
          <p
            className="Ameliorezour_team_its_capable"
            style={{
              fontSize: "12px",
              color: Colors.blackText,
              fontFamily: Fonts().primaryBold,
              lineHeight: 1.7,
              opacity: 0.7,
              // marginTop: 16,
            }}
          >
            Notre équipe est capable de répondre à tous vos{" "}
            {innerWidth < 600 && <br />} besoins.
          </p>
        </div>
      </div>
    </div>
  );
};
