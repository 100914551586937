import React, { useState } from "react";
import Back8 from "@images/backgrounds/Back8.png";
import ResBack8 from "@images/responsive/Back5.png";
import { useColors, Fonts } from "@common";
import { Button, TextInput } from "@components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./styles/Section8.css";
import { toast } from "react-toastify";
import { CONTACTS } from "@services";

const { innerWidth, innerHeight } = window;
export default ({ Ref }) => {
  const Colors = useColors();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [tele, setTele] = useState("");
  const [seciete, setSociete] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoaing] = useState(false);

  const handleKeyUp = (event) => {
    // Remove any non-numeric characters from the input value
    const sanitizedValue = event.target.value.replace(/\D/g, "");

    // Update the input value with the sanitized numeric value
    event.target.value = sanitizedValue;
  };

  const handleChange = () => {
    setIsLoaing(true);
    if (username && email && seciete && message && tele) {
      const params = {
        username: username,
        email: email,
        phone: tele,
        society: seciete,
        message: message,
        from: "home",
      };
      CONTACTS.NEW(params)
        .then((data) => {
          setIsLoaing(false);
          if (data.status === 201 || data.status === 200) {
            setEmail("");
            setUsername("");
            setMessage("");
            setTele("");
            setSociete("");
            toast.success("Les informations personnelles enregistrées", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          setIsLoaing(false);
          if (error) {
            setTimeout(() => {
              setEmail("");
              setUsername("");
              setMessage("");
              setTele("");
              setSociete("");
              toast.error("Quelque chose s'est mal passé", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }, 100);
          }
        });
    } else {
      setIsLoaing(false);
      setTimeout(() => {
        setEmail("");
        setUsername("");
        setMessage("");
        setTele("");
        setSociete("");
        toast.error("Vous devez d'abord remplir les champs", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }, 100);
    }
  };

  const handleUserName = (e) => {
    setUsername(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleTele = (e) => {
    setTele(e.target.value);
  };
  const handleSocity = (e) => {
    setSociete(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${innerWidth > 900 ? Back8 : ResBack8})`,
      }}
      className="section8___main"
    >
      <div className="section6__added_smalls">
        <div className="section6__bottom_desc">
          <h1
            className="section6__title"
            style={{
              color: Colors.blackText,
              fontFamily: Fonts().primaryBold,
              fontSize: innerWidth < 600 && "17px",
            }}
          >
            Vous avez une question?
          </h1>
          <p
            className="section6_desc__bottom"
            style={{
              fontSize: innerWidth < 441 ? "15px" : "17px",
              color: Colors.error,
              fontFamily: 'Roboto',
              marginTop: innerWidth > 600 ? 22 : 54,
              fontWeight: "bold"
            }}
          >
            N’hésitez pas à nous contacter, nos équipes sont là pour vous aider.
          </p>

          <Button
            Label="Contactez-nous"
            useIcon
            IconName={ArrowForwardIosIcon}
            notImage
            style={{
              width: "200px",
              background: Colors.error,
              color: "rgb(255, 255, 255)",
              fontSize: "16px",
              textTransform: "lowercase",
              fontWeight: "400",
              borderRadius: "12px",
              fontFamilly: Fonts().primaryRegular,
              padding: "12px",
              boxShadow: "2px 3px 7px #d7d2d2",
              marginTop: "4%",
            }}
          />
        </div>
        {/* <div className="small8__div">
            <img className="small8_img" src={small8} />
          </div> */}
      </div>
      <div className="section8___content" ref={Ref}>
        <h1
          className="section8__content__title"
          style={{
            fontSize: innerWidth > 600 ? "30px" : "22px",
            fontFamily: Fonts().primaryBold,
            color: Colors.error,
            marginTop: "22px",
          }}
        >
          Nous contacter
        </h1>
        <h4
          className="section8__desc"
          style={{
            fontSize: innerWidth < 441 ? "16px" : "18px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          Pour plus d'informations contacter nous
        </h4>
        <div className="section8__inputs">
          <div className="section8__inputs__party1">
            <TextInput
              value={username}
              placeholder="Nom et Prénom"
              required
              onChange={handleUserName}
            />
            <TextInput
              placeholder="Email"
              required
              onChange={handleEmail}
              value={email}
            />
          </div>
          <div className="section8__inputs__party2">
            <TextInput
              placeholder="Téléphone"
              required
              onKeyUp={handleKeyUp}
              onChange={handleTele}
              value={tele}
            />
            <TextInput
              placeholder="Société"
              required
              onChange={handleSocity}
              value={seciete}
            />
          </div>
        </div>
        <div className="section8__inputs__party3">
          <TextInput
            value={message}
            onChange={handleMessage}
            placeholder="Votre message"
            required
            multiline
            rows={6}
            className="message_Eafrica"
          />
        </div>
        <div className="section8_action">
          <Button
            isLoading={isLoading}
            Click={handleChange}
            Label="Envoyer"
            style={{
              width: innerWidth > 600 ? "216px" : "120px",
              background: "rgb(241, 81, 81)",
              color: "rgb(255, 255, 255)",
              fontSize: innerWidth > 600 ? "13px" : "11px",
              textTransform: "capitalize",
              fontWeight: "400",
              borderRadius: "14px",
              padding: innerWidth > 600 ? "14px" : "12px",
              boxShadow: "rgb(215 210 210) 2px 3px 7px", 
              marginTop: "30px",
              marginRight: "40px",
            }}
          />
        </div>
      </div>
    </div>
  );
};
