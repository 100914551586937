import React from "react";
import Back3 from "@images/backgrounds2/Back3.png";
import small2 from "@images/smalls/small2.png";
import resSmall2 from "@images/smalls/resSmall2.png";
import ResBack3 from "@images/responsive/Back3.png";
import { useColors, Fonts } from "@common";
import { PendingIcon } from "@components";
import Agrobource from "@images/logos/Agrobource.png";
import NeoFast from "@images/logos/NeoFast.png";
import Token from "@images/logos/Token.png";
import Sections4 from "./Sections4";
import { useNavigate } from "react-router-dom";
import "./styles/Section3.css";

const { innerWidth, innerHeight } = window;
export const Items = ({ desc, strong, Ref }) => {
  const Colors = useColors();
  return (
    <div className="section3__item">
      <PendingIcon  top = {3}/>
      <p
        className="section3__item_desc"
        style={{
          fontSize: "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          className="section3_Strongs"
          style={{
            fontSize: innerWidth > 414 ? "12px" : "10px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>
        ,{" "}{desc}
      </p>
    </div>
  );
};

export default ({ Ref, RefOne, RefTwo, RefThree, RefFour }) => {
  const navigate = useNavigate();
  const Colors = useColors();
  return (
    <div
      style={{
        backgroundImage: `url(${innerWidth > 700 ? Back3 : ResBack3})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
        // height: "3210px",
        position: "relative",
      }}
      className="section3__main"
    >
      <div className="section3_the_content1">
        {/* <div className="the_small_2_image">
          <img
            className="small2_image_section3"
            src={innerWidth > 400 ? small2 : resSmall2}
          />
        </div> */}
        {innerWidth > 700 && (
          <div className="displeyed_image">
            <img className="small2_displaied" src={small2} />
          </div>
        )}
        <div className="section3__content">
          <div className="conatin_small_image">
            <div className="section3_after_some_width">
              <div
                className="with_back_and_desc"
                // style={{
                //   backgroundImage: `url(${innerWidth <= 700 && resSmall2})`,
                //   backgroundRepeat: "no-repeat",
                //   backgroundPosition: "right top",
                //   backgroundSize: "inherit",
                //   // height: "3210px",
                //   position: "relative",
                // }}
              >
                <h1
                  className="section3__title"
                  style={{
                    fontSize: innerWidth > 600 ? "50px" : "21px",
                    fontFamily: Fonts().primaryBold,
                    color: Colors.textColors,
                  }}
                >
                  Nos métiers au {innerWidth < 600 && <br />}cœur du digital
                </h1>
                <p
                  className="section3__desc"
                  style={{
                    color: Colors.blackText,
                    fontFamily: Fonts().primaryRegular,
                    opacity: 0.7,
                    lineHeight: 1.7,
                  }}
                >
                  Depuis 3 ans, le digital est un axe de{" "}
                  {innerWidth < 348 && <br />}
                  développement {innerWidth < 600 && innerWidth > 348 && (
                    <br />
                  )}{" "}
                  stratégique majeur {innerWidth < 348 && <br />} pour notre
                  groupe.
                  <br /> Experts en transformation digitale, nous proposons aux
                  entreprises de digitaliser leurs processus métiers dans un
                  objectif d’amélioration de leur performance.
                  <br /> Au cours de ces dernières années, nous avons du
                  démontrer un réel savoir-faire et une vraie maîtrise, en
                  exploitant l'Intelligence Artificielle et la Blockchain dans
                  des projets innovants.
                </p>
              </div>
              {innerWidth <= 700 && (
                <img src={resSmall2} className="section3_respo_image" />
              )}
            </div>
          </div>
          <div className="section3__items">
            <Items
              desc="cabinet de conseil et digital factory."
              strong="3E Africa"
            />
            <Items
              desc="éditeur de solutions informatiques dédiées à l’agriculture, la pêche et l'élevage."
              strong="Agrobourse 360"
            />
            <Items
              desc="laboratoire de recherche appliquée aux Fintech et Agritech."
              strong="NeoFast"
            />
            <Items
              desc="concepteur de solutions de traçabilité infalsifiable sécurisées par la Blockchain."
              strong="Token Me"
            />
          </div>
          <div className="section3__logos">
            <img
              onClick={() => window.open("https://www.agrobourse.com/")}
              src={Agrobource}
              alt="Agrobource"
              className="logos__pics agrubource"
              // style={{
              //   width: innerWidth > 600 ? 85 : innerWidth < 400 ? 60 : 85,
              //   height: innerWidth > 600 ? 55 : innerWidth < 400 ? 40 : 52,
              // }}
            />
            <img
              onClick={() => navigate("/nos_services/coming_soon")}
              src={NeoFast}
              alt="NeoFast"
              className="logos__pics neofast"
              // style={{
              //   width: innerWidth > 600 ? 70 : innerWidth < 400 ? 55 : 60,
              //   // height: innerWidth > 600 ? 70 : innerWidth < 400 ? 30 : 55,
              // }}
            />
            <img
              onClick={() => window.open("https://tokenme.fr/")}
              src={Token}
              alt="Token.me"
              className="logos__pics tokenme"
              // style={{
              //   width: innerWidth > 600 ? 65 : innerWidth < 400 ? 35 : 45,
              //   height: innerWidth > 600 ? 65 : innerWidth < 400 ? 37 : 45,
              // }}
            />
          </div>
        </div>
      </div>
      <Sections4
        Ref={Ref}
        RefOne={RefOne}
        RefTwo={RefTwo}
        RefThree={RefThree}
        RefFour={RefFour}
      />
    </div>
  );
};
