import * as React from "react";
import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import "./index.css";

const { innerWidth, innerHeight } = window;
const customTheme = (outerTheme, multiline) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: !multiline && "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !multiline && "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor:
                !multiline && "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor:
                !multiline && "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&:before, &:after": {
              borderBottom:
                !multiline && "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom:
                !multiline &&
                "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                !multiline &&
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom:
                !multiline && "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom:
                !multiline &&
                "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                !multiline &&
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

export default ({
  placeholder,
  required,
  multiline,
  rows,
  className,
  onKeyUp,
  onChange,
  value,
  style,
}) => {
  const outerTheme = useTheme();

  const asteriskStyle = {
    color: "red",
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: multiline
          ? { sm: "1fr 0fr 0fr" }
          : { sm: "10fr 1fr 1fr" },
        gap: 2,
        width: "100%" /* multiline ?  "100%" : "94%"*/,
        margin: "0 auto",
      }}
    >
      <ThemeProvider theme={customTheme(outerTheme, multiline)}>
        <TextField
          value={value}
          onKeyUp={onKeyUp}
          onChange={onChange}
          className={className}
          multiline={multiline}
          rows={rows}
          label={
            <span>
              {placeholder} {required && <span style={asteriskStyle}>*</span>}
            </span>
          }
          variant="standard"
        />
      </ThemeProvider>
    </Box>
  );
};
