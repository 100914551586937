import React from "react";
import small3 from "@images/smalls/small3.png";
import { PendingIcon, Button } from "@components";
import { useColors, Fonts } from "@common";
import { useNavigate } from "react-router-dom";
import "./style.css";
import small5 from "@images/smalls/small5.png";

const { innerWidth, innerHeight } = window;

export const Items = ({ desc, strong, fontSize }) => {
  const Colors = useColors();
  return (
    <div className="section4__item">
      <PendingIcon width={15} height={15} />
      <p
        className="section4__item_desc"
        style={{
          fontSize: fontSize || "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          style={{
            fontSize: fontSize || "12px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {desc}
      </p>
    </div>
  );
};

export default ({ RefTwo }) => {
  const Colors = useColors();
  const navigate = useNavigate();
  return (
    <div className="marketing_section__content__two_second" ref={RefTwo}>
      <div className="marketing_section_content_1">
        {innerWidth > 700 && (
          <div className="marketing_smaøl2_div_image">
            <img src={small5} className="marketing_section4_small_image_1" />
          </div>
        )}
        <div className="marketing_section_content1_content">
          <div className="marketing__section__titles_image">
            {innerWidth <= 700 && (
              <div className="marketing_smaøl2_div_image">
                <img
                  src={small5}
                  className="marketing_section4_small_image_1"
                />
              </div>
            )}
            <h3
              className="marketing_section__content__one__title"
              style={{
                fontSize: "18px",
                color: Colors.blackText,
                fontFamily: Fonts().primaryBold,
              }}
            >
              Marketing et Digital Marketing
            </h3>
          </div>

          <p
            className="marketing_section__content__one__desc"
            style={{
              fontSize: "14px",
              color: Colors.blackText,
              fontFamily: Fonts().primaryRegular,
              lineHeight: 1.7,
              opacity: 0.7,
              width: "50%",
              // marginTop: innerWidth > 600 ? 16 : 45,
            }}
          >
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              Le marketing 
            </strong>{" "}
            digital utilise l’ensemble des canaux{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              {" "}
              numériques
            </strong>{" "}
            pour vendre un produit ou un{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              {" "}
              service{" "}
            </strong>
              auprès des consommateurs. Notre département web agency est
            spécialisé dans{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              {" "}
              la création de site internet{" "}
            </strong>
            , le développement web, le développement d'application mobile ou
            encore le référencement naturel (SEO) ou publicité (SEA). <br />
          </p>
          <span
          className="conseil_realisation"
            style={{
              fontSize: innerWidth > 600 ? "14px" : "12px",
              color: Colors.blackText,
              fontFamily: Fonts().primaryRegular,
              lineHeight: 1.7,
              opacity: 0.7,
              width: innerWidth > 600 ? "50%" : "100%",
              marginTop: innerWidth > 600 ? 16 : 10,
            }}
          >
            {" "}
            Du conseil à la réalisation, nos experts,
          </span>
          <div className="marketing_section__items">
            <Items desc="Chef de projet" fontSize="13px" />
            <Items
              desc="Développeurs (front-end et back-end)"
              fontSize="13px"
            />
            <Items desc="Designer UX/UI" fontSize="13px" />
          </div>
          <p
            className="section4__content__one__desc"
            style={{
              fontSize: "12px",
              color: Colors.blackText,
              fontFamily: Fonts().primaryRegular,
              lineHeight: 1.7,
              opacity: 0.7,
              width: "50%",
              marginTop: 16,
            }}
          >
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              {" "}
              Vous conseillent pour concevoir un site performant et visible.
            </strong>
            <br />
            Recourir à nos services,{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              {" "}
              c'est avant tout l'assurance d'obtenir un résultat conforme à vos
              exigences.
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};
