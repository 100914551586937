import React from "react";
// import Back4 from "@images/backgrounds/Back4.png";
import { useColors, Fonts } from "@common";
import { PendingIcon, Button } from "@components";
import Arrow from "@images/icons/arrow_left.png";
import { useNavigate } from "react-router-dom";
import "./styles/Section4.css";
import Pilotage from "./NosOffer/Pilotage";
import Externalisation from "./NosOffer/Externalisation";
import Marketing from "./NosOffer/Marketing";
import Ameliorez from "./NosOffer/Ameliorez";
import Digitalisation from "./NosOffer/Digitalisation";

const { innerWidth, innerHeight } = window;

export const Items = ({ desc, strong, fontSize }) => {
  const Colors = useColors();
  return (
    <div className="section4__item">
      <PendingIcon width={15} height={15} />
      <p
        className="section4__item_desc"
        style={{
          fontSize: fontSize || "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          style={{
            fontSize: fontSize || "12px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {desc}
      </p>
    </div>
  );
};
export default ({ Ref, RefOne, RefTwo, RefThree, RefFour }) => {
  const Colors = useColors();
  const navigate = useNavigate();

  const goToService = () => {
    navigate("/nos_services");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div ref={Ref} className="section4__main">
      <div className="section4__title">
        <h1
          className="section4__title__text"
          style={{
            fontSize: innerWidth > 600 ? "50px" : "20px",
            color: Colors.blackText,
            fontFamily: Fonts().primaryBold,
          }}
        >
          Nos offres
        </h1>
      </div>

      <Pilotage RefOne={RefOne} />
      <Externalisation RefFour={RefFour} />
      <Marketing RefTwo={RefTwo} />
      <Ameliorez />
      <Digitalisation RefThree={RefThree} />

      <div className="section4__button__actions">
        <Button
          Click={goToService}
          Label="Plus d'infos"
          useIcon
          IconName={Arrow}
          style={{
            width: innerWidth < 490 ? "160px" : "165px",
            height: innerWidth < 490 ? "48px" : "55px",
            background: "rgb(244 232 232)",
            color: Colors.error,
            fontSize: innerWidth < 490 ? "18px" : "12px",
            textTransform: "capitalize",
            fontWeight: "600",
            borderRadius: innerWidth < 441 ? "12px" : "14px",
            fontFamilly: Fonts().primaryRegular,
            padding: "8px",
            boxShadow: "2px 3px 7px #d7d2d2",
          }}
        />
      </div>
    </div>
  );
};
