import React, { useRef, useState } from "react";
import { useColors, Fonts } from "@common";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import ComingSoon from "./components/ComingSoon";

export default ({}) => {
  const Colors = useColors();
  const section2Ref = useRef(null);
  const Home2Ref = useRef(null);
  const ContactRef = useRef(null);
  const AboutRef = useRef(null);
  const [active, setActive] = useState("services");
  const navigate = useNavigate();

  const OurServices = () => {
    setActive("services");
    navigate("/nos_services");
  };

  const hanleOnclickHome = () => {
    setActive("Accueil");
    // Home2Ref.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };

  const handleOnClickContact = () => {
    setActive("Contacts");
    navigate(`/${"contacts"}`);
  };
  const handleOnClickAbout = () => {
    setActive("Abouts");
    navigate(`/${"abouts"}`);
  };

  return (
    <div className="home__home" style={{ background: Colors.backgroundHex }}>
      <NavBar
        handleOurService={OurServices}
        active={active}
        hanleOnclickHome={hanleOnclickHome}
        handleOnClickContact={handleOnClickContact}
        handleOnClickAbout={handleOnClickAbout}
      />
      <ComingSoon />
      <Footer />
    </div>
  );
};
