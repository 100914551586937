import React, { useRef, useState, useEffect } from "react";
import "./index.css";
import { useColors, Fonts } from "@common";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import OurServicesComponents from "./components/OurServices";

export default ({}) => {
  const params = useParams();
  const RefOne = useRef();
  const RefTwo = useRef();
  const RefThree = useRef();
  const RefFour = useRef();
  const RefFive = useRef();
  const RefSex = useRef();
  const RefSeven = useRef();
  const RefEight = useRef();
  const { name } = params;
  const Colors = useColors();
  const section2Ref = useRef(null);
  const Home2Ref = useRef(null);
  const ContactRef = useRef(null);
  const AboutRef = useRef(null);
  const [active, setActive] = useState("services");
  const navigate = useNavigate();

  useEffect(() => {
    if (!!name) {
      if (name === "pilotage") {
        RefOne.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          
        });
      } else if (name === "marketing") {
        RefTwo.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          
        });
      } else if (name === "digitalisation") {
        RefThree.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          
        });
      } else if (name === "externalisation") {
        RefFour.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          
        });
      } else if (name === "nearshoring") {
        RefFive.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          
        });
      } else if (name === "portage_salarial") {
        RefSex.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          
        });
      } else if (name === "formation") {
        RefSeven.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          
        });
      } else if (name === "conformité") {
        RefEight.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          
        });
      }
    }
  }, [name]);

  const OurServices = () => {
    setActive("services");
    section2Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const hanleOnclickHome = () => {
    setActive("Accueil");
    // Home2Ref.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };

  const handleOnClickContact = () => {
    setActive("Contacts");
    navigate(`/${"contacts"}`);
  };
  const handleOnClickAbout = () => {
    setActive("Abouts");
    navigate(`/${"abouts"}`);
  };

  return (
    <div className="home__home" style={{ background: Colors.backgroundHex }}>
      <NavBar
        handleOurService={OurServices}
        active={active}
        hanleOnclickHome={hanleOnclickHome}
        handleOnClickContact={handleOnClickContact}
        handleOnClickAbout={handleOnClickAbout}
      />
      <div>
        <OurServicesComponents
          RefOne={RefOne}
          RefTwo={RefTwo}
          RefThree={RefThree}
          RefFour={RefFour}
          RefFive={RefFive}
          RefSex={RefSex}
          RefSeven={RefSeven}
          RefEight={RefEight}
        />
      </div>
      <Footer />
    </div>
  );
};
